.checkout-success {
  background-color: white;
  display: flex;
  flex: 1;
  flex-flow: column;
  min-height: 100%;

  @media (min-width: 1024px) {
    margin: auto;
  }

  .success-background {
    background-color: $support;
    display: flex;
    flex-direction: column;
    min-height: 5rem;

    @media (min-width: 1024px) {
      justify-content: center;
    }
  }

  .success-message-spacer {
    min-height: 2rem;
  }

  .cauze-image {
    width: 160px;
    height: 90px;
    margin-top: -50px;
  }

  .success-message-container {
    position: relative;
    border-radius: $radius-large;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;

    @media (min-width: 1024px) {
      margin: auto 5rem 2rem;
    }

    .charity-avatar {
      margin-top: -1.5rem;
      margin-bottom: 1rem;
    }

    .thanks-message {
      padding: 0 2rem;
    }

    .match-container {
      margin: 2rem 0 0;
      padding: 1rem;
      background-color: $grey30;

      .match-description {
        font-size: $size-normal;
      }
    }

    .button {
      min-width: 15rem;
    }

    .column:first-of-type {
      .button {
        margin-top: 2rem;
        @include tablet {
          margin: 2rem;
          margin-right: 1rem;
        }
      }
    }

    .column:last-of-type {
      .button {
        margin-bottom: 2rem;
        @include tablet {
          margin: 2rem;
          margin-left: 1rem;
        }
      }
    }
  }

  .button-row {
    margin: 2rem;
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
    .button {
      &:first-of-type {
        @include tablet {
          margin-right: 1rem;
        }
      }
      &:last-of-type {
        margin-top: 1rem;

        @include tablet {
          margin-top: 0;
          margin-left: 1rem;
        }
      }
    }
  }

  .donation-reward {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: stretch;
    margin: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;

    .title {
      color: white;
    }

    .reward-message {
      margin: 1rem 0 1.5rem;
      text-align: center;
      @include tablet {
        margin-left: 5rem;
        margin-right: 5rem;
      }
    }

    .button {
      max-width: 25rem;
    }
  }

  .title {
    @include text-title;
    font-size: 1.5rem;
    margin-left: 3.5rem;
    margin-right: 3.5rem;
    text-align: center;
    margin-bottom: 0;

    @include tablet {
      font-size: 2rem;
    }
  }

  .share {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    color: $baseColor;
    font-size: 0.75rem;

    .share-logo {
      width: 1.5rem;
    }
  }

  .charity-name {
    @include text-bold;
    margin-bottom: 2rem;
    padding: 1rem 2rem 0 2rem;
  }

  .footer {
    margin: 0;
    padding: 1rem;
  }

  .match-list-column {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    .match-row {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      .match-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: 15px;
        margin-top: 11px;
        color: black;
      }

      .match-amount-text {
        font-size: $size-normal;
        @include text-bold;
      }

      .match-name {
        font-size: $size-normal;
      }

      .match-max {
        font-size: $size-small;
        @include text-bold;
      }
    }
  }

  &.gift-success {
    .success-check-icon {
      margin-top: 3rem;
      margin-bottom: 1rem;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: $lilac;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      text-transform: uppercase;
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    .thanks-message {
      text-align: center;
      max-width: 1024px;
    }

    .thanks-detail {
      @include text-10pt;
      margin-top: 2rem;
      margin-bottom: 2rem;
      color: rgba(27, 32, 49, 0.5);
    }

    .donate-again {
      background-color: $support;
      color: white;
      padding: 2rem;

      @include tablet {
        max-width: 640px;
        padding-right: 0;
        padding-bottom: 0;

        .button {
          margin: 0 !important;
        }
      }

      .donate-again-title {
        @include text-title;
        text-transform: uppercase;
        color: 'white';
      }

      .button {
        margin-bottom: 0 !important;
        margin-left: 2rem !important;
      }
    }
  }
}
