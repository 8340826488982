@mixin text-title {
  font-family: alternate-gothic-no-2-d,  sans-serif;
  font-size: 2rem;
  font-weight: 400;
  font-style: normal;
}

@mixin text-title-bold {
  font-family: alternate-gothic-atf,  sans-serif;
  font-size: 2rem;
  font-weight: 700;
  font-style: normal;
}

@mixin text-title-light {
  font-family: alternate-gothic-atf,  sans-serif;
  font-size: 2rem;
  font-weight: 300;
  font-style: normal;
}

@mixin text-bold {
  font-family: Agenda-Bold,  sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin text-light {
  font-family: Agenda-Light-Italic, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin text-regular {
  font-family: Agenda-Regular, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin text-medium {
  font-family: Agenda-Semi-Bold, sans-serif;
  font-weight: 600;
}

@mixin text-agenda-bold {
  font-family: Agenda-Bold, sans-serif;
}

@mixin text-agenda-regular {
  font-family: Agenda-Regular, sans-serif;
}

@mixin text-agenda-regular-italic {
  font-family: Agenda-Regular-Italic, sans-serif;
}

@mixin text-agenda-medium {
  font-family: Agenda-Medium, sans-serif;
}

@mixin text-agenda-black {
  font-family: Agenda-Black, sans-serif;
}

@mixin text-agenda-semi-bold {
  font-family: Agenda-Semi-Bold, sans-serif;
}

@mixin text-9pt {
  font-size: 0.75rem;
}

@mixin text-10pt {
  font-size: 0.85rem;
}

.text-title {
  @include text-title;
}

.text-bold {
  @include text-bold;
}

.text-light {
  @include text-light;
}

.text-regular {
  @include text-regular;
}

.text-medium {
  @include text-medium;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.big-number {
  @include text-title;
}

.orange {
  color: $orange;
}

.currency {
  sup {
    font-size: 50%;
  }
}

.action-link {
  @include text-medium;
  color: $brand;
  margin-bottom: 1rem;
}

.action-grey {
  @include text-medium;
  color: $grey;
}

.hr {
  @include text-bold;
  font-size: 0.75rem;
  letter-spacing: 0.2rem;
  color: $grey;
  text-transform: uppercase;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: $grey50;
  }
  &::before {
    margin-right: 1rem;
  }
  &::after {
    margin-left: 1rem;
  }
}

/* font weights */
.font-black {
  @include text-agenda-black;
  font-weight: 900;
}

.font-bold {
  @include text-agenda-bold;
  font-weight: 700;
}

/* font sizes */
.text-16 {
  font-size: 16px;
}

.text-20 {
  font-size: 20px !important;
}

.text-24 {
  font-size: 24px !important;
}

.text-30 {
  font-size: 30px !important;
}
