.cauze-item {
  position: relative;
  color: $black;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  max-width: 23rem;
  max-height: 23.1rem;
  border-width: 0.5px;
  border-style: solid;
  border-color: $lightGrey;
  border-radius: 5px;
  width: 23rem;

  @include mobile {
    width: 100%;
    max-width: unset;
  }

  .cauze-card-header {
    max-width: 23rem;
    height: 12.875rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &.colorGradient-0 {
      background: $gradientBluePink;
    }

    &.colorGradient-1 {
      background: $gradientPurpleOrange;
    }

    &.colorGradient-2 {
      background: $grandientDarkBlueBlue;
    }

    &.colorGradient-2 {
      background: $grandientRedGreen;
    }

    &.colorGradient-2 {
      background: $grandientPeachYellow;
    }

    &.colorGradient-2 {
      background: $grandientPurpleBlue;
    }

    @include mobile {
      max-width: unset;
    }

    .cauze-card-header-shadow {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
     justify-content: flex-end;
     align-items: center;

     &.image {
     background-color: rgba(0, 0, 0, 0.4);
     }

    .cauze-card-header-charity-avatar-wrapper {
      display: flex;
      justify-content: center;
      padding-bottom: 1rem;

      .cauze-card-header-charity-avatar {
        margin: 0 5px;
        border: none;
      }
    }

    .name {
      @include text-title;
      height: 64px;
      font-size: 20px;
      line-height: 1.5rem;
      color: $white;
      word-break: break-word;
      text-transform: uppercase;
      margin: 0 auto; 
      text-align: center;
      padding: 0 1rem 1rem 1rem;
      width: inherit;
      z-index: 1000;
    }
  }
  }

  .details {
    padding: 1.5rem 1rem 1.75rem 1rem;
    height: 10.3rem;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    border-top: 0.5px solid $lightGrey;

    .givers {
      @include text-title;
      margin-right: 0.5rem;
      // font-weight: 600;
      text-transform: uppercase;
      color: $brandFocusLight;
      font-size: 1.25rem;
      margin-right: 1rem;

      & .first-giver {
        margin-right: 0px;
      }
    }

    .avatar-list {
      height: 3.4rem;

      .purchase-avatar {
        margin-right: -4px;
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 1.1rem;
        border: 0.5px solid #fc6949;
      }

      .runoff-text {
        @include text-title;
        font-weight: 600;
        cursor: unset;
        text-transform: uppercase;
        color: $grey2;
        margin-left: 1rem;
        font-size: 1rem;
      }
    }

    .learn-more {
      @include text-title;
      color: $brand;
      font-size: 1.25rem;
      text-transform: uppercase;
      text-decoration: underline;
      margin-top: 0.5rem;
    }

    .cauze-card-footer {
      margin-top: 1rem;
      text-transform: uppercase;
      justify-self: flex-end;
    }

    .total {
      @include text-title;
      color: $brandFocusLight;
      font-size: 1.25rem;
      margin-left: 1rem;
    }
  }
}
