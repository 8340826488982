.new-payment-options {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 556px) {
    max-width: 460px;
  }

  h3 {
    text-align: center;
    margin: 1rem 0;
  }

  .option-section {
    border: 1px solid $grey3;
    border-radius: 3px;
    margin: 0 0 5px 0;

    .payment-header {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background-color: $lightGrey2;
      border-bottom: 1px solid $grey3;
      padding: 1.25rem 1rem;
      display: flex;
      justify-content: space-between;

      p {
        @include text-medium;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 15px;
        color: $grey2;
        margin-top: auto;
        margin-bottom: auto;
      }

      div {
        display: flex;
      }

      .payment-header-balance {
        @include text-agenda-bold;
        font-size: 10px;
        line-height: 10px;
        text-transform: uppercase;
        color: $textGrey;
        margin-top: 4.5px;
        margin-right: 1rem;
      }

      .dropdown-cheveron-container {
        margin-right: 0.5rem;
        cursor: pointer;
        height: 1rem;
        width: 1rem;
        margin-top: auto;
        margin-bottom: auto;

        .dropdown-chevron {
          height: 1rem;
          width: 1rem;
          filter: invert(64%) sepia(0%) saturate(131%) hue-rotate(195deg)
            brightness(95%) contrast(94%);

          &.closed {
            transform: rotate(180deg);
          }
        }
      }
    }

    .payment-meta {
      margin: 0.5rem;

      .balance-total {
        @include text-agenda-regular;
        color: $textGrey;
        font-size: 10px;
        line-height: 10px;
        text-transform: uppercase;
        margin-top: 5px;
      }

      .card-image {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      .bank-image {
        height: 1rem;
        margin-left: 0.5rem;
        filter: invert(64%) sepia(0%) saturate(131%) hue-rotate(195deg)
          brightness(65%) contrast(94%);
      }

      .card-total {
        @include text-medium;
        color: $textGrey;
        font-size: 10px;
        text-transform: uppercase;
        margin-right: 1rem;
        text-align: end;
      }

      .divider {
        @include text-regular;
        color: $textGrey;
        font-size: 10px;
        margin-right: 0.25rem;
        margin-left: 0.25rem;
      }

      .balance-remaining {
        @include text-agenda-semi-bold;
        color: $textGrey;
        font-size: 10px;
        text-transform: uppercase;
        text-align: end;
        line-height: 10px;
        margin-top: 5px;
      }

      &-empty {
        height: 2rem;
      }
    }

    .digital-wallet-options {
      padding: 48px 36px 48px 36px;

      @include mobile {
        padding: 48px 36px 96px 36px;
      }

      &.window-open {
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
      }

      &.window-closed {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
        padding-top: 0;
        padding-bottom: 0;
      }

      .digital-wallet {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
        align-items: center;
        justify-content: center;

        .plaid-button-container {
          width: 100%;

          .plaid-button {
            width: 100%;
          }
        }

        .paypal-button-container {
          width: 100%;

          div {
            width: 100%;
          }
        }

        .payment-description {
          @include text-regular;
          padding: 0.5rem 0 1rem 0.25rem;
          line-height: 13px;
          color: $textGrey;
          font-size: 9px;
        }
      }
    }

    .options {
      padding: 2rem 1.5rem 1rem 1.5rem;

      @include mobile {
        padding: 2rem 0.5rem 1rem 0.5rem;
      }

      &.window-open {
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
      }

      &.window-closed {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
        padding-top: 0;
        padding-bottom: 0;
      }

      .digital-wallet {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
        align-items: center;
        justify-content: center;

        .plaid-button-container {
          width: 100%;

          .plaid-button {
            width: 100%;
          }
        }

        .paypal-button-container {
          width: 100%;

          div {
            width: 100%;
          }
        }

        .payment-description {
          @include text-regular;
          padding: 0.5rem 0 1rem 0.25rem;
          line-height: 13px;
          color: $textGrey;
          font-size: 9px;
        }
      }
    }

    input[type='checkbox'] {
      display: inline-block;
      margin-right: 0.75rem;
      margin-left: 0.5rem;
    }

    .stripe-element-wrapper {
      margin: 1rem 7px 2px 7px;
      border: 1px solid $grey3;
      border-radius: 5px;
      padding: 0.5rem;
    }

    .CardField {
      border: 1px solid $lightGrey;
      border-radius: 3px;
    }

    .payment-option {
      @include text-agenda-regular;
      font-weight: 300;
      color: $textGrey;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
      cursor: pointer;

      p {
        font-size: 16px;
        line-height: 16px;

        &.selected {
          color: $black;
        }
      }
    }

    .cauze-checkbox {
      height: 13px;
      width: 13px;
    }

    .share-option {
      margin-left: 4px;

      .cauze-checkbox {
        cursor: pointer;
      }
    }

    .share-message {
      @include text-agenda-regular;
      font-size: 12px;
      line-height: 12px;
      margin-top: auto;
      margin-bottom: auto;
      color: $textGrey;
    }

    .payment-option-disabled {
      @include text-agenda-regular;
      font-weight: 200;
      color: $textGrey;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
      color: $grey50;
      cursor: not-allowed;
    }

    .fee-description {
      background-color: unset;
      padding: 0 0 8px 1rem;
      @include text-regular;
      color: $darkGrey;
    }

    .fee-description-no-element {
      background-color: unset;
      padding: 0 0 0 1rem;
      @include text-regular;
      color: $darkGrey;
    }

    .paypal-button {
      z-index: 1;

      & > div {
        height: 48px;
      }
    }
  }
}
