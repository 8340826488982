.country-select {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.options-list {
  position: absolute;
  top: 110%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  padding: 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .option {
    padding: 6px 8px;
  }
}

.option:hover {
  background-color: $baseColor;
  border-radius: 5px;
  color: white;
}
