.context-selector {
  .context-row {
    cursor: pointer;
    margin: 5px;
    padding: 10px;
    overflow: hidden;
    background-color: $lightGrey;
    border-radius: 5px;
    align-items: center;

    .context-name {
      @include text-bold;
    }
    .context-type {
      @include text-regular;
      color: $black70;
    }
  }
}
