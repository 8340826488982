.entity-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.activity-event-list {
  flex: 1;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  .swiper-slide {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    color: black;
  }

  @media (min-width: 769px) {
    &.show-arrows:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: block !important;
      }
    }
  }

  .entity-activity-item {
    .avatar {
      height: 56px;
      width: 56px;
      border-radius: 9999px;
      border: 4px solid white;
    }
  }
}
