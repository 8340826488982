.header-container {
  background-color: white;
  width: 100%;
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid $grey3;
  justify-content: space-between;

  .header-nav-container {
    display: flex;
    align-items: center;
    width: 100%;
    .header-button-container {
      width: 100%;
      .button-row {
        display: flex;
        width: 100%;
        align-items: center;
      }
    }
  }
  .sub-header-component-container {
    width: 100%;
  }
}
