.footer {
  color: $support;
  margin-top: 1rem;
  padding: 1rem;

  @include tablet {
    padding: 2rem;
    margin-top: 2rem;
  }

  .content {
    margin-top: 1.5rem;
    border-top: 0.25rem solid black;

    &.no-border {
      border: 0;
    }
  }

  &.footer-cobrand {
    background-color: $white;
    padding: 1rem 0 0 0;
    margin-top: 0;

    .content {
      border: 0;
    }

    .cobrand-detail {
      margin: 0 2rem;
      border-top: 0.25rem solid black;
    }

    .cobrand-name {
      @include text-title;
      color: $support;
    }

    .cobrand-title-container {
      display: flex;
      justify-content: center;

      .cobrand-title {
        @include text-title;
        text-transform: uppercase;
        background-color: $white;
        padding: 0 1rem;
        margin-top: -1.5rem;
        margin-bottom: 1rem;

        @include tablet {
          margin-bottom: 2rem;
        }
      }
    }

    .cobrand-logo {
      object-fit: contain;
      object-position: 0 0;
      height: 2rem;
      width: 6rem;
    }

    .footer-links {
      margin-top: 2rem;
      padding: 2rem 1rem 0;

      border-top: thin solid #e1e2e7;
      background-color: #fafafa;
    }

    .footer-title {
      display: none;
    }

    .legal-links {
      padding: 1rem;
    }
  }

  .footer-title {
    margin-top: 2.5rem;
    @include text-title;
    text-transform: uppercase;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  a {
    color: $orange;

    &:hover {
      color: $baseColorTextHighlight;
    }
  }

  .divider {
    @include text-title;
    font-size: 1rem;
    line-height: 1rem;
    margin: 0 0.5rem;

    @include tablet {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  .footer-link {
    @include text-title;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: thin solid $orange;

    @include tablet {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  .button-container {
    margin-top: 2rem;
    justify-content: flex-end;

    .button {
      padding: 1rem 1.5rem;
      font-size: 1.25rem;
    }

    a {
      margin-left: 1rem;
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .mobile-button {
    margin-bottom: 2rem;
  }

  .disclaimer {
    margin-top: 2rem;
    margin-bottom: 1rem;
    @include text-light;
    font-size: 0.75rem;
  }

  .legal-link {
    text-decoration: underline;
    margin-right: 1rem;
    font-size: 1rem;
  }
}
