.cauze-button-container {
  .cauze-button {
    @include text-title;
    font-size: 1rem;
    text-transform: uppercase;
    box-sizing: inherit;
    padding: 0.6rem 1rem 0.5rem 1rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid $brandFocusLight;
    background-color: $brandFocusLight;
    color: $white;
    line-height: 16px;
    cursor: pointer;

    &:hover {
      border: 1px solid $brand;
      background-color: $brand;
    }

    .cauze-spinner {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      display: block;
      top: -0.3rem;

      .cauze-loader {
        width: 1.5rem;
        height: 1.5rem;

        .circular {
          .path {
            animation: dash 1.5s ease-in-out infinite;
            stroke: $white;
          }

          .grey-path {
            stroke: $lightGrey;
          }
        }
      }

      .smile {
        display: none;
      }
    }

    .is-submitting {
      opacity: 0.25;
    }
  }

  .cauze-button-donate {
    box-sizing: inherit;
    padding: 0.6rem 1rem 0.5rem 1rem;
    text-align: center;
    border-radius: 4px;
    color: $white;
    line-height: 16px;
    cursor: pointer;

    .cauze-spinner {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      display: block;
      top: -0.3rem;

      .cauze-loader {
        width: 1.5rem;
        height: 1.5rem;

        .circular {
          .path {
            animation: dash 1.5s ease-in-out infinite;
            stroke: $white;
          }

          .grey-path {
            stroke: $lightGrey;
          }
        }
      }

      .smile {
        display: none;
      }
    }

    .is-submitting {
      opacity: 0.25;
    }
  }

  .grey-theme {
    border: 1px solid $lightGrey2;
    background-color: $lightGrey2;
    color: $grey;

    &:hover {
      border: 1px solid $lightGrey2;
      background-color: $white;
      color: $lightGrey2;
    }
  }

  .black-theme {
    border: 1px solid $black;
    background-color: $black;
    color: $white;

    &:hover {
      border: 1px solid $black;
      background-color: $black;
      color: $white;
    }
  }

  .support-theme {
    border: 1px solid $support;
    background-color: $support;
    color: $white;

    &:hover {
      color: $brandFocusLight;
      border: 1px solid $support;
      background-color: $support;
    }
  }

  .black-border {
    border: 1px solid $trueBlack;
    color: $trueBlack;
    background-color: unset;

    &:hover {
      color: $trueBlack;
      border: 1px solid $trueBlack;
      background-color: unset;
    }
  }

  .black-border-thin {
    border: 0.5px solid $black;
    color: $black;
    background-color: unset;

    &:hover {
      color: $white;
      border: 1px solid $black;
      background-color: $black;
    }
  }

  .grey-border {
    border: 1px solid $textGrey;
    color: $textGrey;
    background-color: unset;

    &:hover {
      color: $brandFocusLight;
      border: 1px solid $brandFocusLight;
      background-color: unset;
    }
  }

  .white-theme {
    border: 1px solid $lightGrey3;
    background-color: $white;
    color: $lightGrey3;

    &:hover {
      color: $brandFocusLight;
      background-color: $white;
      border: 1px solid $brandFocusLight;
    }
  }

  .narrow {
    padding: 0.6rem 1.7rem 0.4rem 1.7rem;
  }

  .small {
    font-size: 1rem;
    padding: 0.5rem 1rem 0.3rem 1rem;
    border-radius: 3px;
  }

  .large {
    font-size: 1.4rem;
    padding: 0.8rem 1.2rem 0.7rem 1.2rem;
    border-radius: 6px;
  }

  .tall {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .no-border {
    border: unset;
    color: grey;
  }
}
