.profile-view-container {
  @include text-agenda-regular;

  background-color: white;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding-top: 0px;
  }

  .profile-view {
    display: flex;
    flex-flow: column;
    background-color: white;
    width: 100%;

    @include mobile {
      max-width: none;
    }

    .group-balance {
      @include text-bold;
      margin-bottom: 1rem;
      font-size: 1rem;
    }

    .charity-select-title {
      display: none;
    }

    .profile-view-edit-button {
      color: $grey6;
      margin-top: auto;
      font-size: 12px;
      cursor: pointer;
    }

    .profile-view-cauzes-title {
      @include text-agenda-bold;
      font-size: 20px;

      margin-top: 21px;

      .profile-view-see-all {
        display: flex;
        justify-content: space-between;

        .see-all {
          @include text-agenda-regular;
          color: $grey7;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
        }

        @include mobile {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
