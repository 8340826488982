.confirm-context {
  background-color: $mediumGrey;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 0;

  .avatar {
    margin-right: 0.5rem;
  }

  .current-context {
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
  }
}
