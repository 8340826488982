.signup-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .background-image {
    background-image: url('../../assets/images/backgrounds/smile-texture.png');
    background-repeat: repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 50%;
  }

  .signup-view {
    width: 545px;
    display: flex;
    gap: 40px;
    padding-bottom: 248px;

    @include mobile {
      flex-direction: column;
      width: 100%;
      padding-bottom: 0px;
    }

    .auth-form-body {
      background-color: white;
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 86px;
      padding-right: 86px;
      display: flex;
      flex-direction: column;
      row-gap: 35px;
      flex-grow: 1;
      border-radius: 5px;
      border: 1px solid $grayscaleLightGray;
      z-index: 10;

      @include mobile {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 0px;
        border: none;
      }

      .signup-view-logos {
        display: flex;
        width: 100%;
        column-gap: 40px;
        justify-content: center;

        svg {
          margin-top: auto;
          margin-bottom: auto;
        }

        .avatar {
          height: 40px;
          width: 40px;
        }
      }

      .signup-view-gift {
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $lightGrey;
        background-color: $lightGrey5;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        align-items: center;

        .gift-icon {
          height: 44px;
          width: 44px;
          background-color: $lightPurple;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .gift-amount {
          @include text-agenda-bold;
          color: $black;
          font-size: 30px;
          line-height: 36px;
          height: 27px;
        }

        .gift-desc {
          @include text-agenda-regular;
          color: $black70;
          font-size: 14px;
          text-align: center;
          line-height: normal;

          .gift-desc-bold {
            @include text-agenda-bold;
            color: $black70;
            font-size: 16px;
            padding-bottom: 3px;
          }
        }
      }

      h1 {
        @include text-agenda-black;
        color: black;
        font-size: 30px;
        text-transform: uppercase;
        text-align: center;
        height: 28px;
        line-height: 100%;
      }

      .signup-view-form {
        .cauze-button {
          @include text-agenda-black;
          width: 100%;
          border-radius: 8px;
          height: 48px;
        }

        .forgot-password {
          @include text-bold;
          font-size: 0.8rem;
          cursor: pointer;
          color: $baseColor;
          padding-bottom: 7px;
        }

        fieldset {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 12px;
          border: none;

          @include mobile {
            display: flex;
            flex-direction: column;
          }

          .form-row {
            display: block;
          }

          .create-account-email,
          .create-account-password {
            grid-column: 1 / 3;
          }

          .password-helper {
            @include text-agenda-regular;
            color: $black70;
            font-size: 12px;
            height: 24px;
          }

          .terms-and-service {
            @include text-agenda-regular;
            display: flex;
            grid-column: 1 / 3;
            align-items: center;
            column-gap: 8px;
            font-size: 12px;

            input {
              -ms-transform: scale(0.75); /* IE */
              -moz-transform: scale(0.75); /* FF */
              -webkit-transform: scale(0.75); /* Safari and Chrome */
              -o-transform: scale(0.75); /* Opera */
              transform: scale(0.75);
            }

            span {
              margin-bottom: 4px;

              b {
                cursor: pointer;
              }
            }
          }

          input,
          textarea {
            @include text-agenda-regular;
            border-radius: 5px;
            border: 1px solid $grayscaleLightGray;
            color: $black70;
            font-size: 14px;
            padding-left: 12px;
            width: 100%;
          }

          input {
            height: 42px;
          }
        }
      }
    }

    .auth-faq {
      /* TODO change */
      width: 374px;
      background-color: white;
    }
  }

  .login-button-container {
    display: flex;
    justify-content: center;

    .login-button {
      @include text-agenda-bold;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      text-decoration: underline;
      width: 42px;
      white-space: nowrap;
    }
  }
}
