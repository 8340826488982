.follow-following-modal {
  width: 100%;

  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-card {
    justify-content: center;

    .modal-card-body {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px;
      border-radius: 10px;
      overflow: hidden;

      @include mobile {
        border-radius: 0px;
      }

      .tabs {
        margin-bottom: 0px;

        .is-active {
          a {
            color: black;
          }
        }

        a {
          @include text-agenda-bold;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding-top: 12px;
          padding-left: 14px;
          padding-right: 14px;
          padding-bottom: 8px;
          font-size: 13px;
          color: $grey2;
        }
      }

      .follow-following-title {
        @include text-agenda-bold;
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        margin-bottom: 28px;
      }

      .follow-following-close {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .follow-following-title-text {
        margin-top: auto;
        margin-bottom: auto;
        line-height: 20px;
      }

      .tabbed-content {
        border-left: 1px solid $lightGrey4;
        padding-top: 26px;
        padding-bottom: 24px;
        padding-left: 12px;
        height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .entity-list-container {
        row-gap: 24px;

        .entity-list-item-container {
          border-bottom: 0px;

          .avatar {
            margin: 0;
            margin-right: 12px;
            height: 42px;
            width: 42px;
          }
        }
      }

      .cauze-spinner {
        margin-top: 5rem;
      }

      .modal-body-list {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $lightGrey2;
      }
    }
  }
}
