.charity-admin-ledger-table {
  overflow: auto;

  .sortable-table-row {
    padding-top: 20px;
    padding-bottom: 20px;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid $lightGrey;
    padding-right: 16px;
    overflow: hidden;

    &.clickable {
      cursor: pointer;
    }

    .donation-date {
      @include text-agenda-regular;
      color: $black;
      font-size: 12px;
      line-height: 19px;
    }

    .donor-name {
      @include text-agenda-bold;
      font-size: 12px;
      color: $black;
      line-height: 17px;
    }

    .donor-email {
      @include text-agenda-regular;
      color: $black70;
      font-size: 11px;
      text-decoration: underline;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .source {
      @include text-agenda-regular;
      color: $black;
      font-size: 12px;
      line-height: 19px;
      white-space: nowrap;

      a {
        text-decoration: underline;
        color: $green3;
      }
    }

    .comment {
      @include text-agenda-regular;
      color: $black;
      font-size: 12px;
      line-height: 12px;
      text-overflow: ellipsis;
      max-height: 36px;
      overflow: hidden;
    }

    .net-donation {
      @include text-agenda-bold;
      color: $black;
      font-size: 12px;
      line-height: 19px;
      text-align: right;
    }

    .granted {
      @include text-agenda-regular;
      color: $black;
      font-size: 12px;
      line-height: 19px;
    }

    .thanked {
      @include text-agenda-regular;
      font-size: 12px;
      line-height: 19px;

      button {
        @include text-agenda-bold;
        width: 64px;
        height: 32px;
        border-radius: 6px;
        padding-top: 6px;
        background-color: $brand;
        color: white;
        border: none;
        cursor: pointer;
      }
    }

    .match-sponsor {
      @include text-agenda-regular;
      font-size: 12px;
      line-height: 19px;
    }
  }

  .sortable-table-expand {
    height: 76px;
    border-bottom: 1px solid $lightGrey;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.expanded {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .sortable-table-expanded-row {
    max-height: 0px;
    overflow: hidden;

    &.full-comment {
      grid-column: 1 / 4;

      .full-comment-title {
        @include text-agenda-bold;
        color: $black;
        font-size: 12px;
        line-height: 19px;
      }

      .full-comment-comment {
        @include text-agenda-regular;
        color: $black;
        font-size: 12px;
        line-height: 12px;
      }
    }

    &.net-donation-expanded {
      grid-column: 4 / 6;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > div {
        display: flex;
      }

      .net-donation-name {
        @include text-agenda-regular;
        color: $black;
        font-size: 12px;
        line-height: 12px;
        text-align: left;
        width: 52px;
      }

      .net-donation-amount {
        @include text-agenda-bold;
        color: $black;
        font-size: 12px;
        line-height: 12px;
        text-align: right;
        min-width: 52px;
      }
    }

    &.grant-type {
      @include text-agenda-regular;
      grid-column: 6 / 10;
      font-size: 12px;
      line-height: 12px;
      padding-left: 0px !important;
      text-transform: capitalize;
      display: flex;
      column-gap: 4px;

      .grant-type-type {
        @include text-agenda-bold;
      }
    }

    &.open {
      max-height: 100vh;
      padding: 20px;
      background-color: $lightGrey;
    }
  }
}
