.create-cauze {
  display: flex;
  flex: 1;
  .input-element {
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    padding: 10.5px;
  }
  .input-container {
    background-color: $white;
    padding: 2rem;
  }

  .input-border {
    border: solid;
    border-color: $black;
    border-width: 1px;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    display: flex;
    padding: 0.5rem;
  }

  .input-border-error {
    border: solid;
    border-color: $red;
    border-width: 1px;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    display: flex;
    padding: 0.5rem;
  }

  .input-title {
    font-family: Agenda-Bold, sans-serif;
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 11px;
  }

  .description-length {
    font-size: 0.7rem;
    color: $darkGrey;
    background-color: $white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: fit-content;
  }

  .description-length-error {
    color: $red;
    font-weight: 500;
  }

  input[type="radio"] {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .input-title-error {
    color: $red;
  }

  .option-group-subhead {
    margin-top: 0.5rem;
  }

  .amount-header {
    font-size: 1.5rem;
    color: $black;
  }

  .create-button {
    margin-top: 1rem;
    width: 100%;
    border-radius: 8px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    padding: 14px;
    border: 1px solid #000;

    span {
      line-height: 21px;
      transform: translateY(2px);
    }
  }

  .match-container {
    padding-left: 2rem;
  }

  .existing-match {
    padding-left: 1rem;
  }

  .amounts-container {
    padding-left: 0.5rem;
  }

  .amount-description {
    font-size: 0.8rem;
    max-width: 26rem;
  }

  .add-funds-warning {
    background-color: $error-background;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-right: 2rem;
    display: flex;
    padding: 0.5rem;

    .error {
      color: $red;
      margin-right: 0.25rem;
    }

    .add-funds {
      margin-top: 0.5rem;
      margin-left: 1rem;
    }
  }
  .event-item-avatar-group {
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;

    .avatar {
      margin-left: -4px;
    }
  }

  .event-item-ellipsis {
    margin-bottom: 6px;
    margin-left: 2px;
    color: $textGrey;

    @media (max-width: 330px) {
      display: none;
    }
  }
}
