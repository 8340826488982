.checkout-input-new {

  &.input-error {
    color: $red;
  }

  margin-bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;

  .input {
    border: 1px solid $grey;
    border-radius: 3px;
    margin-left: -0.25rem;

    &:focus {
      outline: none;
    }
  }

  .input-margin-bottom {
    margin-bottom: 1.05rem;
  }

  .input-error-underline {
    border-style: solid;
    border-color: $red;
    border-width: 0 0 thin 0;
  }

  .error-text {
    font-size: 0.7rem;
  }
}