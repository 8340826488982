.match-table {
  color: $support;
  border-radius: 4px;
  .rt-table {
    @media (min-width: 1024px) {
      min-width: 940px;
    }
  }
  .rt-tr {
    background-color: $white;
    overflow: hidden;
  }

  div[role="menu"] {
    visibility: hidden;
    position: absolute;
  }

  div[role="menu"].visible {
    visibility: visible;
    position: absolute;

    right: 4rem;
    background-color: $white;
  }

  a[role="menuitem"] {
    text-align: initial;
    @include text-regular;
    padding: 4px 8px;
    color: $black;
    font-size: 0.8rem;
  }

  .rt-tf .settings-dots {
    color: $brand;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .sponsor-count {
    @include text-medium;
    color: $brandFocus;
    text-decoration: underline;
    text-align: center;

    &:hover {
      color: $support;
    }
  }

  .col-match-name {
    padding-left: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    .match-name {
      @include text-agenda-medium;
      color: $support;
    }
  }

  .col-header {
    @include text-regular;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .background-grey {
    background-color: $white;
  }

  .eligible-charity-name {
    @include text-agenda-regular-italic;
    &::after {
      content: ",";
      margin-right: 0.5rem;
    }

    &:last-of-type {
      &::after {
        content: unset;
        margin-right: 0;
      }
    }
  }

  .match-dates {
  }

  .match-status {
    padding: 0.1rem;
    border-radius: 5px;
    width: 5rem;

    font-size: 0.8rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    @include text-agenda-regular;
    &.status-active {
      border: 1px solid rgba(163, 184, 108, 1);
      background-color: rgba(163, 184, 108, 0.75);
    }

    &.status-future {
      border: 1px solid rgba(31, 120, 153, 1);
      background-color: rgba(31, 120, 153, 0.75);
    }

    &.status-ended {
      border: 1px solid rgb(247, 186, 94);
      background-color: rgba(247, 186, 94, 0.75);
    }

    &.status-disabled {
      background-color: #ccc;
    }
    span {
      transform: translateY(1px);
    }
  }

  .col-match-name {
    .match-status {
      margin-top: 0.5rem;
    }
  }

  .is-expanded {
    padding-top: 1rem;
  }

  .match-expanded-text {
    color: $darkGrey;
    font-family: text-agenda-regular-italic;
    font-size: 0.8rem;
  }

  .expanded-charities {
    padding-left: 1rem;
    padding-bottom: 0.25rem;
  }
}
