.cauze-fundraiser-event-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  .fundraiser-hero-container {
    width: 100vw;
    background-image: url('../../assets/images/backgrounds/Hero_fundraiser_1.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-clip: border-box;
    background-size: 100% auto;
    height: 33rem;
    background-size: cover;
    z-index: 1;

    @include mobile {
      height: 23rem;
    }

    .hero-image-cover {
      background: $gradientOrangePurpleOpac;
      height: 100%;
      width: 100%;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      @include mobile {
        gap: 1.25rem;
      }

      .hero-header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 700px;

        .hero-title {
          @include text-agenda-bold;
          font-weight: 700;
          font-size: 80px;
          color: $white;
          line-height: 88px;
          text-align: center;

          @include mobile {
            font-size: 40px;
            line-height: 38px;
            margin: 0 1rem;

          }
        }

        .hero-subtitle {
          @include text-agenda-regular;
          font-weight: 400;
          color: $white;
          text-align: center;
          font-size: 1.5rem;

          @include mobile {
            font-size: 15px;
            margin: 0 1rem;
          }
        }
      }

      .cauze-button-container {
        @include mobile {
          flex-wrap: wrap;
          width: 100%;
          margin-top: 2rem;
          display: flex;
          justify-content: center;
        }

        @include mobile {
          margin-top: 0;
        }

        .cauze-button {
          @include text-agenda-black;
          font-size: 29px;
          width: 100%;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;

          @include mobile {
            font-size: 15px;
            padding-top: 1rem;
            padding-bottom: 1rem;
            max-width: 150px;
            margin: 0 auto;
          }
        }

      }
    }
  }

  .event-content {
    flex: 1;
    padding: 1rem;
    background-color: $white;

    .content-header {
      max-width: 1100px;
      @include text-agenda-bold;
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;
      color: $trueBlack;
      margin-top: 4rem;
      margin-bottom: 4rem;

      @include mobile {
        font-size: 22px;
        line-height: 26px;
        margin: 1rem 0.5rem 0 0.5rem;
      }
    }

    .content-body-container {
      display: flex;
      max-width: 1100px;
      width: 100%;
      margin-top: 4rem;
      gap: 2rem;

      @include mobile {
        flex-direction: column;
      }

      .content-body {
        flex-grow: 5;
        max-width: 850px;
        min-width: 500px;

        @include mobile {
          order: 2;
          min-width: unset;
        }

        .content-body-nav {
          display: flex;
          justify-content: space-around;
          align-items: center;
          border-top: 1px solid $grey2;
          border-bottom: 1px solid $grey2;
          height: 60px;

          .content-tab {
            height: 60px;

            @include text-agenda-bold;
            font-size: 30px;
            color: $grey2;
            line-height: 2.25rem;
            text-transform: uppercase;
            cursor: pointer;
            padding: 14px 1rem 0 1rem;

            &.is-active {
              color: $lightPurple;
              border-bottom: 5px solid $lightPurple;
            }

            @include tablet-only {
              font-size: 26px;
            }
          }
        }

        .content-body-nav-mobile {
          width: 100%;
          display: flex;
          flex-direction: column;


          .content-body-nav-select {
            width: 100%;
            height: 60px;
            @include text-agenda-bold;
            font-size: 30px;
            color: $grey2;
            line-height: 2.25rem;
            text-transform: uppercase;
            border-top: 1px solid $textGrey;
            border-bottom: 1px solid $textGrey;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mobile-select-chevron {
              filter: invert(64%) sepia(0%) saturate(131%) hue-rotate(195deg) brightness(95%) contrast(94%);
              height: 18px;
              width: 18px;

              &.is-open {
                transform: rotate(180deg);
              }
            }
          }

          .mobile-select-options {
            background-color: $white;
            @include text-agenda-bold;
            font-size: 30px;
            color: $grey2;
            line-height: 2.25rem;
            text-transform: uppercase;
            border-radius: 4px;

            &.is-open {
              overflow: hidden;
              max-height: 1000px;
              transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
            }

            &.is-closed {
              overflow: hidden;
              max-height: 0px;
              transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
              padding-top: 0;
              padding-bottom: 0;
            }

            .mobile-select-options-item {
              margin: 1rem 0.5rem 1rem 0.5rem;
              border-bottom: 1px solid $textGrey;
              cursor: pointer;
            }
          }
        }
      }

      .content-static-info {
        flex-grow: 4;
        max-width: 400px;

        @include mobile {
          order: 1;
          max-width: 100%;
        }

        .fundraiser-status-card-container {
          width: 100%;
          height: 550px;
          border: 0.5px solid $grey2;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 3rem;

          @include mobile {
            flex-direction: row;
            height: unset;
            flex-wrap: wrap;
            border: none;
            border-top: 0.5px solid $grey2;
            padding: 1rem;
          }

          .amount-rasied-container {

            @include mobile {
              width: 50%;
            }

            .dollar-amount {
              @include text-agenda-black;
              font-size: 70px;
              line-height: 84px;
              color: $brand;

              @include mobile {
                margin-left: 1rem;
                font-size: 40px;
                line-height: 48px;
              }
            }

            .raised {
              @include text-agenda-bold;
              font-size: 37px;
              line-height: 44px;
              color: $brand;

              @include mobile {
                margin-left: 1rem;
                font-size: 25px;
                line-height: 30px;
              }
            }
          }

          .investor-container {

            @include mobile {
              border-left: 0.5px solid $grey2;
              padding-left: 1rem;
              width: 50%
            }

            .investor-count {
              @include text-agenda-black;
              font-size: 35px;
              line-height: 42px;
              color: $brand;

              @include mobile {
                font-size: 22px;
                line-height: 26px;
              }
            }

            .avatar {
              height: 50px;
              width: 50px;
              margin-right: -0.6rem;

              @include mobile {
                height: 38px;
                width: 38px;
              }

            }

            .more-purchasers {

              @include text-agenda-bold;
              font-size: 30px;
              line-height: 36px;
              color: $grey2;
              text-transform: uppercase;

              @include mobile {
                font-size: 15px;
                line-height: 18px;
              }

            }
          }

          .cauze-button-container {
            @include mobile {
              flex-wrap: wrap;
              width: 100%;
              margin-top: 2rem;
              display: flex;
              justify-content: center;
            }

            .cauze-button {
              @include text-agenda-black;
              font-size: 29px;
              width: 100%;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;

              @include mobile {
                font-size: 15px;
                padding-top: 1rem;
                padding-bottom: 1rem;
                max-width: 150px;
                margin: 0 auto;
              }
            }

          }

        }

        .impact-assets-card-container {
          margin-top: 2rem;

          @include mobile {
            display: none;
          }

          .powered-by {
            @include text-agenda-regular;
            font-size: 18px;
            line-height: 21px;
            color: $textGrey;
            text-transform: uppercase;
            margin-bottom: 1rem;
          }

          .impact-assets-title {
            @include text-agenda-black;
            font-size: 18px;
            line-height: 21px;
            color: $textGrey;
            text-transform: uppercase;
            margin: 2rem 0 1rem 0;
          }

          .impact-assets-list {

            .impact-assets-list-item {
              list-style-position: outside;
              margin-left: 1rem;
              @include text-agenda-regular;
              font-size: 15px;
              line-height: 22px;
              color: $textGrey;
            }
          }

          .more-questions {
            @include text-agenda-black;
            font-size: 18px;
            line-height: 21px;
            color: $textGrey;
            text-transform: uppercase;
            margin-top: 2rem;
          }

          .chat-link {
            @include text-agenda-regular;
            font-size: 15px;
            line-height: 22px;
            color: $textGrey;
            text-decoration: underline;
            margin-top: 0.5rem;
          }

        }
      }
    }
  }

  .footer-new {
    background-color: $offWhite5;
  }
}