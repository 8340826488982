.match-flag {
  @include text-title;
  color: $white;
  background-color: $brand;
  font-size: 1rem;
  padding: 1px 8px 0;
  display: inline-block;
}

.match-flag-blue {
  @include text-title;
  color: $white;
  background-color: $support;
  font-size: 1.25rem;
  padding: 6px 1rem 6px 1rem;
  // display: inline-block;
  // border-width: 0px;
  // border-style: solid;
  // border-radius: 2rem;
}

.match-flag-large {
  @include text-title;
  color: $white;
  background-color: $brand;
  font-size: 1.5rem;
  padding: 1px 8px 0;
  display: inline-block;
}
