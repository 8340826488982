// _colors.scss

$brand: rgb(207, 65, 13);
$brandFocus: rgb(241, 91, 39);
$brandFocusLight: rgb(241, 94, 40);
$brandFade: rgba(241, 91, 38, 0.3);
$brandLight: rgb(235, 164, 139);
$brandUltraLight: rgb(253, 247, 245);
$brandNew: rgb(234, 67, 53);
// complement - blue - #1333EA
$complement: rgb(19, 51, 235);
$complementFocus: rgb(66, 92, 238);
$complementFade: rgba(19, 51, 234, 0.2);
$complementBold: rgba(45, 10, 234, 1);

// support - deepPurple - #171643
$support: rgb(23, 22, 68);
$supportFocus: rgb(68, 68, 105);
$supportFade: rgba(19, 51, 234, 0.2);
$lilac: rgb(224, 183, 214);

$black: rgb(27, 32, 49);
$black70: rgba(27, 32, 49, 0.7);
$black50: rgba(27, 32, 49, 0.5);
$black30: rgba(27, 32, 49, 0.3);
$grey: rgb(104, 112, 137);
$grey75: rgba(104, 112, 137, 0.75);
$grey50: rgba(104, 112, 137, 0.5);
$grey30: rgba(104, 112, 137, 0.3);
$grey2: rgb(170, 170, 170);
$grey3: rgb(214 214 214);
$grey4: rgb(201 201 201);
$grey5: rgb(186, 186, 186);
$grey6: rgb(136, 136, 136);
$grey7: rgb(153, 153, 153);
$grey8: rgb(178, 183, 195);
$grey9: #797979;
$lightGrey: rgb(241, 241, 241);
$lightGrey2: rgb(235, 235, 235);
$lightGrey3: rgb(225, 225, 225);
$lightGrey4: rgb(219, 219, 219);
$lightGrey5: rgba(219, 213, 252);
$offWhite: rgb(247, 247, 247);
$offWhite2: 'rgb(245, 245, 245)';
$offWhite3: rgb(253, 247, 245);
$offWhite4: rgb(250, 250, 250);
$offWhite5: rgb(252, 251, 251);
$offWhite6: rgb(251, 253, 254);
$white: rgb(255, 255, 255);

// green
$success: #038164;
// yellow
$warning: #ffc300;
// red
$error: #e60054;
$error-light-red: #fff5f5;
$error-background: #fcded4;

$purple: rgb(95, 0, 199);
$lightPurple: #755ef2;
$chatBackground: rgb(245, 245, 245);
$eventHeaderShadow: rgba(80, 80, 80, 0.6);
$cauzeorange: #ce410d;

$baseColor: #ce410d;
$baseColorBright: rgb(254, 140, 67);
$baseColorLight: rgb(188, 154, 104);
$baseColorMediumDark: #311557;
$baseColorDark: #1c053a;
$baseColorInverse: #aaa039;
$baseColorInverseLight: #d4cb6a;
$baseColorHalfTransparent: rgba(255, 255, 255, 0.5);
$baseColorPale: rgb(252, 105, 73);
$baseColorTextHighlight: rgb(255, 134, 87);
$baseColorPale: rgb(252, 105, 73);
$secondaryColor: rgb(83, 0, 156);
$secondaryColorBright: rgb(119, 0, 210);
$charityAdminGray: rgb(66, 75, 78);
$white: #fff;
$veryLightGrey: rgb(224, 224, 224);
$mediumGrey: rgb(179, 179, 179);
$darkGrey: rgb(114, 116, 118);
$veryDarkGrey: rgb(66, 75, 78);
$lightBorderGrey: rgb(196, 196, 196);
$lighterBorderGrey: rgb(209, 209, 209);
$textGrey: #949494;
$trueBlack: rgba(0, 0, 0, 1);
$orange: #ce410d;
$green: rgba(253, 141, 76, 1);
$green2: #10783a;
$green3: #338efa;
$blue: rgba(27, 194, 245, 1);
$yellow: rgba(245, 229, 125, 1);
$purple: rgb(23, 22, 67);
$red: #e60054;
$pink: #fa7c91;
$chatBackground: rgb(245, 245, 245);
$validationWarning: rgb(245, 229, 125);
$eventHeaderShadow: rgba(80, 80, 80, 0.6);
$thnksBlue: #00b8c7;
$anchorBlue: rgba(0, 38, 100, 1);
$dividerGray: rgba(236, 235, 235, 1);

$baseColorNew: #fa8762;

/* redesign colors */
$grayscaleLightGray: #e6e6e6;
$grayscaleLightGray2: #e5e5e5;

.bg-orange {
  background-color: $orange;
  color: $white;
}

.bg-lilac {
  background-color: $lilac;
}

.bg-purple {
  background-color: $support;
  color: $white;
}

.bg-dark-gray {
  background-color: $darkGrey;
  color: $white;
}

.bg-divider-gray {
  background-color: $dividerGray;
  color: $white;
}

.is-disabled {
  opacity: 0.25;
}

@mixin cauze-gradient {
  background: $support;
  // background: linear-gradient(
  //   150deg,
  //   rgba(253, 141, 76, 1) 0%,
  //   rgba(247, 186, 94, 1) 100%
  // );
}

.cauze-gradient {
  @include cauze-gradient;
}

.background-white {
  background-color: $white;
}

.background-grey {
  background-color: $offWhite4;
}

.background-gradient-1 {
  background: linear-gradient(253deg, #e81cff 1.33%, #40c9ff 100%);
}

.background-gradient-2 {
  background: linear-gradient(
    222deg,
    #fc6949 10.37%,
    rgba(117, 94, 242, 0.56) 76.5%
  );
}

.background-gradient-3 {
  background: linear-gradient(
    253deg,
    #00d1ff 26.63%,
    rgba(5, 0, 255, 0.81) 94%
  );
}

.background-gradient-4 {
  background: linear-gradient(253deg, #37b00d 1.33%, #ff4040 100%);
}

.background-gradient-5 {
  background: linear-gradient(
    253deg,
    #fcea49 1.12%,
    rgba(242, 94, 130, 0.9) 100%
  );
}

.background-gradient-6 {
  background: linear-gradient(
    253deg,
    #30c5d2 21.7%,
    rgba(71, 16, 105, 0.81) 100%
  );
}
