.fundraiser-feed-container {
  @include text-agenda-regular;
  margin: 3rem 0.25rem 1rem 0.25rem;

  @include mobile {
    margin-top: 1rem;
  }

  .fundraiser-feed-item {
    margin-bottom: 2rem;
    margin-top: 2rem;

    .avatar {
      @include mobile {
        height: 3rem;
        width: 3rem;
      }
    }

    .item-content {
      margin: 0 1rem;

      .invested {
        font-size: 25px;

        @include mobile {
          font-size: 1rem;
        }

        .item-actor {
          @include text-agenda-bold;
        }
      }

      .item-comment-body {
        color: $textGrey;
        font-size: 20px;
      }

      .item-meta {
        margin-top: 0.25rem;
        color: $textGrey;
        font-size: 1rem;

        @include mobile {
          font-size: 0.75rem;
        }

        img {
          width: 1rem;
          height: 1rem;
          margin-top: -3px;

          @include mobile {
            width: 0.75rem;
            height: 0.75rem;
            margin-top: -2px;
          }
        }

        .item-date {
          width: 6rem;

          @include mobile {
            width: 4.6rem;
          }
        }

        .likes-container {
          cursor: pointer;
          margin-left: 0.5rem;

          .item-likes {
            margin-left: 0.5rem;
            text-transform: uppercase;
          }
        }

        .liked {
          color: $brandFocusLight;
        }
      }
    }
  }
}