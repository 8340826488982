.footer-new {
  padding: 1rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 21rem;
  width: 100vw;

  .content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 2rem 0;

    @include widescreen {
      @include max-width-default;
    }
  }

  .content-left {
    flex: 1;
    justify-content: space-around;
    align-items: flex-start;
  }

  .content-right {
    flex: 1;
    justify-content: space-around;
    align-items: flex-end;
  }

  a {
    color: $brandNew;

    &:hover {
      color: $baseColorTextHighlight;
    }
  }

  .app-store-links {
    margin-left: 0.5rem;
    margin-bottom: 2rem;

    @include mobile {
      margin-bottom: 1rem;
    }
    .link {
      margin-bottom: 0.25rem;
      .icon {
        height: 3rem;
        width: 8rem;

        @include mobile {
          height: 2rem;
          width: 5rem;
        }
      }
    }
  }

  .social-container {
    margin-bottom: 1rem;
    @include mobile {
      margin-bottom: 0.5rem;
    }
    .social-link {
      margin-left: 0.5rem;
    }
  }

  .logo-container {
    flex: 2;

    @include tablet {
      flex: 3;
    }

    .logo {
      margin-bottom: 1rem;
      image-rendering: crisp-edges;
    }
  }

  .footer-link {
    @include text-title;
    color: $brandFocus;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 0.5rem;

    @include tablet {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  .mobile-button {
    margin-bottom: 2rem;
  }

  .disclaimer {
    @include text-light;
    font-size: 0.5rem;
    color: $grey50;
  }

  .legal-links {
    display: flex;
    flex-direction: row;
    @include mobile {
      flex-direction: column;
    }

    .legal-link {
      @include text-title;
      text-align: center;
      color: $grey50;
      margin-left: 1rem;
      font-size: 1rem;

      @include mobile {
        font-size: 0.75rem;
      }
    }
  }
}
