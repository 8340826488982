.cauze-charity-item {
  width: 110px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &.not-selectable {
    cursor: not-allowed;
  }

  .avatar {
    margin: 0 auto 0.5rem auto;
    height: 50px;
    width: 50px;
  }

  .charity-info {
  max-width: 100px; 

    .charity-name {
    @include text-regular;
    font-size: 0.6rem;
    color: $darkGrey;
    overflow: hidden;
    width: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

  .charity-checkbox-container {
    width: inherit;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .charity-checkbox {
      margin: 0 auto;
      height: 1rem;
      width: 1rem;
      border: 1px solid $textGrey;
      border-radius: 4px;

      &.is-selected {
        background-color: $textGrey;
      }
    }
  }
}