.influencer-admin {
  display: flex;
  flex: 1;

  .dashboard-container {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  option {
    width: 6rem;
  }

  .influencer-admin-table {
    .rt-tbody {
      .col-user-container {
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;

        @include from($tablet) {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        }

        .col-user-details {
          margin-left: 0.5rem;
          justify-content: center;
        }

        .avatar {
          margin: 0.5rem 0.5rem 0.5rem 0;

          @include from($tablet) {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .col-waiting-highlight {
      color: $baseColorTextHighlight;
    }
  }
}
