.CalendarDay__selected_span {
  background: $baseColorLight;
  color: white;
  border: 1px solid $lightGrey;
}

.CalendarDay__selected {
  background: $baseColor;
  border: 1px solid $lightGrey;
}

.CalendarDay__selected:hover {
  border: 1px solid $lightGrey;
}

.CalendarDay__selected:selected {
  border: 1px solid $lightGrey;
}

.CalendarDay__selected:hover {
  background: $baseColor;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  border: 1px solid $lightGrey;
  background: $baseColorLight;
  color: white;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: $baseColor;
  border: 1px solid $lightGrey;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: $baseColor;
}

.DateRangePickerInput__disabled {
  background: unset;
}

.DateInput_input__focused {
  border-bottom-color: $baseColor;
}
