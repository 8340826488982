$fa-font-path: '/assets/fonts/font-awesome';

@font-face {
  font-family: 'alternate-gothic-no-2-d';
  src: url('../assets/fonts/alternate-gothic-no-2-d.ttf') format('truetype');
}

@font-face {
  font-family: 'Walshiem';
  src: url('/assets/fonts/walsheim/gt-walsheim.ttf') format('truetype');
}

@font-face {
  font-family: 'Walshiem-Light';
  src: url('/assets/fonts/walsheim/gt-walsheim-light.ttf') format('truetype');
}

@font-face {
  font-family: 'Walshiem-Medium';
  src: url('/assets/fonts/walsheim/gt-walsheim-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Walshiem-Bold';
  src: url('/assets/fonts/walsheim/gt-walsheim-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Agenda-Black-Italic';
  src: url('/assets/fonts/Agenda/Agenda-Black-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Black';
  src: url('/assets/fonts/Agenda/Agenda-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Bold-Italic';
  src: url('/assets/fonts/Agenda/Agenda-Bold-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Bold';
  src: url('/assets/fonts/Agenda/Agenda-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Light-Italic';
  src: url('/assets/fonts/Agenda/Agenda-Light-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Light';
  src: url('/assets/fonts/Agenda/Agenda-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Medium-Italic';
  src: url('/assets/fonts/Agenda/Agenda-Medium-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Medium';
  src: url('/assets/fonts/Agenda/Agenda-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Regular-Italic';
  src: url('/assets/fonts/Agenda/Agenda-Regular-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Regular';
  src: url('/assets/fonts/Agenda/Agenda-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Semi-Bold-Italic';
  src: url('/assets/fonts/Agenda/Agenda-Semi-Bold-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-Semi-Bold';
  src: url('/assets/fonts/Agenda/Agenda-Semi-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-thin-italic';
  src: url('/assets/fonts/Agenda/Agenda-thin-italic.otf') format('opentype');
}

@font-face {
  font-family: 'Agenda-thin';
  src: url('/assets/fonts/Agenda/Agenda-thin.otf') format('opentype');
}

