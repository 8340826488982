.comment-input {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-top: 2px;
  // margin-bottom: 1rem;
  // padding-bottom: 1rem;

  .comment-area-container {
    display: flex;
    flex-direction: column;
    border: 2px solid $mediumGrey;
    border-radius: 5px;
    background-color: white;

    textarea {
      border: 1px solid $lightGrey;
      border-radius: 5px;
    }

    .comment-area {
      padding: 1rem;
      // box-shadow: 1px 4px 3px -1px gray;

      font-family: 'Walshiem';
    }
    .comment-area.is-image {
      border: 2px solid $mediumGrey;
    }
  }

  p {
    text-align: center;
  }

  .comment-image {
    height: 4rem;
    width: 4rem;
  }

  .image-loading-area {
    height: 4rem;
    width: 4rem;
    background-color: $veryLightGrey;
  }

  .image-container {
    // box-shadow: 1px 4px 3px -1px gray;
    z-index: 1;
    padding: 4px;
    position: relative;
  }

  .remove-container {
    height: 4rem;
    width: 4rem;
    position: relative;
  }

  .image-remove-icon {
    color: $veryDarkGrey;
    font: $size-medium;
    display: block;
    position: absolute;
    height: 1rem;
    width: 1rem;
    z-index: 2;
    top: 0;
    right: 0;
    margin-right: 2px;
    cursor: pointer;
  }

  .camera-icon {
    cursor: pointer;
    margin: 0 1rem;
    height: 1.3rem;
    width: 1.3rem;
  }

  .icon-container {
    margin-top: 1rem;
  }

  .post-text {
    color: $white;
    cursor: pointer;
  }

  .post-text-disabled {
    color: $mediumGrey;
    cursor: not-allowed;
  }

  .post-button {
    border-radius: 3px;
    margin-left: 2rem;
  }

  .comment-title {
    font-size: 1.2rem;
  }

  .comment-subtitle {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
}
