.cauze-match-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;

  cursor: pointer;

  &.not-active {
    opacity: 0.5;
  }

  .avatar {
    height: 50px;
    width: 50px;
  }

  .match-details {
    margin-top: 0.5rem;
    text-align: center;

    .match-amount {
      @include text-agenda-bold;
      font-size: 10px;
      text-align: center;
    }

    .max-match-amount {
      @include text-agenda-regular;
      font-size: 10px;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .match-sponsor {
      @include text-agenda-regular;
      font-size: 10px;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .match-checkbox-container {
    width: inherit;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .cauze-checkbox {
      margin: 0;
    }
  }
}
