.charity-donate-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;

  @include mobile {
    flex-direction: row;
    align-items: center;
    height: 62px;
  }

  @include tablet {
    flex-direction: row;
    align-items: center;
  }
  .charity-search-form {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    .cauze-button-container {
      @include mobile {
          position: absolute;
          right: 10000px;
          height: 1px;
          width: 1px;
      }
    }
   
  }

  .column {
    flex-basis: unset;
  }

  select {
    text-indent: 1px;
    background-color: $white;
    border: unset;
    color: $grey;
  }

  option {
    @include text-regular;
  }

  .input-shadow {
    box-shadow: 5px 5px 10px $grey30;
  }

  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .search-input {
    height: 3rem;
    color: $black;
    font-weight: 600;
    text-indent: 1.8rem;
    outline: none;
    background: transparent;
    background-image: url('../../assets/images/icons/search/search.svg');
    background-repeat: no-repeat;
    background-position: 8px center;
    min-width: 0px;
    width: 100%;
    flex: 3;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey4;
      opacity: 1; /* Firefox */
      font-weight: 400;
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $grey4;
      font-weight: 400;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $grey4;
      font-weight: 400;
    }
   
    &-location {
      background: transparent;
      background-image: none;
      outline: none;
      color: $black; 
      min-width: 0px;
      font-weight: 600;
      width: 100%;
      flex: 2;
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $grey4;
        opacity: 1; /* Firefox */
        font-weight: 400;
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $grey4;
        font-weight: 400;
      }
      
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $grey4;
        font-weight: 400;
      }
      
    }

    @include tablet {
      border-radius: 0;
    }

    @include mobile {
      height: 3rem;
      color: $black;
      min-width: 0px;
      width: 100%;
      flex: 1 1;
      &-location {
        flex: 1 1;
      }
    }
  }

  .search-country {
    padding-bottom: 1rem;
    flex: 1;
    margin-right: 0.75rem;

    @include tablet {
      padding-bottom: 0;
      position: absolute;
      bottom: -30px;
      right: 0;
      display: flex;
      justify-content: flex-end;
    }

    @include mobile {
      display: none;
    }

    font-size: 0.75rem;

    .current-country {
      color: $white;
      font-weight: 700;
      font-size: 0.75rem;
      margin-right: 0.25rem;
    }

    .change-country {
      color: $white;
      padding-left: 0.25rem;
      text-decoration: underline;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .margin-bottom-responsive {
    margin-bottom: 0;

    @include tablet {
      margin-bottom: 0rem;
    }
  }

  .map-marker {
    color: $grey4;

  }

  .charity-search-separation {
    background-color: $white;
    line-height: 2.5rem;
    font-size: 2rem;
    font-weight: 100;
    color: $textGrey;
    height: 3rem;
    border: none;

  }

  .location-search {
    flex: 2;
    height: 3rem;
    border: none;
    
  }

  .search-button-desktop {
    flex: 1;
    display: flex;
    cursor: pointer;
    background-color: $white;
    padding: 0.5rem;
    width: 7rem;
    max-height: 3rem;

    .search-icon-container {
      background-color: $brand;
      height: 2rem;
      width: 2rem;
      display: flex;
      flex: 1;

      .search-icon {
        color: $white;
      }
    }
  }

  .search-button-mobile {
    display: none;
  }
}
