.cauze-checkbox {
  height: 0.9rem;
  width: 0.9rem;
  border: 1px solid $textGrey;
  border-radius: 2px;
  margin: 0 0.6rem 0 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-selected {
    background-color: $textGrey;

    &:hover {
      background-color: $darkGrey;
    }
  }

  .checkbox-checkmark {
    height: 7px;
    width: 7px;
  }
}
