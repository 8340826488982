.event-view {
  display: flex;
  flex: 1;

  .event-host-update {
    .host-avatar {
      height: 40px;
      width: 40px;
      border-radius: 9999px;
    }

    .comment-highlighted {
      svg {
        fill: $cauzeorange;
        path {
          stroke: $cauzeorange;
        }
      }
    }
  }

  .expired-text {
    @include text-agenda-bold;
    text-align: center;
    line-height: 16px;
    margin-top: 3px;
  }

  .event-view-container {
    margin-bottom: 0rem;

    .card-item {
      background-color: white;
      @include shadow-medium;
      margin-top: 0.5rem;

      @media (min-width: 650px) {
        border-radius: 5px;
      }
    }

    .event-image-container {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      /* 16:9 Aspect Ratio */
    }

    .invite-header {
      padding: 1rem;
      background-color: $complement;
      color: $white;

      @include widescreen {
        @include max-width-default;
      }

      width: 100vw;

      .avatar {
        margin-right: 1rem;
      }

      h1 {
        font-size: 2rem;
        color: $white;
      }

      .subtitle {
        font-size: 0.8rem;
        color: $white;
        margin-bottom: 1rem;
        margin-top: -0.5rem;
      }

      .edit-match {
        cursor: pointer;
        color: $white;
      }
    }

    .new-header {
      @include max-width-default;
      display: flex;
      flex-direction: column;
      background: black;
      position: relative;
      width: 100%;
      padding-bottom: 1.5rem;

      @media (min-width: 650px) {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &.header-image {
        height: 56.25vw;
        max-height: 360px;

        @include tablet {
          @include max-width-default;

          height: 360px;
        }
      }

      &.expanded {
        height: unset;
        max-height: unset;
        min-height: 450px;
      }

      &.header-gradient {
        background: transparent
          linear-gradient(195deg, #f15a29 0%, #0e1971 100%) 0% 0% no-repeat
          padding-box;
      }

      .header-bg {
        width: 100%;
        z-index: 0;
        opacity: 0.4;
        position: absolute;
        padding: 0;
        align-items: flex-end;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        @media (max-width: 800px) {
          background-size: cover;
        }
      }

      .header-bg-fade {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        &.expanded {
          background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 400px
          );
        }
      }

      .share-logo {
        cursor: pointer;
      }

      .header-content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
      }

      .event-name {
        @include text-title;
        font-size: 1.5rem;
        color: white;
        text-transform: uppercase;
        padding-top: 1rem;
        line-height: normal;
        text-align: center;
        margin: 0 1rem 1rem 1rem;

        @include tablet {
          padding-top: 2.5rem;
          font-size: 2.5rem;
        }
      }

      h2 {
        color: white;
        font-size: 0.75rem;
        text-align: center;
        margin: 0 1rem;

        @include tablet {
          font-size: 100%;
        }
      }

      .charity-avatar {
        // hide for iphone pro and smaller
        display: none;
        border-radius: 50%;
        margin: 0.5rem;
        width: 3.5rem;
        height: 3.5rem;
        @include shadow-medium;

        @media (min-width: 429px) {
          display: block;
        }
      }

      .share {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
      }

      .host-name {
        @include text-title;
        color: $white;
        font-size: 1.25rem;

        &.many-hosts {
          margin-top: 2rem;
        }
      }

      .hosts {
        display: none;
        align-self: stretch;
        margin-top: 0.25rem;
        align-items: center;

        @media (min-width: 429px) {
          display: flex;
        }

        .hosts-container {
          display: flex;
          flex-direction: row;
          align-self: stretch;
          justify-content: space-around;
          align-items: center;

          &.many-hosts {
            .host-avatar {
              height: 2.5rem;
              width: 2.5rem;
              margin-right: -0.75rem;
            }
          }
        }

        .host {
          margin: 0.25rem;
          max-width: 8rem;
          flex: 1;
          display: flex;
          justify-content: flex-start;

          .host-name {
            @include text-title;
            font-size: 1.25rem;
            color: white;

            line-height: 1;
            text-align: center;
          }

          .host-avatar {
            width: 3.75rem;
            height: 3.75rem;
            display: flex;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
          }
        }
      }
    }

    .event-feed-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1.75fr 1fr;
      margin-right: 20px;
      margin-left: 16px;

      @media (max-width: 1187px) {
        grid-template-columns: 1fr;
        margin-right: 0px;
        margin-left: 0px;
      }
    }

    .event-data-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .feed {
        padding: 0 0;
      }
    }
    .event-title-container {
      width: 100%;
      margin-bottom: 20px;
      padding: 0 10px;

      @media (max-width: 769px) {
        display: none;
      }

      .title-container {
        .title-text {
          @include text-agenda-black;
          font-size: 30px;
          line-height: 30px;
          margin: 10px 0;
          color: black;
        }
      }

      .subtitle-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        @include text-agenda-regular;
        font-size: 13px;

        .subtitle-host-container {
          .hosted-by-text {
            color: $textGrey;
          }
          .hosted-by-name {
            color: $black;
            text-decoration: underline;
          }
        }

        .match-details-container {
          color: $textGrey;
          .subtitle-pipe {
            margin: 0 5px;
          }
        }

        .event-end-date-container {
          color: $textGrey;

          .subtitle-pipe {
            margin: 0 5px;
          }

          .subtitle-calendar {
            margin: 0 3px 0 0;
          }
        }
      }
    }

    .new-event-header {
      @include max-width-default;
      flex-direction: column;
      position: relative;
      width: 100%;
      padding-bottom: 1.5rem;
      display: grid;
      grid-template-areas:
        'header-image header-info'
        'charities-container header-info';
      grid-template-columns: 1.75fr 1fr;
      grid-template-rows: 3fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
      @include mobile {
        padding-bottom: 8px;
      }

      @media (max-width: 768px) {
        min-height: 500px;
        grid-template-areas:
          'header-image'
          'header-info'
          'charities-container';
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
      }

      .charity-list-new {
        margin: 0px;
        min-width: 300px;
      }

      .header-image-container {
        overflow: hidden;
        height: 100%;
        width: 100%;
        min-height: 150px;
        position: relative;

        .pin-button {
          position: absolute;
          top: 4px;
          right: 4px;

          @include mobile {
            top: 6px;
            right: 6px;
          }
        }

        @media (min-width: 769px) {
          border-top-left-radius: 15px;
        }

        @media (min-width: 940px) {
          min-width: 300px;
        }

        .header-image {
          height: 100%;
          width: 100%;
          object-fit: cover;
          max-height: 350px;
          grid-area: header-image;

          @media (max-width: 769px) {
            max-height: 180px;
          }

          &.linear-gradient-image {
            // min-width: 30px;
            // min-height: 300px;
            background: transparent
              linear-gradient(195deg, #f15a29 0%, #0e1971 100%) 0% 0% no-repeat
              padding-box;
          }
        }

        .header-image-pin {
          position: absolute;
          top: 4px;
          right: 4px;
        }
      }

      .header-info-container {
        flex: 1.1;
        display: flex;
        flex-direction: column;
        grid-area: header-info;

        .header-info-flex-container {
          padding: 15px;
          flex: 1;
          display: flex;
          justify-content: center;

          @media (min-width: 769px) {
            border: 1px solid $grey3;
          }

          @media (max-width: 768px) {
            border-bottom: 1px solid $grey3;
            padding-top: 0px;
          }

          .header-description-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 8px;

            @media (min-width: 769px) {
              border-top-right-radius: 15px;
            }

            @media (max-width: 768px) {
              border-top: none;
            }

            .host-avatar-container {
              display: flex;
              align-items: center;
              flex-direction: row;
              gap: 4px;

              @media (max-width: 768px) {
                flex-direction: column;
                text-align: center;

                &.no-avatar {
                  margin-top: 0px;
                }

                .avatar {
                  z-index: 1;
                }
              }

              .event-name {
                @include text-agenda-bold;
                font-size: 20px;
                line-height: 20px;
                color: $black;

                @media (min-width: 769px) {
                  display: none;
                }
              }

              .host-name-container {
                display: flex;
                justify-content: center;

                @media (max-width: 768px) {
                  margin-top: 10px;
                }

                .host-name {
                  @include text-agenda-regular;
                  font-size: 20px;
                  color: black;
                  margin-left: 10px;
                  line-height: 20px;

                  &.bold {
                    @include text-agenda-bold;
                    margin-left: 1px;
                  }

                  @media (max-width: 768px) {
                    font-size: 13px;
                    color: $textGrey;
                    margin-left: 0px;
                  }
                }
              }

              .match-details-text {
                font-size: 12px;
                color: $textGrey;
                display: inline-block;

                @media (min-width: 769px) {
                  display: none;
                }
              }

              .event-end-date-text {
                font-size: 12px;
                color: $textGrey;
                display: inline-block;
              }
            }

            .header-description-text-container {
              @media (max-width: 768px) {
                margin-top: 20px;
              }

              margin-top: 10px;
              font-size: 15px;
              line-height: 15px;
              color: $textGrey;
              display: block;
              flex: 1;
              overflow: hidden;
              position: relative;
              max-height: 100px;

              &.hide-on-mobile {
                @media (max-width: 768px) {
                  display: none;
                }
              }

              .header-description-text {
                @include text-agenda-regular;
              }

              .read-more-text {
                @include text-agenda-bold;
                display: inline;
                margin-left: 10px;
              }

              .read-more-link {
                @include text-agenda-bold;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $offWhite4;
                width: 100%;
                cursor: pointer;
              }
            }

            .expanded {
              max-height: none;
            }
          }
        }

        .header-action-container {
          flex: 1.5;
          border: 1px solid $grey3;
          border-top-width: 0;
          flex-grow: 1.5;
          padding: 15px;

          .amount-raised-container {
            max-height: 50px;
            margin: 10px 0;

            .amount-raised-text {
              @include text-agenda-black;
              font-size: 60px;
              line-height: 60px;
              justify-content: flex-end;
              color: black;
              margin-left: 1px;
              @media (max-width: 768px) {
                font-size: 45px;
              }
              .raised-text {
                @include text-agenda-regular;
                font-size: 17px;
                color: $textGrey;
              }
            }
          }

          .joiners {
            display: flex;
            flex-direction: row;
            padding: 0 0 17px 0;
            border-radius: 5px;
            align-items: center;

            @media (min-width: 940px) {
              flex-direction: row;
            }

            .giver-text {
              @include text-agenda-regular;
              font-size: 13px;
              line-height: 13px;
              margin-right: 10px;
              color: $textGrey;
              text-transform: uppercase;
            }
            .avatar-container {
              display: flex;
              justify-content: center;
              .joiner {
                margin-right: -5px;
              }

              .more-givers-avatar {
                color: white;
                border: none;
                width: 2rem;
                margin-left: 5px;
                background-color: transparent;
                cursor: pointer;
              }
            }
          }

          .event-join-button {
            background-color: black;
            border: none;
            width: 100%;
            padding: 15px 0;
            @include text-agenda-black;
            font-size: 17px;
            border-radius: 10px;
          }

          .event-details-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @include text-agenda-regular;
            font-size: 15px;
            color: $textGrey;
            margin-top: 20px;

            .event-end-date-container {
              margin-right: 20px;

              .event-end-date-text {
                margin-left: 5px;
              }
            }

            .inspire-button {
              border: 1px solid $textGrey;
              border-radius: 10px;
              width: 100%;
              text-align: center;
              padding: 8px 0;
              cursor: pointer;

              .inspire-text {
                margin-left: 5px;
                font-size: 17px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }

    .charities-container {
      width: 100%;

      .charities-container-content {
        width: 100%;
        height: 100%;
      }
    }

    .event-content-container {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      @media (min-width: 769px) {
        flex-direction: row;
      }

      .description-container {
        flex: 2;
        padding-right: 20px;
        display: flex;
        flex-direction: column;

        .overview-text {
          @include text-agenda-black;
          font-size: 30px;
          text-transform: uppercase;
          color: black;
        }

        .description-body {
          @include text-agenda-regular;
          font-size: 18px;
          line-height: 22px;
          display: inline;

          p {
            margin-bottom: 20px;
          }
        }

        .read-more-description-text {
          @include text-agenda-bold;
          font-size: 18px;
          color: $textGrey;
          cursor: pointer;
        }
      }

      .right-content-container {
        flex: 1.1;
        flex-direction: column;
        width: 100%;

        @media (max-width: 769px) {
          margin-bottom: 20px;
        }

        .match-container {
          display: flex;
          flex-direction: row;
          padding: 17px 20px;
          background-color: $lightGrey2;
          border-radius: 5px;
          align-items: center;

          .match-avatar {
            height: 50px;
            width: 50px;
            border-radius: 30px;
            margin-right: 10px;
          }

          .match-info-container {
            overflow: hidden;

            .match-sponsor-name-text {
              @include text-agenda-bold;
            }

            .match-multiplier-text {
              @include text-agenda-regular;
              margin-bottom: 3px;
            }

            .match-amount-text-container {
              @include text-agenda-regular;
            }

            font-size: 17px;
            line-height: 17px;
            color: $textGrey;
          }
        }
      }
    }

    .event-image {
      color: white;
      padding: 0;
      align-items: flex-end;
      border-style: solid;
      border-color: $support;
      font-size: 1.5rem;
      border-width: thin 0 0 0;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .video-container {
      @include widescreen {
        @include max-width-default;
      }

      width: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      background-color: black;
    }

    .event-video {
      height: 56.25vw;
      max-height: 360px;
      flex: 1;
      display: flex;
      justify-content: center;

      @include mobile {
        max-height: none;
      }

      iframe {
        width: 100vw;
        max-height: 360px;
        height: 56.25vw;
        border-top-left-radius: 10px;

        @include mobile {
          max-height: none;
          border-top-left-radius: 0px;
        }
      }
    }

    .subtitle {
      @include text-regular;
      color: $black;
      font-size: 1rem;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .share {
      margin-left: 1rem;
    }

    .detail-row {
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      display: flex;
      padding: 0.5rem 0 0.5rem 0;
      background-color: $white;
      margin-top: 1rem;
      margin-bottom: 2rem;

      .section-header-title {
        color: $black;
      }

      @include tablet {
        padding: 1rem 0 1rem 0;
      }

      &.hosts {
        display: none;
        padding: 0 1rem 0.5rem 1rem;
        align-items: center;

        @media (min-width: 650px) {
          border-radius: 5px;
        }

        .hosts-container {
          flex-direction: column;
          flex-wrap: wrap;
          flex: 1;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          display: flex;

          .host {
            display: flex;
            flex-direction: row;
            margin-top: 0.5rem;
            margin-right: 0.25rem;
            align-items: center;
            flex-shrink: 1;

            .host-avatar {
              width: 1.5rem;
              height: 1.5rem;
              border-radius: 1.5rem;
              margin-right: 0.5rem;
              margin-left: 0.5rem;
              margin: 0 0.5rem 0.5rem 0.5rem;

              &:last-of-type {
                margin-right: 0;
              }
            }

            .host-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: white;
              margin-left: 0.25rem;
              font-size: 0.75rem;
              flex-shrink: 1;
            }
          }
        }
      }

      &.charities {
        padding: 1rem 0;

        @include tablet {
          padding: 2rem 0;
        }
      }

      &.matches {
        padding: 1rem 0;

        @include tablet {
          padding: 2rem 0;
        }
      }

      &.detail-title {
        padding-left: 1rem;

        @include tablet {
          padding-left: 2rem;
        }

        h1 {
          @include text-title;
          font-size: 1.5rem;
          line-height: 1.2;

          @include tablet {
            font-size: 2rem;
          }
        }
      }

      &.description {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .description-text {
          color: $white;
          display: flex;
          flex-direction: column;
          flex: initial;
          min-width: 0;
          min-height: 0;
          text-align: center;

          p {
            margin-bottom: 1rem;
          }

          strong {
            color: $white;
          }

          a {
            @include text-bold;
          }

          &.hide-more {
            flex: 0 0 6rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .gradient-back {
          background: linear-gradient(
            to bottom,
            rgba(23, 22, 68, 0) 0%,
            rgba(23, 22, 68, 1) 25%
          );
        }

        .read-more-button {
          cursor: pointer;
          display: flex;
          position: relative;
          flex: 0 0 3rem;
          justify-content: center;
          align-items: flex-end;
          margin-top: -1.5rem;
          text-align: center;
          color: $white;
          @include text-bold;
        }
      }
    }

    .detail-column {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-left: 1rem;
      padding-right: 1rem;
      border-style: solid;
      border-color: white;
      border-width: 0 thin 0 0;

      &:last-of-type {
        border: none;
      }

      &.left-align {
        align-items: flex-start;
      }

      .joiners {
        display: flex;
        flex-direction: row;
        color: white;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 0.25rem;

        .joiner.avatar {
          height: 3rem;
          width: 3rem;
          border-radius: 1.5rem;
          margin-right: -0.5rem;
          cursor: pointer;
        }

        .joiners-text {
          font-size: 1rem;
          line-height: 0.5rem;
          padding: 0.75rem 0.8rem 0.75rem 0.8rem;
          border-radius: 1.5rem;
          background-color: $complement;
          color: $white;
        }

        .more-givers-avatar {
          background-color: $lightPurple;
          color: white;
          height: 3rem;
          width: 3rem;
          border-radius: 1.5rem;
          border: 1px solid $black;
          cursor: pointer;
        }
      }

      .more-joiners {
        font-size: 0.8rem;
        color: white;
        display: flex;
        background-color: $veryDarkGrey;
        justify-content: center;
        align-items: center;
      }
    }

    .new-subheader {
      margin-top: 1rem;
      background-color: $white;
      width: 100%;

      .description {
        max-width: 56rem;
        margin: 2rem auto 2rem auto;

        a {
          @include text-bold;
          color: $brandFocusLight;

          &:hover {
            color: $brand;
          }
        }

        .description-body {
          padding-left: 50px;
          padding-right: 50px;
          color: $textGrey;
          font-size: 1rem;

          p {
            margin-top: 10px;
            margin-bottom: 10px;
          }

          &.hide-more {
            flex: 0 0 3rem;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (min-width: 429px) {
              flex: 0 0 4.5rem;
            }
          }

          @media (min-width: 429px) {
            font-size: 1rem;
          }
        }

        .read-more-button {
          cursor: pointer;
          display: flex;
          position: relative;
          flex: 0 0 3rem;
          justify-content: center;
          align-items: flex-end;
          margin-top: -1.25rem;
          text-align: center;
          color: $white;
          @include text-bold;
          font-size: 0.85rem;
        }
      }

      hr {
        width: 90%;
        margin: 1.5rem auto 0 auto;
        background-color: $grey3;
      }

      .detail-row {
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        display: flex;
        padding: 0.5rem 0 0.5rem 0;

        @include tablet {
          padding: 1rem 0 1rem 0;
        }

        &.hosts {
          display: none;
          padding: 0 1rem 0.5rem 1rem;
          align-items: center;

          @media (min-width: 650px) {
            border-radius: 5px;
          }

          .hosts-container {
            flex-direction: row;
            flex-wrap: wrap;
            flex: 1;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            display: flex;

            .host {
              display: flex;
              flex-direction: row;
              margin-top: 0.5rem;
              margin-right: 0.25rem;
              align-items: center;
              flex-shrink: 1;

              .host-avatar {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 1.5rem;
                margin-right: 0.5rem;
                margin-left: 0.5rem;

                &:last-of-type {
                  margin-right: 0;
                }
              }

              .host-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white;
                margin-left: 0.25rem;
                font-size: 0.75rem;
                flex-shrink: 1;
              }
            }
          }
        }

        &.charities {
          padding: 1rem 0;

          @include tablet {
            padding: 2rem 0;
          }
        }

        &.matches {
          padding: 1rem 0;

          @include tablet {
            padding: 2rem 0;
          }
        }

        &.detail-title {
          padding-left: 1rem;

          @include tablet {
            padding-left: 2rem;
          }

          h1 {
            @include text-title;
            font-size: 1.5rem;
            line-height: 1.2;

            @include tablet {
              font-size: 2rem;
            }
          }
        }

        &.description {
          padding: 1rem;
          display: flex;
          flex-direction: column;

          .description-text {
            color: $white;
            display: flex;
            flex-direction: column;
            flex: initial;
            min-width: 0;
            min-height: 0;
            text-align: center;

            p {
              margin-bottom: 1rem;
            }

            strong {
              color: $white;
            }

            a {
              @include text-bold;
            }

            &.hide-more {
              flex: 0 0 6rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .gradient-back {
            background: linear-gradient(
              to bottom,
              rgba(23, 22, 68, 0) 0%,
              rgba(23, 22, 68, 1) 25%
            );
          }

          .read-more-button {
            cursor: pointer;
            display: flex;
            position: relative;
            flex: 0 0 3rem;
            justify-content: center;
            align-items: flex-end;
            margin-top: -1.5rem;
            text-align: center;
            color: $white;
            @include text-bold;
          }
        }
      }

      .event-title {
        @include text-title;
        font-size: 2.5rem;
        color: $brandFocusLight;
        text-transform: uppercase;
        line-height: normal;
        text-align: left;
        margin: 0;
      }

      .detail-column {
        padding: 0;
        margin: 1rem;
        flex: 1;

        @include tablet {
          padding: 0 1rem;
        }

        &:first-of-type {
          border-right: 1px solid $grey50;
          margin-right: 0;
          margin-top: 0.5rem;
          margin-bottom: 0;
          padding: 1.5rem 1rem 1rem 0;
        }

        &:only-child {
          border: 0;
        }

        &.call-to-action {
          .button {
            background-color: $support;
            @include shadow-medium;
          }
        }
      }

      .joiners {
        justify-content: flex-start;
      }

      .joiners-text {
        color: $complement;
        font-size: 0.75rem;
        margin-left: 0.5rem;
      }

      .amount-raised {
        @include text-title;
        font-size: 2.5rem;

        .amount {
          color: $lightPurple;
        }

        .raised {
          color: $brandFocusLight;
        }
      }
    }

    .donate-button-container {
      padding: 2rem;

      .donate-button {
        max-width: 25rem;
        margin: auto;
      }
    }

    .blue-bg {
      background-color: $support;
    }

    // hide app ad for unauthed
    .card-item.unauthed {
      display: none;

      @media (min-width: 429px) {
        display: flex;
      }
    }

    .download-app-ad {
      margin: 0;
      background-color: transparent;
      padding: 1rem;

      img {
        // hide for iphone pro and smaller
        display: none;

        @media (min-width: 429px) {
          display: block;
        }
      }

      @include tablet {
        margin: 0;
        max-width: unset;
        padding: 2rem;
      }
    }
  }

  .feed-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10rem;
    align-items: center;
  }

  .recent-giving-activity {
    width: 100%;
    text-align: left;
  }
}

.cauze-video-container {
  width: 100%;
  overflow: hidden;

  video {
    border-radius: 0px;
    max-height: 402px;

    @include mobile {
      max-height: unset;
    }
  }
}

.cauze-video {
  border-radius: 10px;
  overflow: hidden;

  video {
    border-radius: 10px;
    overflow: hidden;
  }
}

.video-modal {
  @include mobile {
    .cauze-video {
      border-radius: 0px !important;
    }

    video {
      border-radius: 0px !important;
    }
  }
}
