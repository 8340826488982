.ledger-table {
  border-radius: 4px;

  .rt-table {
    @media (min-width: 1024px) {
      min-width: 1010px;
    }
  }
  .rt-tbody {
    .col-user-container {
      margin: 0.5rem 0.5rem 0.5rem 0;

      @include from($tablet) {
        margin: 0.5rem 0.5rem 0.5rem 0.2rem;
      }

      .avatar {
        margin: 0.5rem 0.5rem 0.5rem 0;

        @include from($tablet) {
          margin-right: 0.5rem;
        }
      }

      .col-user-details {
        justify-content: center;

        .col-user-name {
          font-size: 15px;
          font-family: Agenda-Bold, sans-serif;
        }

        .col-user-email {
          font-size: 12px;
        }
      }
    }

    .rt-td {
      cursor: pointer;
    }
  }
}
