.download-app {
  background-color: white;
  display: flex;
  flex: 1;
  flex-flow: column;
  margin: auto;

  .app-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
    margin: 1.5rem;
  }

  .download-background {
    background-color: $support;
    padding: 2rem 4rem 4rem;
  }

  .title {
    @include text-title;
    color: white;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    color: white;
    margin-bottom: 3rem;

    strong {
      color: white;
    }
  }

  input {
    max-width: 10rem;
    margin-right: 0.5rem;
  }
}
