.sponsored-card-wrapper {
    max-width: 588px;
    height: 358px;
    margin: 1rem;    

    @include mobile {
        height: 300px;
    }

    .card {
        height: 100%;
        overflow: hidden;

        .card-header {
            height: 7rem;
            box-shadow: none;


            .card-header-image {
                height: 3.5rem;
                margin: 3rem auto 2rem 2rem;

                @include mobile {
                    margin: 1.5rem auto 1rem 1rem;
                }
            }
        }

        .card-content {
            box-shadow: none;
            padding: 2rem 3rem 3rem 3rem;
      
            @include mobile {
                padding: 1rem 1.5rem 1.5rem 1.5rem;
            }

            .content {
                @include text-regular;
                color: $mediumGrey;
                font-size: 20px;

                @include mobile {
                    font-size: 16px;
                }
            }
            
        }
    }
}