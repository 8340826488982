.saved-payment-methods {
  .saved-payment-method {
    @include text-agenda-bold;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $lightGrey;

    .uapm-name {
      font-size: 17px;
      line-height: 17px;
      height: 17px;
      color: $lightPurple;
      flex: 1;
      text-transform: capitalize;
      margin-top: auto;
      margin-bottom: auto;
      padding-top: 2px;
    }

    .uapm-brand-container {
      display: flex;
      justify-content: space-between;
      flex: 1;
      column-gap: 20px;

      @include mobile {
        justify-content: flex-end;
        column-gap: 6px;
      }
    }

    .uapm-brand {
      margin-top: auto;
      margin-bottom: auto;
      height: 16px;
      width: 25px;
    }

    .uapm-last4 {
      font-size: 17px;
      line-height: 17px;
      height: 17px;
      padding-top: 2px;
      color: $lightPurple;
      margin-top: auto;
      margin-bottom: auto;
      width: 42px;
      text-align: left;
    }

    .uapm-default-btn {
      @include text-agenda-regular;
      font-size: 13px;
      line-height: 15.47px;
      width: 100px;
      height: 34px;
      border-radius: 6px;
      background-color: white;
      color: black;
      border: 1px solid black;
      padding-top: 4px;
      cursor: pointer;

      &.is-default {
        background-color: black;
        color: white;
      }

      @include mobile {
        display: none;
      }
    }

    .uapm-x-btn {
      background-color: transparent;
      border: none;
      margin: 0px;
      margin-top: auto;
      margin-bottom: auto;

      @include mobile {
        display: none;
      }
    }
  }

  .saved-payment-button-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 40px;

    #plaid-button {
      @include text-agenda-bold;
      width: 100%;
      height: 40px;
      font-size: 13px;
      line-height: 15.83px;
      text-align: center;
      color: $black;
      border: 1px solid black;
      border-radius: 5px;
      background-color: white;
      padding-top: 4px;
      cursor: pointer;
    }

    .saved-add-card {
      button {
        &.saved-add-card-btn {
          background-color: black;
          color: white;
          border: none;
        }
      }
    }

    .saved-payment-digital-wallet {
      display: flex;
      justify-content: center;
      column-gap: 10px;

      @include mobile {
        flex-direction: column;
        align-items: center;
      }

      .saved-payment-paypal {
        width: 400px;
      }
    }
  }
}
