@keyframes spinAround
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)

@keyframes spinHalf
  from
    transform: rotate(0deg)
  to
    transform: rotate(180deg)

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)