.donation-success-view {
  .donation-success-view-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .donation-success-view-header {
      margin: 2rem 0;
      max-width: 24rem;

      .donation-success-view-title {
        @include text-agenda-black;
        font-size: 24px;
        line-height: 28.8px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1rem;
      }

      .donation-success-view-subtitle {
        @include text-agenda-medium;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        text-align: center;
        color: $textGrey;
      }
    }

    .donation-success-view-content {
      width: 100%;

      .donation-success-target {
        color: $textGrey;
        @include text-agenda-bold;
        text-transform: uppercase;
        line-height: 18px;
        font-size: 15px;
        display: flex;
        align-content: center;

        .target-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    .donation-details {
      width: 100%;

      .donation-line-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include text-agenda-regular;
        font-size: 15px;
        line-height: 18px;
        color: $textGrey;
        margin: 2rem 0;
        text-transform: uppercase;

        .ellipses-container {
          flex-grow: 2;
          border-bottom: 2px dotted $textGrey;
          margin: 0 2px 5px 2px;
        }
      }
    }

    .payment-method-tag {
      width: 100%;
      padding-bottom: 1rem;
      @include text-regular;
      font-size: 11px;
      line-height: 20px;
      color: $textGrey;

      .remaining-balance {
        font-weight: 700;
      }

      .payment-card-image {
        margin: 0 0.25rem -0.25rem 0.25rem;
      }
    }

    .match-tag-container {
      border-top: 1px solid $lightGrey2;
      border-bottom: 1px solid $lightGrey2;
      width: 100%;
      margin-top: 1.75rem;

      .match-item {
        display: flex;
        margin: 1rem 0;

        .avatar {
          height: 45px;
          width: 45px;
          margin-left: 0.5rem;
        }

        .match-item-details {
          display: flex;
          flex-direction: column;
          margin-left: 0.5rem;

          .match-amount {
            @include text-agenda-black;
            font-size: 1rem;
            color: $baseColorPale;
            line-height: 1rem;
            margin-bottom: 2px;
          }

          .match-description {
            @include text-agenda-regular;
            font-size: 10px;
            color: $textGrey;
            line-height: 16px;
          }
        }
      }
    }

    .donation-success-message {
      @include text-agenda-regular;
      width: 100%;
      margin: 2rem 0;
      font-size: 15px;
      line-height: 150%;
      color: $textGrey;
      text-align: left;
    }

    .donation-success-button-container {
      width: 100%;
      text-align: center;

      .cauze-button {
        max-width: 220px;
        width: 100%;
        margin: 1rem;

        &.donate {
          @include text-agenda-black;
          background-color: black;
          border: none;
        }
      }
    }
  }
}
