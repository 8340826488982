.checkout-summary {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    @include text-agenda-regular;

    hr {
      margin: 0px;
      background-color: $grey8;
    }

    .line-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;

      .line-item-name {
        @include text-agenda-regular;
        text-transform: uppercase;
        font-size: 14px;
        color: black;
        display: flex;
        align-items: center;

        span {
          padding-top: 4px;
        }

        .processing-fee {
          color: $grey7;
        }

        svg {
          cursor: pointer;
        }
      }

      .line-item-amount {
        @include text-agenda-bold;
        color: black;
        font-size: 18px;
        text-transform: uppercase;
        padding-top: 4px;
      }
    }
}