.event-list {
  @include mobile {
    padding-left: 18px;
    padding-right: 18px;
  }
}
.bleeding-list {
  @include mobile {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.event-list,
.bleeding-list {
  flex: 1;
  margin-bottom: 1rem;

  .swiper-slide {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .scroll-horizontal {
    padding-bottom: 0.25rem;

    @include tablet {
      padding-left: 1rem;
    }
  }

  .flex-wrap {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    color: black;
  }

  @media (min-width: 769px) {
    &.show-arrows:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: block;
      }
    }
  }
  .event-item {
    cursor: pointer;

    .event-item-header {
      position: relative;

      .pin-button {
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: 1;
      }
    }

    .event-item-overlay {
      height: 94px;
      background-color: rgba(0, 0, 0, 0.35);
      position: absolute;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      width: 100%;
    }

    .event-item-image {
      height: 94px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }

    .event-item-body {
      padding-top: 6px;
      padding-left: 17px;
      padding-bottom: 17px;
      padding-right: 17px;
      border-left: 1px solid rgba(218, 218, 218, 0.5);
      border-right: 1px solid rgba(218, 218, 218, 0.5);
      border-bottom: 1px solid rgba(218, 218, 218, 0.5);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .event-item-name {
        @include text-agenda-bold;
        color: $textGrey;
        line-height: 30px;
        height: 30px;
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .event-item-host {
        @include text-agenda-regular;
        color: $textGrey;
        display: flex;
        font-size: 15px;
        margin-bottom: 7px;
        overflow: hidden;
        width: 100%;

        .avatar {
          height: 30px;
          width: 30px !important;
          margin-right: 7px;
        }

        .event-item-host-text {
          margin-top: auto;
          margin-bottom: auto;
          display: flex;
          overflow: hidden;
        }

        .event-item-host-name {
          @include text-agenda-bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 0.25rem;
        }
      }

      .event-item-givers {
        display: flex;
        height: 30px;

        .event-item-giver-count {
          font-size: 13px;
          line-height: 30px;
          height: 30px;
          color: $textGrey;
          white-space: nowrap;
        }

        .event-item-avatar-group {
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;
          padding-left: 0.5rem;
          white-space: nowrap;
          overflow: hidden;

          .avatar {
            margin-left: -4px;
          }
        }

        .event-item-ellipsis {
          margin-bottom: 6px;
          margin-left: 2px;
          color: $textGrey;

          @media (max-width: 330px) {
            display: none;
          }
        }
      }
    }
  }
}

.discover-event-list {
  flex: 1;

  .swiper-slide {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  @include mobile {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .scroll-horizontal {
    padding-bottom: 0.25rem;

    @include tablet {
      padding-left: 1rem;
    }
  }

  .flex-wrap {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }

  .event-item {
    cursor: pointer;
    overflow: hidden;

    .event-item-header {
      position: relative;

      .pin-button {
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: 1;
      }
    }

    .event-item-overlay {
      height: 94px;
      background-color: rgba(0, 0, 0, 0.35);
      position: absolute;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      width: 100%;
    }

    .event-item-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .event-item-body {
      padding-top: 6px;
      padding-left: 17px;
      padding-bottom: 17px;
      padding-right: 17px;
      border-left: 1px solid rgba(218, 218, 218, 0.5);
      border-right: 1px solid rgba(218, 218, 218, 0.5);
      border-bottom: 1px solid rgba(218, 218, 218, 0.5);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;

      .event-item-name {
        @include text-agenda-bold;
        color: $textGrey;
        line-height: 30px;
        height: 30px;
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .event-item-host {
        @include text-agenda-regular;
        color: $textGrey;
        display: flex;
        font-size: 15px;
        margin-bottom: 7px;
        overflow: hidden;
        width: 100%;

        .avatar {
          height: 30px;
          width: 30px !important;
          margin-right: 7px;
        }

        .event-item-host-text {
          margin-top: auto;
          margin-bottom: auto;
          display: flex;
          overflow: hidden;
        }

        .event-item-host-name {
          @include text-agenda-bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 0.25rem;
        }
      }

      .event-item-givers {
        display: flex;
        height: 30px;

        .event-item-giver-count {
          font-size: 13px;
          line-height: 30px;
          height: 30px;
          color: $textGrey;
          white-space: nowrap;
        }

        .event-item-avatar-group {
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;
          padding-left: 0.5rem;
          white-space: nowrap;
          overflow: hidden;

          .avatar {
            margin-left: -4px;
          }
        }

        .event-item-ellipsis {
          margin-bottom: 6px;
          margin-left: 2px;
          color: $textGrey;

          @media (max-width: 330px) {
            display: none;
          }
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    color: black;
  }

  @media (min-width: 769px) {
    &.show-arrows:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: block;
      }
    }
  }
}
@media (min-width: 768px) {
  .swipe-button-prev,
  .swipe-button-next {
    // display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 1.5rem;
    color: #ce410d;
    cursor: pointer;
  }

  .swiper:hover .swipe-button-prev,
  .swiper:hover .swipe-button-next {
    display: block;
  }

  .swipe-button-prev {
    left: 10px;
  }

  .swipe-button-next {
    right: 10px;
  }

  .swipe-button-prev svg,
  .swipe-button-next svg {
    transition: color 0.3s ease;
  }
}
