.app-notification {
  z-index: 5000;
  position: fixed;
  border-radius: 0;
  top: auto;
  right: 0;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  padding-right: 3rem;

  .delete {
    right: 0;
  }

  &.is-success {
    .delete {
      &::before,
      &::after {
        background-color: $white;
      }
    }
  }
}
