.auth-view-container {
  padding-top: 1rem;

  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $lightGrey;

  .section {
    @include mobile {
      padding: unset;
    }
  }
}

.auth-view {
  display: flex;
  flex-direction: column;
  flex: 1;

  .modal-card {
    width: unset;
  }

  .section {
    @include mobile {
      padding: unset;
    }
  }

  .notification {
    background-color: $red;
  }

  .company-invite-banner {
    background-color: $complement;
    padding: 1rem;
    margin-bottom: 2rem;

    .invite-text {
      margin-left: 1rem;

      .company-name {
        color: $white;
        text-transform: uppercase;
        line-height: 2rem;
      }

      .subtitle {
        color: $white;
        font-size: 1rem;
      }
    }
  }

  .form-container {
    align-items: center;
    justify-content: center;
    background-color: $lightGrey;
    color: $white;


    .login-tabs {
      margin-bottom: 3rem;

      .tab {
        width: 14rem;
        @include text-title;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $white;
        cursor: pointer;
        flex: 1;
        text-transform: uppercase;

        padding: 0.5rem 0.75rem;
        font-size: 1.3rem;
        background-color: $grey4;
      }

      .selected {
        background-color: $brandFocusLight;
      }
    }

    .background-white {
      background-color: $white;
    }

    .auth-button-confirm {
      margin-top: 1rem;
      margin-bottom: 3rem;
      width: 16rem;

      @include mobile {
        width: 10rem;
      }
    }

    form {
      fieldset {
        border: unset;
        align-items: center;
        flex-direction: column;
        display: flex;

        @include tablet {
          width: 28rem;
        }

        input {
          border: 1px solid $grey4;
          border-radius: 3px;
          padding: 1rem;
          color: $black;
          margin-bottom: 1.5rem;
          font-size: 0.75rem;
          width: 18rem;

          @include mobile {
            width: 12rem;
          }
        }

        .zip-container {
          width: 18rem;

          @include mobile {
            width: 12rem;
          }

          .zip {
            max-width: 9rem;

            @include mobile {
              max-width: 6rem;
            }
          }
        }
      }
    }
  }

  .forgot-password {
    @include text-bold;
    font-size: 0.8rem;
    cursor: pointer;
    color: $baseColor;
    padding: 7px;
    margin-top: 5px;
  }
}


.auth-modal {
  width: 28rem;
}