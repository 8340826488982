.feed-comment-item {
  display: flex;
  padding: 15px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $grey3;
  }
  &.primary {
    border-color: $grey3;
  }

  .avatar-link {
    display: flex;
    margin-right: 10px;
  }

  .avatar-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .comment-content {
    flex: 1;
  }

  .comment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .name-link {
    font-size: 15px;
    font-weight: bold;
    color: $black;
    text-decoration: none;
  }

  .comment-body {
    // margin-top: 5px;
    color: $black70;
    font-size: 15px;
  }

  .link-preview {
    margin-top: 5px;
  }

  .comment-image-button {
    border: none;
    background: none;
    padding: 0;
    margin-top: 10px;
    cursor: pointer;
  }

  .comment-image {
    width: 95%;
    height: 200px;
    border-radius: 15px;
    background-color: var(--app-color-grey50);
  }

  .image-modal {
    max-width: 90%;
    max-height: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  .image-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
}
