$spinnerGreen: $mediumGrey;
$spinnerBlue: $mediumGrey;
$spinnerRed: $mediumGrey;
$spinnerYellow: $mediumGrey;
$spinnerWhite: $veryLightGrey;

// scaling... any units
$width: 100px;

.push-footer {
  min-height: 40vmax;
}

.cauze-spinner-container {
  position: relative;
  margin-bottom: 5rem;
}

.cauze-spinner {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .appSpinner {
    transform: translate(-50%, -50%);
  }

  .cauze-loader {
    position: relative;
    margin: 0 auto;
    width: $width;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  .smile {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.25;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $spinnerRed;
  }
  40% {
    stroke: $spinnerBlue;
  }
  66% {
    stroke: $spinnerGreen;
  }
  80%,
  90% {
    stroke: $spinnerYellow;
  }
}
