.back {
  width: 1rem;
  height: 2rem;
  padding: 0;
  overflow: hidden;
  margin-right: 0.75rem;
  border: 0;
  background: transparent;

  img {
    max-width: 2rem;
    margin: -0.75rem;
    padding: 0;
  }
}
