.email-unsubscribe-view-container {
	padding-top: 1rem;

	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: $lightGrey;

	.section {
		@include mobile {
			padding: unset;
		}
	}
}

.unsubscribe-action-container {
	flex-direction: column;
	flex-grow: 1;
}

.unsubscribe-confirmation-text-container {
	text-align: center;
	max-width: 450px;
	margin: 50px auto;
}

.unsubscribe-confirmation-title-text {
	@include text-agenda-bold;
	font-size: 40px;
	text-align: center;
	margin: 50px auto 10px auto;
}

.unsubscribe-confirmation-text {
	@include text-agenda-regular;
	font-size: 17px;
	text-align: center;
	margin: 10px auto 10px auto;
}

.unsubscribe-confirmation-button {
	@include text-agenda-bold;
	font-size: 20px;
	text-transform: uppercase;
	background-color: black;
	color: $white;
	text-align: center;
	border-radius: 10px;
	width: 270px;
	margin: 20px auto 0 auto;
	padding: 10px 0;

	&:active {
		background-color: $grey50;
	}
}
