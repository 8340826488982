.table-actions {
  font-size: 16px;
  display: flex;

  flex-direction: row;
  padding-bottom: 14px;
  height: 46px;

  select {
    width: 137px;
    margin-right: 20px;

    padding: 3px 10px; /* Apply padding to the text area */
    background-color: white;
    appearance: none; /* Remove default dropdown icon */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
    background-image: url("../../assets/images/icons/chevron/down-chevron-3.svg"); /* Optional: Custom dropdown icon */
    background-position: right 10px center; /* Position icon on the right */
    background-repeat: no-repeat; /* Prevent icon from repeating */
    background-size: 16px 16px; /* Size of the icon */
    border: 1px solid #000; /* Optional: Add border to the select */
    border-radius: 4px; /* Optional: Rounded corners */
  }

  button {
    margin-right: 20px;
  }

  .right-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    gap: 12px;
    .search-input {
      flex: 1;
      display: flex;
      align-items: center;

      @include from($tablet) {
        min-width: 25rem;
      }

      input {
        flex: 1;
        padding-left: 35px;
        border: 1px solid #000;

        &:active,
        &:focus {
          border: 1px solid #ce410d;
        }
      }

      .input-container {
        display: flex;
        width: 100%;
        position: relative;

        .action-button {
          position: absolute;
          right: 12px;
          top: 9px;
          height: 20px;
        }
        .action-button-search {
          position: absolute;
          left: 9px;
          top: 6px;
          height: 20px;
          z-index: 99;
        }
      }
    }
    svg {
      cursor: pointer;
    }
  }
}

.server-table {
  background-color: #fff;
  border-collapse: collapse;
  overflow: auto;
  width: 100%;
  font-size: 15px;
  @include text-agenda-regular;

  tbody {
    background: #fff;
  }

  .row-cannot-refund {
    opacity: 0.5;
  }

  th.st-th,
  td.st-td,
  .action-col {
    padding: 5px;
    border-bottom: 1px solid #e8e8e8;
    vertical-align: middle;
  }

  th.st-th {
    font-size: 12px;
    @include text-agenda-regular;
    letter-spacing: 0.05em;
    white-space: unset;
    padding: 16px 5px 16px 16px;
    text-align: center;
    white-space: nowrap;
    height: 64px;
    border-bottom: 2px solid #dfdfdf;
    font-weight: 400;

    &.sort-desc {
      box-shadow: inset 0 -4px 0 0 #ce410d;
    }
    &.sort-asc {
      box-shadow: inset 0 4px 0 0 #ce410d;
    }
  }

  th.st-th.col-left {
    text-align: left;
  }

  td.st-td {
    padding-top: 10px;
    padding-bottom: 10px;

    .avatar {
      margin: 0.5rem 0.5rem 0.5rem 0;
    }
    .col-user-container .col-user-details .col-user-email {
      font-size: 0.8rem;
    }
  }

  .action-col {
    padding: 18px;
    max-width: 48px;
    width: 48px;

    input[type="checkbox"] {
      background: #ce410d;
      border-color: "red";
    }
  }

  th.st-th.action-col {
    border-bottom: 2px solid #dfdfdf;
  }

  tfoot tr > td {
    width: 100%;
  }
  .col-user-name {
    @include text-agenda-bold;
  }
  .col-user-email {
    font-size: 12px;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    padding: 10px;
    border-top: 2px solid #dfdfdf;

    > div {
      display: flex;
      flex-direction: row;
    }

    input {
      width: 60px;
      margin-left: 10px;
      margin-right: 10px;
    }

    button {
      background: #ce410d;
      border: 0;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 1em;
      height: 100%;
      outline: none;
      padding: 6px;
      transition: all 0.1s ease;
      width: 25%;

      &:disabled {
        background: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
