.sponsored-cards-wrapper {
    @include max-width-default;
    
    width: 100%;

    .sponsored-cards-card-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        @include mobile {
            flex-direction: column;
        }
    }
}