.add-payment-modal {
  align-items: center;
  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }

  label {
    @include text-agenda-regular;
    color: $textGrey;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.07px;
  }

  input,
  select {
    @include text-agenda-regular;
    border-radius: 5px;
    border: 1px solid $grey3;
    background: $white;
    padding: 12px 0 8px 12px;
    color: $textGrey;
  }

  .field {
    margin-bottom: 20px;
  }

  .modal-card {
    justify-content: center;
    @include mobile {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .modal-card-head {
      background-color: black;
      height: 137px;

      @include mobile {
        padding-right: 1rem;
        padding-left: 1rem;
      }

      .modal-card-title {
        @include text-agenda-black;
        line-break: auto;
        white-space: normal;
        color: $white;
        font-size: 25px;
        line-height: 30px;
        text-transform: uppercase;
        margin-left: 2rem;
        margin-top: 4px;
        flex-shrink: 1;

        @include mobile {
          margin-left: 0;
        }
      }
    }

    .modal-card-body {
      @include text-agenda-regular;
      font-size: 1rem;
      padding-left: 168px;
      padding-right: 168px;
      padding-top: 44px;
      padding-bottom: 60px;

      @include mobile {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      .line-item {
        justify-content: space-between;
        margin-bottom: 1rem;

        &:first-of-type {
          margin-top: 1rem;
        }

        .dotted-line {
          flex: 1;
          overflow: hidden;
          margin: 0rem 0.5rem;
        }
      }
    }

    .modal-card-foot {
      justify-content: center;
      padding: 2rem 3.25rem 2rem 3.25rem;
      background-color: $white;
      border: none;

      .cauze-button-container {
        width: 100%;

        .cauze-button {
          width: 100%;
        }
      }
    }
  }

  .target-name {
    @include text-agenda-bold;
    font-size: 25px;
    line-height: 25px;
  }

  .ach-detail {
    @include text-agenda-regular;
    color: $textGrey;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    height: 72px;
    letter-spacing: 0.055px;
  }

  .plaid-button-container {
    width: 100%;

    .plaid-button {
      width: 100%;
    }
  }
}
