.form-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

input[type='checkbox'] {
  transform: scale(1.5);
}

.email-list-input {
  border: 1px solid $mediumGrey;
  padding: 1rem;
  background-color: white;

  .email {
    color: white;
    background-color: $orange;
    border-radius: $radius-small;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    margin-right: 0.25rem;
  }
}

.email-summary {
  border: 1px solid $mediumGrey;
  border-top: 0;
  padding: 1rem 1rem 0.5rem 1rem;
  margin-top: -0.5rem;
  border-radius: $radius-small;
  background-color: white;
}

.comment-field {
  border: 1px solid $mediumGrey;
  background-color: white;
}

form {
  display: flex;
  flex-direction: column;

  .form-validation-error {
    color: $danger;
    font-size: 0.8rem;
    padding: 1rem 1rem 0.5rem 1rem;
    border-radius: $radius-small;
    justify-content: center;
    text-align: center;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.25rem;

    &.columns {
      display: block;
      flex-direction: unset;

      @include tablet {
        display: flex;
      }
    }
  }


  .input-group {

    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: thin 0 0 0;
    border-color: $lightGrey;
    padding: 1rem;

    @include tablet {
      padding: 2rem;
    }
  }


  fieldset {
    display: flex;
    flex-direction: column;
    border: 0.1rem solid $mediumGrey;
    background-color: white;
    border-radius: $radius-small;
    padding: 0.5rem;

    legend {
      display: none;
    }

    .form-row:last-of-type {
      margin-bottom: 0;
    }
  }

  label {

    @include tablet {
      margin-right: 0.5rem;
    }

    img {
      margin-left: 0.25rem;
      opacity: 0.7;
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  input {
    @include text-regular;
    flex: 1;
    font-size: 1rem;
    color: black;
    border: none;
    border-radius: $radius-small;
    height: 2.5rem;
    padding: 0.25rem;
  }
}
