.cauze-sidebar {
  border: none !important;
  height: calc(100vh - 76px);

  &::-webkit-scrollbar {
    width: 0px;
  }

  &:hover {
    padding-right: 0px;

    /* width */
    &::-webkit-scrollbar {
      width: 1rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $lightGrey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $grey8;
      border: 0.25rem solid transparent;
      border-radius: 10px;
      background-clip: content-box;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $grey75;
      border: 0.25rem solid transparent;
      border-radius: 10px;
      background-clip: content-box;
    }
  }

  @media (max-width: 1024px) {
    height: calc(100vh - 108px);
  }
}
