.sponsor-invite {
  background-color: white;
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: 480px;
  margin: auto;

  @media (min-width: 480px) {
    width: 480px;
    margin: auto;
  }

  .invite-header {
    padding: 1rem;
    background-color: $complement;
    color: $white;

    .avatar {
      margin-right: 1rem;
    }

    h1 {
      font-size: 1.5rem;
      color: $white;
    }
  }

  .sponsor-padding {
    padding: 1rem;
  }

  .profile-preview-image {
    border: 1px solid $lightGrey;
    border-radius: 5rem;
    max-width: 5rem;
    margin-right: 0.5rem;
  }

  form {
    .user-list {
       label {

         input {
           height: unset;
           margin-right: 0.25rem;
           border: 1px solid black;
         }
       }
    }

    .text-input {
      border-bottom: 1px solid black;
      padding-left: 0;
      flex: 1;
      display: flex;
      margin-bottom: 2rem;
      width: 100%;
    }

    .field-title {
      font-size: 0.75rem;
      color: $grey;

      &:first-of-type {
        margin-top: 1rem;
      }
    }

    .logo-text {
      margin-top: 2rem;
    }

    .logo-upload {
      margin-top: 0.5rem;
      align-items: center;
    }

    .company-inputs {
      margin-top: 1rem;
    }

    .balance {
      margin-bottom: 1rem;
    }

    .amount-input {
      @include text-regular;
      font-size: 2rem;
      border: 1px solid black;
      margin: 1rem;
      padding: 1rem;
      align-items: center;

      input {
        border: 0;
        @include text-regular;
        font-size: 2rem;
        width: 10rem;
        height: unset;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .match-detail {
    margin-bottom: 1rem;
    h2 {
      @include text-medium;
      text-align: center;
    }
    p {
      max-width: 20rem;
      text-align: center;
    }
  }

  .fine-print {
    margin-top: 2rem;
  }
}
