.amount-header {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 26rem;
  padding: 0.5rem 0 0 0;
  border-width: 0;
  justify-content: flex-start;
  width: auto;
}

.amount-selector {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.75rem;
  border-width: 0;
  justify-content: flex-start;
  max-width: 26rem;

  .amount-option {
    @include text-bold;
    border: 2px solid white;
    border-radius: 0.25rem;
    min-width: 3rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    display: flex;
    justify-content: space-around;
    background-color: white;
    align-items: center;

    &:focus {
      outline: 0;
    }

    @include from($tablet) {
      width: 4rem;
      height: 2.5rem;
    }
  }

  .amount-custom {
    @include text-bold;
    font-size: 1rem;
    align-items: center;
    border: 2px solid white;
    border-radius: 0.25rem;
    height: 2.5rem;
    flex: 1;
    max-width: 12rem;
    font-size: 1rem;
    background-color: white;
    overflow: hidden;

    &:focus {
      outline: 0;
    }

    @include from($tablet) {
      height: 2.5rem;
    }

    input {
      font-size: 1rem;
      @include text-bold;
      border: 0;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: inherit;
      color: inherit;
      margin: 0;

      &:focus {
        outline: 0;
      }
    }

    input::placeholder {
      color: lightgray;
    }
  }

  .is-active {
    background-color: $orange;
    color: white;
  }
}
