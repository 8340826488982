.feed {
  .download-app-ad {
    cursor: pointer;
    border-radius: 2rem 2rem 0 0;
    margin: 3rem 1rem 0 1rem;
    padding: 0.5rem 2rem;
    min-width: 300px;
    background-color: $support;
    color: $white;
    align-items: center;

    @include tablet {
      margin: 3rem auto 0 auto;
      max-width: 450px;
    }

    .download-text {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }
  }

  .feed-sort {
    font-size: 1.25rem;
    cursor: pointer;
    padding: 1rem;
    border-width: medium 0 0 0;
    border-style: solid;
    border-color: $lightGrey;

    @include tablet {
      padding: 1rem 2rem;
    }
  }

  .see-more {
    @include text-medium;
    padding: 1rem;
    display: flex;
    flex: 1;
    justify-content: center;
    text-transform: uppercase;
    border-width: medium 0 0 0;
    border-style: solid;
    border-color: $lightGrey;
  }
}
