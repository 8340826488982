.search-item{
  border: 1px solid $lightGrey;
  height: 13.25rem;
  padding: 1.25rem;
  margin: 0.75rem 0rem;

  @include mobile {
    height: 100%;
    width: unset;
  }

  @include fullhd {
    margin: 0.75rem 0;
  }

  .search-item-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    @include mobile {
      flex-direction: column;
    }
  }

  &:hover {
    background-color: $veryLightGrey;
  }

  .avatar {
    border: 1px solid $brand;
  

    @include  mobile {
      display: inline-block;
      margin-right: 0.5rem;
      float: left;
    }
  }

  .charity-details {
    @include mobile {
      display: block;
    }
  }

  .charity-name {
    @include text-title;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: $black;

  }

  .location {
    @include text-bold;
    font-size: 0.9rem;
    font-weight: 700;
    color: $textGrey;
  }

  .details-column {
    display: flex;
    flex: 4;
    margin-left: 1rem;

    @include mobile {
      display: unset;
      margin-left: 0;

      .details-header {
        display: inline-block;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
    }
  }

  .description {
    max-width: 650px;
    font-size: 13px;
    margin-top: 9px;
    color: $textGrey;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  
    @include mobile {
      max-width: 100%;
      max-height: 250px;
    }

    a {
      @include text-bold;
      color: $brandFocusLight;

      &:hover {
        color: $anchorBlue;
      }

    }
  }
  .search-item-followers {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 1rem;
    margin-left: 2rem;
    

    @include mobile {
      align-items: flex-start;
      margin: 1rem 0 0 0;
    }

    .follower-info {
      display: flex;
      flex: 2;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    
    @include mobile {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
        }

    &.no-followers {
      flex: 1;
      margin-top: 0;
    }

    .follower-count-wrapper {
      @include text-title;
      font-size: 1.25rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $brand;

      @include mobile {
      display: inline;
      margin: 4px 0.5rem 0 0;
      white-space: nowrap;
      }
    }

    .follower-avatar-wrapper {
      @include text-title;
      font-size: 15px;
      color: $textGrey;
      text-transform: uppercase;
      cursor: pointer;

      .avatar {
        margin-right: -0.3rem;
        max-height: 40px;
        max-width: 40px;
      }

      .non-featured-follower-count {
        margin-left: 0.5rem;
        white-space: nowrap;

        @include mobile {
          margin-top: 4px;
          flex-shrink: 2;
        }
      }

    }
  }
  
  .search-item-button {
    flex: 1;
    margin-top: 2rem;

    @include mobile {
      margin-top: 1rem;
    }

    &.no-followers {
      flex: 3;
      margin-top: 0;

      @include mobile {
        margin-top: 1rem;
      }
    }

  }
}

}
