.wallet-view {
  .payment-methods {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 0;
    padding: 1rem;
    background-color: $white;
    @include shadow-medium;

    @include from($tablet) {
      margin-bottom: 1rem;
    }

    @include from($desktop) {
      margin-right: auto;
      margin-left: auto;
    }

    h1 {
      @include text-title;
      color: $black;
    }
    .remove {
      margin-left: 1rem;
    }

    .ach-detail {
      @include text-regular;
      margin: 2rem 0 2rem;
    }

    form {
      margin: 1rem;
      
      .input {
        border-bottom-width: 1px;
        border-bottom-color: $black;
        border-bottom-style: solid;
        margin-bottom: 1rem;
        padding-left: 0;
      }
    }

  }
}
