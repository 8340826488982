.user-activity-view {
    background-color: $white;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $black;

    .user-activity-filter-group {
        background-color: $lightGrey;
        border-radius: 8px;
        padding: 20px 15px 20px 15px;
        gap: 24px;
        display: grid;
        grid-template-columns: 261px 232px;
        grid-template-rows: 34px 34px;

        @include mobile {
            background-color: white;
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 10px 15px 10px 15px;
            border-radius: 0px;
        }

        .user-activity-filter {
            display: flex;
            justify-content: space-between;
            position: relative;

            @include mobile {
                justify-content: flex-start;
            }

            .user-activity-filter-name {
                @include text-agenda-bold;
                text-transform: uppercase;
                font-size: 12px;
                line-height: 12px;
                margin-top: auto;
                margin-bottom: auto;

                @include mobile {
                    width: 100px;
                }
            }

            .user-activity-filter-inputs {
                display: flex;
                column-gap: 4px;

                @include mobile {
                    height: 40px;
                    flex-grow: 1;
                    width: 100%;
                
                    div {
                        width: 100%;
                    }
                }

                select {
                    @include text-agenda-regular;
                    border-radius: 8px;
                    border: 1px solid $grey8;
                    width: 126px;
                    height: 100%;
                    padding-left: 13px;
                    font-size: 12px;
                    color: $black70;
                    background-color: white;

                    @include mobile {
                        flex-grow: 1;
                        width: 100%;
                    }
                }

                .range-input-container {
                    position: relative;

                    .dollar-sign {
                        position: absolute;
                        top: 4px;
                        left: 13px;
                        color: $black70;
                        pointer-events: none;
                        
                        @include mobile {
                            top: 7px;
                        }
                    }

                    .range-input {
                        @include text-agenda-regular;
                        border-radius: 8px;
                        border: 1px solid $grey8;
                        width: 87px;
                        height: 100%;
                        padding-left: 23px;
                        font-size: 12px;
                        color: $black70;

                        @include mobile {
                            width: 100%;
                        }
                    }
                }

                button {
                    @include text-agenda-regular;
                    border-radius: 8px;
                    border: 1px solid $grey8;
                    padding-right: 9px;
                    background-color: white;
                    text-align: left;
                    padding-left: 13px;
                    color: $grey7;
                    display: flex;
                    font-size: 12px;
                    cursor: pointer;
                    justify-content: space-between;
                    height: 100%;

                    span {
                        margin-top: auto;
                        margin-bottom: auto;
                        line-height: 12px;

                        &.has-value {
                            color: $black70;
                        }
                    }

                    svg {
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    &.small {
                        width: 87px;

                        @include mobile {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .btn-container {
            display: flex;
            justify-content: flex-end;
            column-gap: 10px;
            width: 100%;
            grid-column: 1 / 3;

            @include mobile {
                justify-content: space-between;
            }

            button {
                @include text-agenda-bold;
                font-size: 13px;
                line-height: 15.6px;
                border-radius: 5px;
                width: 130px;
                height: 40px;
                padding-top: 4px;
                cursor: pointer;
                border: none;
                text-transform: uppercase;

                &.clear-filters-btn {
                    color: black;
                }

                &.see-results-btn {
                    @include text-agenda-black;
                    background-color: black;
                    color: white;
                }
            }
        }
    }

    .user-activity-no-activity {
        color: $grey;
        text-align: center;
    }

    @include mobile {
        width: 100vw;
        overflow-x: hidden;
    }

    .user-activity-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        @include mobile {
            width: 100%;
        }

        .user-activity-title {
            @include text-agenda-bold;
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            color: $black;

            @include mobile {
                padding-left: 28px;
            }

            svg {
                margin-right: 10px;
            }
        }

        .user-activity-funds {
            height: 148px;
            border-radius: 5px;
            border: 1px solid $lightGrey;
            padding: 20px;

            @include mobile {
                border: none;
                text-align: center;
                width: 100%;
                padding-top: 26px;
                margin-bottom: 17px;
            }

            .user-activity-current-balance {
                height: 53px;
                width: 100%;
                margin-bottom: 15px;

                @include mobile {
                    margin-bottom: 23px;
                }

                .user-activity-current-balance-amount {
                    @include text-agenda-bold;
                    font-size: 31px;
                    line-height: 37.76px;
                    color: $black;

                    @include mobile {
                        @include text-agenda-regular;
                        font-size: 35px;
                        line-height: 31px;
                    }
                }

                .user-activity-current-balance-text {
                    @include text-agenda-regular;
                    font-size: 13px;
                    line-height: 15.47px;
                    color: $black70;

                    @include mobile {
                        color: $textGrey;
                    }
                }
            }

            .user-activity-fund-buttons {
                display: flex;
                gap: 10px;

                @include mobile {
                    gap: 24px;
                    justify-content: center;
                }

                button {
                    @include text-agenda-bold;
                    line-height: 16px;
                    width: 140px;
                    border-radius: 5px;
                    padding-top: 13.5px;
                    padding-bottom: 13.5px;
                    cursor: pointer;

                    @include mobile {
                        width: 109px;
                        padding-top: 15.5px;
                        padding-bottom: 15.5px;
                    }
                }

                button.black {
                    background-color: black;
                    color: white;
                    border: none;
                }

                button.outlined {
                    background-color: white;
                    color: $black;
                    border: 1px solid black;
                }

                button:disabled {
                    background-color: $lightGrey;
                    color: $textGrey;
                    border: none;
                    cursor: auto;
                }
            }
        }

        .user-activity-tabs {
            display: block;
            border-bottom: 1px solid $lightGrey;

            @include mobile {
                height: 28px;
                border-bottom: 1px solid $lightGrey5;
                text-align: center;
                display: flex;
                justify-content: space-evenly;
            }

            button {
                @include text-agenda-regular;
                min-width: 132px;
                font-size: 17px;
                color: $black70;
                border: none;
                padding-top: 18px;
                padding-bottom: 18px;
                cursor: pointer;
                background-color: white;
                border-bottom: 1px solid $lightGrey;

                @include mobile {
                    @include text-agenda-black;
                    text-align: left;
                    padding-top: 4px;
                    padding-bottom: 5px;
                    color: $lightBorderGrey;
                    text-transform: uppercase;
                    font-weight: 900;
                    width: auto;
                }
            }

            button.active {
                @include text-agenda-bold;
                color: $black;
                background-color: $lightGrey;

                @include mobile {
                    background-color: white;
                    color: $brandNew;
                    border-bottom: 4px solid $brandNew;
                }
            }
        }

        .user-activity-payment-link {
            row-gap: 8px !important;
        }

        .user-activity-filters {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
        }

        .user-activity-search {
            height: 35px;
            width: 100%;
            display: flex;
            align-items: center;

            @include mobile {
                justify-content: center;
            }

            .user-activity-search-bar {
                padding: 10px 0px 10px 10px;
                margin-right: 20px;
                border: 1px solid $grey8;
                border-radius: 8px;
                width: 251px;
                height: 100%;
                position: relative;

                @include mobile {
                    width: 100%;
                    margin-left: 15px;
                    margin-right: 15px;
                }

                svg {
                    position: absolute;
                    top: 8px;
                    cursor: pointer;
                }

                input {
                    @include text-agenda-regular;
                    font-size: 13px;
                    position: absolute;
                    left: 33px;
                    border: none;
                    line-height: 33px;
                    width: 212px;
                    padding-top: 3px;
                    top: 0;
                    bottom: 0;
                    background-color: white;
                    height: 33px;

                    &:focus {
                        outline: none;
                        border: none;
                    }

                    &::placeholder {
                        line-height: 15.5px;
                    }
                }

                .close-button {
                    border: none;
                    background-color: transparent;
                    position: absolute;
                    right: 8px;

                    svg {
                        top: 0px;
                    }
                }
            }

            .user-activity-filter-btn {
                height: 34px;
                width: 34px;
                display: flex;
                align-items: center;
                cursor: pointer;
                border: none;
                background-color: white;
                border-radius: 8px;

                &.active {
                    background-color: $lightGrey;
                }

                svg {
                    margin: auto;
                }
            }

            .user-activity-download-btn {
                @include text-agenda-regular;
                margin-left: auto;
                border: none;
                cursor: pointer;
                color: $black;
                display: flex;
                column-gap: 5px;
                background-color: transparent;

                span {
                    margin-top: auto;
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }

        .user-activity-search-mobile {
            display: flex;
            justify-content: center;
            gap: 20px;
            height: 17px;
            margin-bottom: 14px;

            &.hidden {
                visibility: hidden;
            }

            .search-mobile {
                @include text-agenda-regular;
                border: none;
                cursor: pointer;
                color: $black;
                display: flex;
                column-gap: 5px;
                background-color: transparent;

                span {
                    font-size: 13px;
                    line-height: 24px;
                }
            }

            .download-mobile {
                @include text-agenda-regular;
                border: none;
                cursor: pointer;
                color: $black;
                display: flex;
                column-gap: 5px;
                background-color: transparent;

                span {
                    font-size: 13px;
                    line-height: 24px;
                }
            }

            .sort-mobile {
                @include text-agenda-regular;
                border: none;
                cursor: pointer;
                color: $black;
                display: flex;
                column-gap: 5px;
                background-color: transparent;

                span {
                    font-size: 13px;
                    line-height: 24px;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                svg {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .sort-by {
                    color: $lightBorderGrey;
                }
            }
        }

        .user-activity-table {
            color: $black;
            margin-top: -4px;

            a {
                color: $black;
            }

            .user-activity-table-columns {
                display: grid;
                grid-template-columns: 1fr 9fr 3fr 3fr;
                gap: 20px;
                border-bottom: 1px solid $lightGrey;
                padding-top: 10px;
                padding-bottom: 10px;

                @include mobile {
                    padding-left: 20px;
                    padding-right: 20px;
                    grid-template-columns: 2fr 1fr;
                }

                .col-text {
                    @include text-agenda-regular;
                    font-size: 12px;
                    line-height: 14.28px;

                    &.right {
                        text-align: right;
                    }

                    @include mobile {
                        color: $textGrey;
                    }
                }
            }

            .user-activity-table-row {
                display: grid;
                grid-template-columns: 1fr 9fr 3fr 3fr;
                gap: 20px;
                border-bottom: 1px solid $lightGrey;
                padding-top: 20px;
                padding-bottom: 20px;

                @include mobile {
                    padding-left: 20px;
                    padding-right: 20px;
                    grid-template-columns: 2fr 1fr;
                }

                .date {
                    width: 32px;
    
                    .month {
                        @include text-agenda-regular;
                        font-size: 12px;
                        line-height: 12px;
                        text-align: center;
                        text-transform: uppercase;
                    }
    
                    .day {
                        @include text-agenda-bold;
                        font-size: 24px;
                        line-height: 24px;
                        text-align: center;
                    }
    
                    .year {
                        @include text-agenda-regular;
                        font-size: 12px;
                        line-height: 15px;
                        text-align: center;
                    }
                }

                .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .desc-title {
                        @include text-agenda-bold;
                        font-size: 15px;
                        line-height: 17px;
                        color: $black;
                        word-break: break-word;
                    }

                    .desc-type {
                        @include text-agenda-regular;
                        font-size: 13px;
                        line-height: 15.47px;
                        color: $black70;
                    }
                }

                .amount {
                    @include text-agenda-bold;
                    font-size: 15px;
                    line-height: 19px;
                    text-align: right;
                    color: $black;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include mobile {
                        color: black;
                    }

                    .positive {
                        color: $green2 !important;
                    }
                }

                .balance {
                    @include text-agenda-regular;
                    font-size: 15px;
                    line-height: 19px;
                    text-align: right;
                    color: $black;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include mobile {
                        color: black;
                        font-size: 12px;
                        line-height: 14.4px;
                    }
                }
            }

            .load-more-container {
                display: flex;
                padding: 20px;
                align-items: center;
                justify-content: center;
            }
        }

        .user-activity-details {
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            padding-top: 10px;

            .user-activity-section {
                width: 100%;
                border-radius: 5px;
                border: 1px solid $lightGrey;
                padding: 20px;
                gap: 10px;
                display: flex;
                flex-direction: column;
    
                .user-activity-section-title {
                    @include text-agenda-bold;
                    font-size: 17px;
                    color: black;
                    line-height: 20px;
                    height: 20px;
                }

                @include mobile {
                    border: none;
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
            }

            .user-activity-payment-details {
                .user-activity-payment-methods {
                    .user-activity-payment-method {
                        @include text-agenda-bold;
                        display: flex;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        border-bottom: 1px solid $lightGrey;
    
                        .uapm-name {
                            font-size: 17px;
                            line-height: 17px;
                            height: 17px;
                            color: $lightPurple;
                            flex: 1;
                            text-transform: capitalize;
                            margin-top: auto;
                            margin-bottom: auto;
                            padding-top: 2px;
                        }
    
                        .uapm-brand-container {
                            display: flex;
                            justify-content: space-between;
                            flex: 1;
                            column-gap: 20px;

                            @include mobile {
                                justify-content: flex-end;
                                column-gap: 6px;
                            }
                        }
    
                        .uapm-brand {
                            margin-top: auto;
                            margin-bottom: auto;
                            height: 16px;
                            width: 25px;
                        }
    
                        .uapm-last4 {
                            font-size: 17px;
                            line-height: 17px;
                            height: 17px;
                            padding-top: 2px;
                            color: $lightPurple;
                            margin-top: auto;
                            margin-bottom: auto;
                            width: 42px;
                            text-align: left;
                        }
    
                        .uapm-default-btn {
                            @include text-agenda-regular;
                            font-size: 13px;
                            line-height: 15.47px;
                            width: 100px;
                            height: 34px;
                            border-radius: 6px;
                            background-color: white;
                            color: black;
                            border: 1px solid black;
                            padding-top: 4px;
                            cursor: pointer;

                            &.is-default {
                                background-color: black;
                                color: white;
                            }

                            @include mobile {
                                display: none;
                            }
                        }
    
                        .uapm-x-btn {
                            background-color: transparent;
                            border: none;
                            margin: 0px;
                            margin-top: auto;
                            margin-bottom: auto;

                            @include mobile {
                                display: none;
                            }
                        }
                    }

                    .user-activity-payment-button-container {
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;
                        padding-top: 40px;

                        @include mobile {
                            padding-top: 0px;
                            row-gap: 0px;
                        }

                        button {
                            @include text-agenda-bold;
                            width: 100%;
                            height: 40px;
                            font-size: 13px;
                            line-height: 15.83px;
                            text-align: center;
                            color: $black;
                            border: 1px solid black;
                            border-radius: 5px;
                            background-color: white;
                            cursor: pointer;

                            @include mobile {
                                @include text-agenda-regular;
                                font-size: 17px;
                                line-height: 20.4px;
                                color: $grey7;
                                border: none;
                                border-bottom: 1px solid $lightGrey;
                            }
                        }

                        .user-activity-add-card {
                            button {
                                margin-bottom: 10px;

                                @include mobile {
                                    margin-bottom: 0px;
                                }

                                &.user-activity-add-card-btn {
                                    background-color: black;
                                    color: white;
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }

            .user-activity-matches-list {
                .user-activity-match {
                    display: flex;
                    height: 60px;
                    column-gap: 10px;

                    @include mobile {
                        border-top: 1px solid $lightGrey;
                        border-bottom: 1px solid $lightGrey;
                    }

                    .avatar {
                        height: 40px !important;
                        width: 40px !important;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    &.has-border {
                        border-bottom: 1px solid $lightGrey;
                    }

                    .user-activity-match-container {
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .user-activity-match-remaining {
                        @include text-agenda-regular;
                        font-size: 17px;
                        line-height: 20.4px;
                        height: 20px;

                        .user-activity-match-current {
                            @include text-agenda-bold;
                        }
                    }

                    .user-activity-match-name {
                        @include text-agenda-regular;
                        font-size: 13px;
                        line-height: 15.6px;
                        color: $grey7;
                    }
                }
            }

            .user-activity-payment-link-text {
                @include text-agenda-regular;
                font-size: 17px;
                line-height: 17px;
                color: $black70;

                @include mobile {
                    font-size: 13px;
                    line-height: 14px;
                    color: $textGrey;
                }
            }
            
            .user-activity-payment-link-container {
                display: flex;
                column-gap: 30px;
                width: 100%;

                @include mobile {
                    display: grid;
                    grid-template-columns: auto 37px 37px;
                    column-gap: 10px;
                }

                .user-activity-payment-link-url {
                    @include text-agenda-regular;
                    font-size: 17px;
                    line-height: 17px;
                    height: 17px;
                    margin-top: auto;
                    margin-bottom: auto;
                    color: black;
                    border: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .user-activity-payment-link-btn {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .user-activity-payment-link-btn {
                    @include text-agenda-regular;
                    background-color: transparent;
                    border: none;
                    display: flex;
                    column-gap: 5px;
                    font-size: 13px;
                    line-height: 17px;
                    color: $black70;
                    cursor: pointer;

                    @include mobile {
                        flex-direction: column;
                        text-align: center;
                    }

                    span {
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    svg {
                        margin-top: auto;
                        margin-bottom: auto;

                        @include mobile {
                            margin-left: auto;
                            margin-right: auto;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
    }
}