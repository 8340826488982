.user-table {
  border-radius: 4px;
  .rt-table {
    @media (min-width: 1024px) {
      min-width: 810px;
    }
  }
  .rt-tbody {
    .col-user-container {
      margin: 0.5rem 0.5rem 0.5rem 0;

      @include from($tablet) {
        margin: 0.5rem 0.5rem 0.5rem 0.2rem;
      }

      .avatar {
        margin: 0.5rem 0.5rem 0.5rem 0;

        @include from($tablet) {
          margin-right: 0.5rem;
        }
      }

      .col-user-details {
        justify-content: center;

        .col-user-name {
          font-size: 15px;

          font-family: Agenda-Bold, sans-serif;
        }

        .col-user-email {
          font-size: 12px;
        }
      }

      .is-expanded {
        margin-top: 0.5rem;

        @include from($tablet) {
          margin-left: 2.5rem;
        }

        .user-expanded-status {
          color: $darkGrey;
          font-size: 0.9rem;
          @include text-medium;
        }

        .user-expanded-text {
          color: $darkGrey;
          font-size: 0.9rem;
        }

        .user-expanded-currency {
          color: $darkGrey;
          font-size: 0.9rem;
          @include text-bold;
        }
      }
    }

    .col-date-highlight {
      color: $baseColorTextHighlight;
    }

    .col-status {
      text-overflow: ellipsis;
      overflow: auto;
    }
    .gift-unredeemed {
      color: $orange;
    }
    .gift-unredeemed-text {
      font-size: 0.75rem;
    }
  }
}
