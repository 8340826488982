@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .cz-btn {
    @apply px-2.5 pt-[14px] pb-[11px] rounded-lg text-white font-agenda-bold text-[17px] uppercase leading-none cursor-pointer filter hover:brightness-110 active:brightness-125 disabled:bg-lightgray-b2b disabled:hover:brightness-100 disabled:cursor-default border-solid;
  }

  .cz-btn-brand {
    @apply cz-btn bg-cauzeorange border-none;
  }

  .cz-btn-brand-dark {
    @apply cz-btn bg-cauze-dark border-none;
  }

  .cz-btn-black {
    @apply cz-btn bg-black border-none;
  }

  .cz-btn-outline {
    @apply cz-btn bg-transparent border border-white;
  }

  .cz-btn-outline.black {
    @apply text-black border-black disabled:border-white disabled:text-lightgray-f1f;
  }
  .cz-btn-outline.brand {
    @apply text-cauzeorange border-cauzeorange;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-padding-top {
  padding-top: 76px;
}

.body-disable-clicks {
  pointer-events: none;
  cursor: not-allowed;
}

/* Assuming your search container has a class 'search-container' */
.body-disable-clicks .search-container {
  pointer-events: auto;
  cursor: auto; /* or cursor: text; or cursor: pointer; depending on what you prefer */
}
