.image-expand-modal {
  align-items: center;

  .modal-background {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .modal-card {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .full-image {
        position: relative;
        .modal-header {
          position: absolute;
          top: -35px;
          right: -35px;
          cursor: pointer;
          color: white;
          @include mobile {
            top: -35px;
            right: 92%;
            left: 0px;
          }
        }
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
}
