.cauze-amount-selector-container {
  max-width: 270px;
  width: 100%;
  margin: 0 auto;

  .amount-selector-buttons-row {
    display: flex;
    justify-content: stretch;
    margin: 0.75rem 0;

    .cauze-button-container {
      padding-right: 0;
    }

    .amount-option {
      width: 100%;

      &.is-active {
        background-color: $black;
        color: $white;
        border: 1px solid $white;
      }
    }
  }

  .cauze-button-container {
    width: 100%;
    margin: 0 0.5rem;
    padding-right: 1rem;

    .cauze-button {
      font-size: 1.2rem;
      padding: 1rem 0;
    }

    .amount-custom {
      width: 100%;
      padding-top: 0.8rem;
      padding-bottom: 0.6rem;

      span {
        width: 100%;
        display: flex;
      }

      &:hover {
        background-color: unset;
        border: 0.5px solid $black;

        &::placeholder {
          color: $black;
        }
      }

      .dollar-sign {
        margin: auto 0.5rem auto 0.5rem;
      }

      &.is-active {
        background-color: $white !important;
        color: $black;
        border: 0.5px solid $black !important;
        padding-top: 0.6rem;
        padding-bottom: 0.5rem;

        input {
          justify-self: center;
          max-width: 100%;
          width: 100%;
          box-shadow: none;
          outline: 0;
          padding: 0;
          height: 1.6rem;
          line-height: 1rem;
          color: $white;
          text-indent: 0.2rem;
          border-radius: 3px;
          text-align: center;

          &:focus {
            background-color: $white;
            border: 1px solid $black;
            color: $trueBlack;
          }

          &::placeholder {
            color: $white;
          }
        }
      }

      &.is-blurred {
        background-color: $black;
        color: $white;
        border: 0.5px solid $white;

        input {
          background-color: $black;
          color: $white;
          font-size: 1rem;

          &::placeholder {
            color: $white;
          }
        }
      }

      input {
        border: none;
        @include text-agenda-black;
        color: $trueBlack;
        font-size: 1rem;
        text-transform: uppercase;
        box-shadow: none;
        outline: 0;
        width: 100%;

        &::placeholder {
          color: $grey2;
          text-align: center;
        }
      }
    }
  }
}
