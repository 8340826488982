.plaid-button-container {
  line-height: 13px;

  .plaid-button {
    @include text-agenda-regular;
    font-size: 2rem;
    font-weight: 400;
    font-style: normal;
    font-size: 0.8rem;
    box-sizing: inherit;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid $grey2;
    background-color: transparent;
    color: $textGrey;
    line-height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .pay-text {
      margin-right: 0.5rem;
      line-height: 100%;
      padding-top: 4px;
    }

    .plaid-image {
      height: 1.2rem;
    }
  }
}
