@keyframes expanding {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 50rem;
    opacity: 1;
  }
}

.is-expanded {
  animation-name: expanding;
  animation-duration: 0.5s;
  ease: easeInOutCubic;
}

.rt-container {
  min-height: 70vmax;

  input[type="checkbox"] {
    transform: scale(1.5);
  }

  .action-bar {
    flex-direction: column;
    background-color: $lightGrey;

    @include from($tablet) {
      flex-direction: row-reverse;
      background-color: unset;
    }

    .table-info {
      @include text-bold;
      margin-left: 1rem;
      color: $black;
      font-size: 1.1rem;
      padding: 0.25rem 0;

      @include from($tablet) {
        margin-left: 0;
        padding: 0;
      }
    }

    .actions-container {
      select {
        padding: 3px 10px; /* Apply padding to the text area */
        background-color: white;
        appearance: none; /* Remove default dropdown icon */
        -webkit-appearance: none; /* For Safari */
        -moz-appearance: none; /* For Firefox */
        background-image: url("../../assets/images/icons/chevron/down-chevron-3.svg"); /* Optional: Custom dropdown icon */
        background-position: right 10px center; /* Position icon on the right */
        background-repeat: no-repeat; /* Prevent icon from repeating */
        background-size: 16px 16px; /* Size of the icon */
        border: 1px solid #000; /* Optional: Add border to the select */
        border-radius: 4px; /* Optional: Rounded corners */
      }
      .apply-button {
        margin-left: 0.5rem;

        &[disabled] {
          background-color: $mediumGrey;
        }
      }
    }

    .select-all-check {
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 3rem;
      padding: 0 5px;
    }

    .responsive-row {
      padding: 0.25rem 0;
      align-items: center;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $mediumGrey;
      border-top: 1px solid $mediumGrey;

      &:last-of-type {
        border-top: none;
      }

      &:only-of-type {
        border-top: 1px solid $mediumGrey;
      }

      @include from($tablet) {
        &:last-of-type {
          border-top: 1px solid $mediumGrey;
          padding-left: 1rem;
        }

        &:only-of-type {
          border-top: 0;
          border-bottom: 0;
        }
      }

      @include from($desktop) {
        flex-direction: row;
        border: none;
        &:last-of-type {
          padding-left: 0;
          border: none;
        }
      }
    }

    .go-action {
      border: 0;
      font-size: 1.25rem;
      color: white;
      background-color: $orange;
      border-radius: 100px;
      width: 1.75rem;
      height: 1.75rem;
      margin-left: 0.5rem;
      padding: 0.1rem 0 0 0.1rem;

      &[disabled] {
        background-color: $mediumGrey;
      }
    }

    .action-button {
      font-size: 1.25rem;
      width: 2.75rem;
      height: 2.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.25rem;

      &.sort-action > i {
        line-height: 0;
      }
    }

    .search-input {
      flex: 1;
      display: flex;
      align-items: center;

      @include from($tablet) {
        min-width: 25rem;
      }

      input {
        flex: 1;
        padding-left: 35px;
        border: 1px solid #000;
        margin-right: 10px;
        &:active,
        &:focus {
          border: 1px solid #ce410d;
        }
      }

      .input-container {
        display: flex;
        width: 100%;
        position: relative;

        .action-button {
          position: absolute;
          right: 7px;
          top: 9px;
          height: 20px;
        }
        .action-button-search {
          position: absolute;
          left: 9px;
          top: 6px;
          height: 20px;
          z-index: 99;
        }
      }

      .download-icon {
        @include text-agenda-regular;
        display: flex;
        padding-left: 4px;
        padding-right: 4px;
        column-gap: 4px;
        cursor: pointer;
        transform: translateY(2px);
        width: 93px;
      }
    }
  }

  .status-bar {
    position: sticky;
    z-index: 1;
    top: $navbar-height-mobile;
    @include from($tablet) {
      top: $navbar-height;
    }
    display: flex;
    justify-content: space-between;
    background-color: $mediumGrey;
    padding: 0.25rem 1rem 0.25rem 1rem;
    color: white;
    @include text-medium;

    button {
      text-decoration: underline;
      border: 0;
      background-color: transparent;
      margin: 0;
      padding: 0;
      color: white;
      @include text-medium;
      font-size: 1rem;
    }
  }

  .ReactTable {
    overflow: auto;
    background-color: #fff;
    border: 0;

    @include from($desktop) {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .rt-thead {
      min-width: unset !important;
      background-color: #fdfdfd;

      &.-filters {
        display: none;
      }

      .th-expand {
        display: none;
      }

      .rt-th {
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: unset;
        letter-spacing: 0.05em;
        font-family: Agenda-Regular, sans-serif;
        font-size: 12px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        @include from($desktop) {
          min-height: 4rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        & > div {
          display: flex;
          flex: 1;
          align-self: stretch;
          justify-content: center;
          align-items: center;

          & > div {
            display: flex;
            flex: 1;
            align-self: stretch;
            justify-content: center;
            align-items: center;
          }
        }

        .col-total {
          @include text-bold;
          font-size: 0.9rem;
        }

        &.col-left {
          justify-content: flex-start;
          padding-left: 1rem;

          & > div {
            justify-content: flex-start;

            & > div {
              justify-content: flex-start;
            }
          }
        }

        &.col-right {
          justify-content: flex-end;
          padding-right: 1rem;

          & > div {
            justify-content: flex-end;

            & > div {
              justify-content: flex-end;
            }
          }
        }

        &.col-center {
          display: flex;
          justify-content: center;
        }

        &.col-wrap {
          white-space: initial;
        }

        &.-sort-desc {
          box-shadow: inset 0 -4px 0 0 $orange;
        }

        &.-sort-asc {
          box-shadow: inset 0 4px 0 0 $orange;
        }
      }
    }

    .rt-tbody {
      min-width: unset !important;

      .rt-td {
        border-right: 0;
        letter-spacing: 0.5px;
        color: $black;
        font-size: 15px;
        font-family: Agenda-Regular, sans-serif;

        &.col-left {
          justify-content: flex-start;
          overflow: hidden;
          text-overflow: ellipsis;

          @include from($tablet) {
            padding-left: 1rem;
          }
        }

        &.col-right {
          justify-content: flex-end;
          padding-right: 1rem;
        }

        &.col-wrap {
          white-space: initial;
        }

        &.col-center {
          display: flex;
          justify-content: center;
        }
      }

      .rt-tr {
        border-right: 0;
        align-items: center;
      }

      .rt-tr:hover {
        background-color: $brandUltraLight;
      }
    }

    .rt-expander:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(0);
      transform: translate(-50%, -50%) rotate(0);
      border-left: 5.04px solid transparent;
      border-right: 5.04px solid transparent;
      border-top: 7px solid rgba(0, 0, 0, 0.8);
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      cursor: pointer;
      border-top-color: $black;
    }

    .rt-expander.-open:after {
      -webkit-transform: translate(-50%, -50%) rotate(180deg);
      transform: translate(-50%, -50%) rotate(180deg);
      border-top-color: $orange;
    }

    .-pageSizeOptions {
      display: none;
    }
  }
}
