.entity-list-modal {
  width: 100%;

  .search-input {
    height: 3rem;
    color: $black;
    font-weight: 600;
    text-indent: 1.8rem;
    outline: none;
    background: transparent;
    background-image: url('../../assets/images/icons/search/search.svg');
    background-repeat: no-repeat;
    background-position: 8px center;
    min-width: 0px;
    width: 100%;
    flex: 3;
    border: none;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey4;
      opacity: 1; /* Firefox */
      font-weight: 400;
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $grey4;
      font-weight: 400;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $grey4;
      font-weight: 400;
    }
   
    &-location {
      background: transparent;
      background-image: none;
      outline: none;
      color: $black; 
      min-width: 0px;
      font-weight: 600;
      width: 100%;
      flex: 2;
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $grey4;
        opacity: 1; /* Firefox */
        font-weight: 400;
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $grey4;
        font-weight: 400;
      }
      
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $grey4;
        font-weight: 400;
      }
      
    }

    @include tablet {
      border-radius: 0;
    }

    @include mobile {
      height: 3rem;
      color: $black;
      min-width: 0px;
      width: 100%;
      flex: 1 1;
      &-location {
        flex: 1 1;
      }
    }
  }

  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-card {
    justify-content: center;

    .modal-card-body {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      border-radius: 10px;
      overflow: hidden;

      @include mobile {
        border-radius: 0px;
      }

      .entity-list-modal-title {
        @include text-agenda-bold;
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        display: flex;
      }

      .entity-list-modal-close {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .entity-list-modal-title-text {
        margin-top: auto;
        margin-bottom: auto;
        line-height: 20px;
      }

      .entity-list-modal-content {
        border: 1px solid $lightGrey4;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 12px;
        height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;

        @include mobile {
          border-radius: 10px;
          height: 90vh;
        }
      }

      .entity-list-container {
        row-gap: 24px;

        .entity-list-item-container {
          border-bottom: 0px;

          .avatar {
            margin: 0;
            margin-right: 12px;
            height: 42px;
            width: 42px;
          }
        }
      }

      .cauze-spinner {
        margin-top: 5rem;
      }

      .modal-body-list {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $lightGrey2;
      }
    }
  }
}
