
@mixin shadow-light {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

@mixin shadow-medium {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

@mixin shadow-deep {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
}

@mixin shadow-deep-top {
  box-shadow: -5px -5px 20px rgba(0, 0, 0, 0.05);
}

.shadow-deep {
  @include shadow-deep;
}

.shadow-deep-top {
  @include shadow-deep-top;
}

.shadow-light {
  @include shadow-light;
}

.shadow-medium {
  @include shadow-medium;
}
