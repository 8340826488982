.content-overview-container {
  width: 100%;

  .content-overview-section {
    margin: 1rem 0 1rem 0;

    .content-overview-section-header {
      @include text-agenda-black;
      font-size: 3rem;
      color: $trueBlack;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5rem;

      .down-chevron {
        filter: invert(64%) sepia(0%) saturate(131%) hue-rotate(195deg) brightness(95%) contrast(94%);
        @include add-clickable-area-small;
        width: 125px;
        height: 125px;

        &.is-closed {
          transform: rotate(180deg);
        }

        @include mobile {
          height: 70px;
          width: 70px;
          margin-bottom: 1rem;
        }
      }

      @include mobile {
        font-size: 26px;
        line-height: 30px;
        margin-top: 3rem;
      }
    }

    .content-overview-section-body {
      padding: 1rem 0 1rem 0;
      @include text-agenda-semi-bold;
      font-size: 25px;
      line-height: 35px;

      @include mobile {
        font-size: 17px;
        line-height: 21px;
        padding: 0;
      }

      

      &.is-open {
        overflow: hidden;
        max-height: 10000px;
        transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
      }

      &.is-closed {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
        padding-top: 0;
        padding-bottom: 0;
      }

      .opportunity-paragraph {
        @include text-agenda-regular;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 1rem;

        @include mobile {
          font-size: 17px;
          line-height: 20px;
        }
      }

      .opportunity-tagline {
        @include text-agenda-regular;
        font-size: 20px;
        line-height: 25px;
      }

      .opportunity-signature {
        @include text-agenda-bold;
        font-size: 20px;
        line-height: 24px;
      }

      .approach-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;


        @include desktop {
          flex-direction: row;
        }
        
        .approach-text {
          color: $complementBold;
          font-size: 48px;
          line-height: 60px;
          text-transform: uppercase;
          margin-right: 1rem;
          @include text-agenda-black;

          @include tablet-only {
            order: 2;
            text-align: center;
            margin-bottom: 2rem;
          }

          @include mobile {
            order: 2;
            font-size: 22px;
            line-height: 28px;
            margin: 1rem 0.5rem 2rem 0.5rem;
            text-align: center;
          }

          .approach-block {
            @include text-agenda-regular;
            font-size: 20px;
            line-height: 24px;
            color: $trueBlack;
            margin-top: 1rem;
            text-transform: none;
        
    
            @include mobile {
              font-size: 17px;
              line-height: 20px;
            }
          }

        }

        .approach-image {
          width: 50%;

          @include tablet-only {
            order: 1;
          }

          @include mobile {
            width: 200px;
            order: 1;
            
          }
        }
      }

     

      .works-section {

        .works-section-title {
          @include text-agenda-black;
          font-size: 40px;
          line-height: 48px;
          color: $textGrey;
          text-transform: uppercase;
          margin-bottom: 1rem;

          @include mobile {
            font-size: 22px;
            line-height: 26px;
            margin-top: 0.5rem;
          }
        }

        .works-section-body {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 1rem;
          margin-bottom: 2rem;

          @include mobile {
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            align-items: center;
          }

          .section-body-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 200px;
            margin: 0 0.5rem;

            @include mobile {
              max-width: 300px;
            }
            
            .body-item-image {
              max-width: 150px;
              height: 90px;
              margin: 0 auto;

              @include mobile {
                max-width: 100px;
                height: 70px;
              }
            }

            .body-item-title {
              @include text-agenda-bold;
              font-size: 20px;
              line-height: 24px;
              color: $trueBlack;
              text-transform: uppercase;
              margin-top: 1rem;

              @include mobile {
                font-size: 17px;
                line-height: 18px;
              }

              &.text-left {
                text-align: start;
              }
            }

            .body-item-message {
              @include text-agenda-regular;
              font-size: 20px;
              line-height: 24px;
              color: $trueBlack;

              @include mobile {
                font-size: 17px;
                line-height: 18px;
              }

              &.text-left {
                text-align: start;
              }
            }
          }

          &-commit {
          .race-to-zero-image-wrapper {

            padding: 2rem 1rem 0 2rem;
            border: 1px solid $grey4;
            max-width: 400px;
            margin-left: 2rem;

            @include mobile {
              margin-left: 0.5rem;
            }

            .race-to-zero-image {
            margin-bottom: -4rem;
            }

          }
          padding-left: 1rem;
          margin-bottom: 2rem;

          .body-content {

            @include mobile {
              margin-left: 0.5rem;
            }

            .content-paragraph {

              @include mobile {
              padding: 0;
              }
            }
          }
        }

          .section-body-avatars-container {
            display: flex;
            margin-left: 1rem;

            .avatar-main {
              display: flex;
              flex-shrink: 0;


              .avatar-main-image {
                z-index: 10;
                height: 120px;
                width: 120px;

                @include mobile {
                  height: 80px;
                  width: 80px;
                }
              }

              .thin-arrow-right {
                margin-left: -3rem;
                z-index: 0;

                @include mobile {
                  margin-left: -4rem;
                }
              }
            }

            .avatar-minor-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding-right: 2rem;
              margin-left: -0.5rem;


              @include mobile {
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                margin-top: 0.5rem;
                margin-left: 0;
              }

              .minor-avatar {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 1rem;
                margin: 1rem 0.5rem -1rem 0.5rem;

                @include mobile {
                  height: 60px;
                  width: 60px;
                  margin: 0 auto;
                }

                .minor-avatar-subtitle {
                    @include text-agenda-bold;
                    font-size: 30px;
                    line-height: 36px;
                    color: $grey2;

                    @include mobile {
                      font-size: 24px;
                      line-height: 28px;
                    }
                }
              }
            }
          }
        }
        .body-title {
          @include text-agenda-bold;
          font-size: 25px;
          line-height: 30px;
          color: $trueBlack;

          @include mobile {
            font-size: 17px;
            line-height: 20px;
          }
        }

        .body-content {
          
          margin-left: 1rem;

          .content-paragraph {
            @include text-agenda-regular;
            font-size: 20px;
            line-height: 24px;
            color: $trueBlack;
            margin-bottom: 1rem;
            padding: 0 1rem;

            @include mobile {
              font-size: 17px;
              line-height: 20px;
            }

          }
        }
      }

      .give-a-damn-section {

        .section-header {
          @include text-agenda-black;
          font-size: 40px;
          line-height: 48px;
          color: $textGrey;
          text-transform: uppercase;
          margin: 2rem 0;

          @include mobile {
            font-size: 26px;
            line-height: 32px;
            margin: 1rem 0;
          }
        }

        .impact-item {
          @include text-agenda-regular;
          font-size: 20px;
          line-height: 24px;
          color: $trueBlack;

          @include mobile {
            font-size: 1rem;
            line-height: 1.25rem;
          }

          .impact-stat {
            @include text-agenda-black;
            font-size: 36px;
            line-height: 45px;
            color: $trueBlack;

            @include mobile {
              font-size: 22px;
              line-height: 28px;
            }
          }

          .charity-map-link {
            @include text-agenda-regular;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            text-decoration: underline;
            color: $trueBlack;

            &:hover {
              color: $brandFocusLight;
            }

            @include mobile {
              font-size: 17px;
              line-height: 20px;;
            }
          }
        }


        .section-content-block {
          margin-bottom: 1.5rem;
          @include text-agenda-regular;
          font-size: 20px;
          line-height: 24px;
          color: $trueBlack;

          @include mobile {
            font-size: 1rem;
            line-height: 1.25rem;
          }

          .content-block-paragraph {
            margin-bottom: 1.5rem;
          }

          .content-block-list {
            padding-left: 1.5rem;

           
          }
        }
      }

      .body-content {
          
        .content-paragraph {
          @include text-agenda-regular;
          font-size: 20px;
          line-height: 24px;
          color: $trueBlack;
          margin-bottom: 1rem;

          @include mobile {
            font-size: 17px;
            line-height: 20px;
          }

          &.page-end {
            margin-bottom: 10rem;
          }

          .impact-assets-link {
            text-decoration: underline;
            color: $trueBlack;

            &:hover {
              color: $brandFocusLight;
            }
          }
        }
      }
    }
  }
}