.forgot-password-view {
  width: 100vw;
  background-color: $offWhite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;


    form {
      max-width: 450px;
      width: 100%;
      border-radius: 5px;
      overflow: hidden;

      .forgot-header {
        background-color: $support;
        padding: 1rem 1.5rem;
        color: $white;
        text-align: center;
        min-height: 115px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .forgot-title {
          @include text-agenda-bold;
          font-size: 2rem;
          line-height: 2.2rem;
          margin-bottom: 0.5rem;
       

          @include mobile {
            font-size: 1.25rem;
            line-height: 1.5rem;
            margin-bottom: 0.25rem;
          
          }
        }

        .forgot-subtitle {
          @include text-agenda-regular;
          font-size: 1rem;
          line-height: 1.2rem;
        

          @include mobile {
            font-size: 0.75rem;
            line-height: 1rem;
            
          }
        }
      }

      .forgot-body {
        padding: 1rem;
        background-color: $white;

        fieldset {
          max-width: 288px;
          margin: 2rem auto 0.5rem auto;
          border: none;
          padding: 0;

          @include mobile {
            margin: 1.5rem auto 0.5rem auto;
          }

          input {
            border: 1px solid $grey4;
            border-radius: 3px;
            padding: 1rem;
            color: $black;
            font-size: 0.75rem;
            width: 18rem;
  
            @include mobile {
       
              width: 100%;
            }
          }
        }  
        .cauze-button-container {
          margin: 1rem auto 0 auto;
          max-width: 16rem;
          width: 100%;

          .cauze-button {
          width: 100%;
          }
        }    
        
        .sign-in {
          @include text-agenda-regular;
          font-size: 1rem;
          line-height: 1.25rem;
          margin: 0.25rem 0 2rem 0;

          .sign-in-anchor {
            text-decoration: underline;
            display: inline-block;
          }
        }
      }
    }

    .error-message {
      max-width: 450px;
      height: 16px;
      width: 100%;
      background-color: $white;
      text-align: center;
      @include text-agenda-regular;
      font-size: 1rem;
      line-height: 1rem;

      .support-email {
        color: $brandFocusLight;

        &:hover {
          color: $supportFocus;
        }
      }
    }
  }
}