.gift-landing {
  background-color: white;
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: 640px;
  margin: auto;

  @media (min-width: 1024px) {
    width: 640px;
    margin: auto;
  }

  .title {
    @include text-title;
    text-transform: uppercase;
    font-size: 2rem;
  }

  .body-text {
    @include text-regular;
    color: $support;
  }

  .header-margin {
    margin: 2rem;
  }

  .gift-select-columns {
    align-self: stretch;
    margin: 1rem 0 0 0;

    @include tablet {
      margin: 2rem;
    }

    .column {
      display: flex;
    }

    .title {
      @include text-title;
      text-transform: uppercase;
      font-size: 1rem;
      margin-bottom: 0;
    }

    p {
      font-size: 0.75rem;
    }

    .gift-select-image {
      padding: 1rem;
      flex: 1;
      object-fit: contain;
      object-position: center;
    }

    .landing-button {
      margin-Left: 1rem;
      padding-left: 1em;
      padding-right: 1em;
    }

    .donate-now {
      background-color: $lilac;

      p, .title {
        color: $support;
      }
    }

    .add-to-account {
      background-color: $support;

      p, .title {
        color: $white;
      }
    }
  }

}
