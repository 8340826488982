.favorite-nonprofit-modal {
    width: 100%;
  
    .modal-background {
      background-color: rgba(0, 0, 0, 0.7);
    }
  
    .modal-card {
      justify-content: center;
  
      .modal-card-body {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        border-radius: 10px;
        overflow: hidden;
  
        @include mobile {
          border-radius: 0px;
        }
  
        .favorite-nonprofit-modal-title {
          @include text-agenda-bold;
          text-align: left;
          font-size: 20px;
          font-weight: 700;
          display: flex;
        }
  
        .favorite-nonprofit-modal-close {
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
  
        .favorite-nonprofit-modal-title-text {
          margin-top: auto;
          margin-bottom: auto;
          line-height: 20px;
        }
  
        .favorite-nonprofit-modal-content {
          border: 1px solid $lightGrey4;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 12px;
          height: 80vh;
          overflow-y: auto;
          overflow-x: hidden;
  
          @include mobile {
            border-radius: 10px;
            height: 90vh;
          }
        }
  
        .favorite-nonprofit-container {
          row-gap: 24px;
  
          .favorite-nonprofit-item-container {
            border-bottom: 0px;
  
            .avatar {
              margin: 0;
              margin-right: 12px;
              height: 42px;
              width: 42px;
            }
          }
        }
  
        .cauze-spinner {
          margin-top: 5rem;
        }
  
        .modal-body-list {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $lightGrey2;
        }
      }
    }
  }
  