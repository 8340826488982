.charity-list {
  @include text-agenda-regular;

  .swiper-button-prev, .swiper-button-next {
    display: none;
    color: black;
  }

  @media (min-width: 769px) {
    &.show-arrows:hover {
      .swiper-button-prev, .swiper-button-next {
        display: block !important;
      }
    }
  }

  @include mobile {
    padding-left: 18px;
    padding-right: 18px;
  }

  .charity-item {
    .avatar.md {
      height: 60px;
      width: 60px;
      margin: auto;
      z-index: 3;
    }

    .charity-item-hero-container {
      height: 102px;
      width: 100%;
      position: relative;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      overflow: hidden;
      display: flex;

      .charity-item-hero-backdrop {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.35);
        z-index: 2;
      }

      .charity-item-hero {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    .charity-item-body {
      border-left: 1px solid $lighterBorderGrey;
      border-right: 1px solid $lighterBorderGrey;
      border-bottom: 1px solid $lighterBorderGrey;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 9px;
      padding-right: 9px;

      .charity-item-name {
        @include text-agenda-bold;
        font-size: 13px;
        height: 23px;
        line-height: 23px;
        text-transform: capitalize;
        color: black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .charity-item-sub {
        height: 18px;
        line-height: 18px;
        font-size: 10px;
        color: $textGrey;
      }

      .charity-item-city {
        text-transform: capitalize;
      }

      .charity-item-state {
        text-transform: uppercase;
      }
    }
  }
}

.discover-charity-list {
  .charity-list {
    @include mobile {
      margin-left: -1rem;
      margin-right: -1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.charity-list-new {
  flex: 1;
  border: 1px solid $grey3;
  border-bottom-left-radius: 10px;
  padding: 0px 10px 0 10px;
  margin: 5px 0 0 0;

  @media (max-width: 768px) {
    border: none;
    padding: 0px 1rem 10px 1rem;
  }

  .charity-select-title {
    @include text-agenda-bold;
    color: black;

    .section-header-title {
      font-size: 17px;
    }

    &.multiple-charities {
      margin-top: -10px;
    }
  }

  .flex-wrap {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }
}

.charity-event-item {
  display: flex;
  flex-direction: column;
  flex: 0 0 9rem;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
  max-width: 77px;

  &.single-charity {
    flex-direction: row;
  }

  .event-charity-avatar {
    margin-bottom: 1rem;
    width: 50px;
    height: 50px;
    border-radius: 35px;
    border: 1px solid $grey3;
  }

  .charity-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    &.single-charity {
      margin-left: 10px;
      height: 77px;
      align-items: flex-start;
      justify-items: flex-start;
    }

    .charity-name {
      @include text-agenda-regular;
      font-size: 13px;
      line-height: 13px;
      color: black;
      max-width: 77px;
      word-wrap: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;

      &.single-charity {
        max-width: unset;
      }
    }

    .charity-location {
      @include text-agenda-regular;
      color: $black;
      word-break: break-all;
      font-size: 10px;
      justify-content: center;
      text-transform: uppercase;
    }
  }

  .learn-more {
    margin-top: 0.5rem;
    @include text-title;
    font-size: 0.75rem;
    background-color: $baseColor;
    color: $white;
    padding: 0.4rem 1rem;
    text-transform: uppercase;
    border-width: 0px;
    text-align: center;
    cursor: pointer;
  }

  .select-button.selected:hover {
    background-color: white;
    color: $orange;
  }

  .select-button {
    margin-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &:only-of-type {
    padding-right: 0;

    .avatar {
      margin-left: auto;
      margin-right: auto;
    }

    .charity-name {
      justify-content: center;
      text-align: center;
    }

    .charity-location {
      justify-content: center;
      text-align: center;
    }

    .learn-more {
      justify-content: center;
      text-align: center;
    }
  }
}
