.section-header-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 1rem 2rem 0.5rem 0;

  .section-header-title {
    @include text-title;
    @include text-agenda-black;
    text-transform: capitalize;
    margin-right: 1rem;
    font-size: 30px;
  }

  .section-header-zig-zag {
    margin-right: 0.75rem;
  }

  .section-header-subtitle {
    @include text-regular;
    font-size: 13px;
    line-height: 3rem;
    color: $mediumGrey;
  }
}
