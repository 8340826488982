.link-preview {
  background-color: $lightGrey;
  overflow: hidden;

  .content {
    padding: 0 0.5rem 0.25rem 0.5rem;
  }

  .host-name {
    @include text-regular;
    color: $grey;
  }

  .title {
    @include text-agenda-bold;
    font-size: 14px;
    color: $black;
  }

  .small-preview {
    display: flex;
    flex-direction: row;

    .preview-image {
      width: 160px;
      height: 90px;
      margin-right: 0.5rem;
    }

    .host-name {
      @include text-regular;
      color: $grey;
    }

    .title {
      @include text-medium;
      font-size: 1rem;
    }

    .delete {
      margin: 0;
    }
  }
}
