.payment-meta {
  margin: 0.5rem;

  .balance-total {
    @include text-agenda-regular;
    color: $textGrey;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    margin-top: 5px;
  }

  .card-image {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .bank-image {
    height: 1rem;
    margin-left: 0.5rem;
    filter: invert(64%) sepia(0%) saturate(131%) hue-rotate(195deg)
      brightness(65%) contrast(94%);
  }

  .card-total {
    @include text-medium;
    color: $textGrey;
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 1rem;
    text-align: end;
  }

  .divider {
    @include text-regular;
    color: $textGrey;
    font-size: 10px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }

  .balance-remaining {
    @include text-agenda-semi-bold;
    color: $textGrey;
    font-size: 10px;
    text-transform: uppercase;
    text-align: end;
    line-height: 10px;
    margin-top: 5px;
  }

  &-empty {
    height: 2rem;
  }
}
