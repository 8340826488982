.popular-cauzes-wrapper {
  background-color: $white;
  padding: 0 1rem;

  @include mobile {
    padding: 0 1rem;
  }

  .loading-popular-cauzes {
    height: 498px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .popular-cauzes {
    padding-left: 0.5rem;

    @include fullhd {
      padding-left: 0;
    }

    .glide__arrows {
      margin-right: 0.5rem;

      .glide__arrow {
        background-color: unset;
      }
    }
  }
}
