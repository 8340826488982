.select-table {
  .rt-tr {
    background-color: white;
  }
  .rt-td:first-of-type,
  .rt-th:first-of-type {
    width: 3rem !important;
    max-width: 3rem !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: stretch;
    display: flex;
  }

  .rt-td:first-of-type {
    @include from($tablet) {
      background-color: white;
    }
  }

  .row-selected {
    background-color: $veryLightGrey;
  }
}
