.event-match-list {
  flex: 1;
  display: flex;
  padding: 1rem 1rem 0;
  border-top: thin solid $lightGrey;

  @include tablet {
    padding: 1rem 2rem 0;
  }

  .match-item {
    flex-direction: row;
    display: flex;
    color: black;
  }

  .sponsor-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -0.5rem;
  }

  .match-info {
    @include text-bold;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .match-max {
    @include text-regular;
    color: $darkGrey;
    word-break: break-all;
    font-size: 0.6rem;
    justify-content: center;
  }

  .sponsor-label {
    @include text-bold;
    color: $orange;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;

    @include tablet {
      margin-left: 2rem;
      margin-bottom: 1rem;
    }
  }

  .sponsor-item {
    margin: 0.5rem 1rem 0.5rem 0;
    flex: 0 0 6rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;

    .avatar {
      margin-bottom: 0.5rem;
      width: 4rem;
      height: 4rem;
      border-radius: 2rem;

      @include tablet {
        width: 5rem;
        height: 5rem;
        border-radius: 2.5rem;
      }
    }

    .sponsor-name {
      @include text-title;
      font-size: 16px;
      text-align: center;
      color: $black;
      text-overflow: ellipsis;
    }
  }
}
