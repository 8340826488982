
.button {
  @include text-title;
  border-radius: 0;
  font-size: 1rem;
  text-transform: uppercase;


  &.is-rounded {
    border-radius: 3px;
  }

  .cauze-spinner {
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    display: block;

    .cauze-loader {
      width: 1.25rem;
      height: 1.25rem;

      .circular {
        .path {
          animation: dash 1.5s ease-in-out infinite;
          stroke: white;
        }
      }
    }

    .smile {
      display: none;
    }
  }

  .is-submitting {
    opacity: 0.25;
  }
}

.pin-bottom {
  @include shadow-deep-top;
  padding: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: white;
  border: thin solid $lightGrey;
  border-width: thin 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    max-width: 25rem;
  }
}

.button-link {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  margin: 0 1rem;
}

.delete {
  margin: 1rem;

  &:hover {
    background-color: unset;
    &::before,
    &::after {
      background-color: $grey;
    }
  }

  background-color: transparent;
  &::before,
  &::after {
    background-color: $black;
  }
  &::before {
    width: 100%;
  }
  &::after {
    height: 100%;
  }
}

.button-new {
  @include text-agenda-bold;
  font-size: 13px;
  width: 130px;
  height: 40px;
  line-height: 15.83px;
  text-align: center;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  background-color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.secondary {
    color: $black;
    border: 1px solid black;
    background-color: white;
  }

  .cauze-loader {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto 0 !important;

    .circular {
      .path {
        animation: dash 1.5s ease-in-out infinite;
        stroke: $white;
      }

      .grey-path {
        stroke: $lightGrey;
      }
    }
  }

  .smile {
    display: none;
  }
}