.search-view {
  display: flex;
  flex: 1;
  background-color: $white;

  .search-view-hero-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    
  }

  .cauzes-wrapper {
    display: block;
    margin: 0 auto;
    padding: 2rem 0;
    width: 100vw;
    background-color: $offWhite;

    @include fullhd {
      max-width: 88rem;
    }

    .top-cauzes {
      @include max-width-default;
      width: 100%;
      margin: 0 auto;
      padding-left: 0rem;   

      .popular-cauzes-wrapper {
        background-color: $offWhite;

        .details {
          background-color: $white;;
        }
      }
    }
  }  

  .container {
    width: 100%;
    @include max-width-default;
    @include tablet {
      background-color: $white;
      
    }

    .trending-header {
      margin-bottom: 5rem;
      color: $textGrey;
      @include text-title;
      text-transform: uppercase;
      font-size: 20px;
      padding-left: 0.5rem;

      &.no-results {
        margin-bottom: 0px;
      }

      @include fullhd {
        padding-left: 0;
      }
    }

    .search-pill-container {
      position: absolute;
      top: 7rem;
      display: flex;
      justify-content: flex-start;
      z-index: 1;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      overflow: hidden;
      overflow-x: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      padding-left: 0.5rem;

      &::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari and Opera */
      }

      @include fullhd {
        padding-left: 0;
      }
      @include mobile {
        top: 8rem;
      }

      .topic {
        &:first-child {
          margin-left: 0;
        }

        height: 2rem;
        padding-top: 0.4rem;
        margin: 0 0.35rem;
        width: fit-content;
        white-space: nowrap;
        background-color: transparent;
        color: $textGrey;
        border: 1px solid $textGrey;

        &.is-selected {
          background-color: $textGrey;
          color: $white;
        }
      }

    }

    .search-title {
      @include text-title;
      text-transform: uppercase;
      padding: 1rem;
      margin-left: 1.5rem;
    }
    .push-footer {
      min-height: 41rem;

    .cauze-spinner {
      justify-content: flex-start;
      padding-top: 13rem;
    }
  }

    .results-container {
      margin-top: 1rem;

      @include tablet {
        margin: 1rem 0;
      }

      .cauze-button-container {
     text-align: center;
      }
    }

    .columns {
      margin: 0rem 0rem 1rem 0rem;

      @include tablet {
        margin: 0rem 0rem 2rem 0rem;
      }
    }

    .pagination {
      padding: 1rem 1rem 1rem 1rem;
      justify-content: center;

      li {
        padding: 0.5rem 1rem 0.5rem 1rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 6px;
        border-width: 0;
        border-style: solid;

        @include mobile {
          padding: 0.5rem;
          margin: 0.5rem;
        }
      }
    }

    .active-page {
      background-color: $support;
    }

    .active-page-number {
      color: $white !important;
    }

    .page-number {
      color: $black;
    }

    .empty-item {
      background-color: $white;
      padding: 4rem 0;
     
    

      p {
        color: $textGrey;
        text-align: center;
        

        &.text-bold {
          @include text-title;
          text-transform: uppercase;
          font-size: 30px;
          margin-bottom: 1.25rem;
        }

        &.text-normal {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    
  }
}
