.company-reporting {
  display: flex;
  flex: 1;

  .dashboard-container {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .dashboard-item {
    padding-top: unset;
  }

  .engagement-chart {
    width: 13rem;
    height: 13rem;
    padding: 1rem;
  }

  .dashboard-item-body {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Walshiem-Medium';
    justify-content: center;

    .bold {
      font-family: 'Walshiem-Bold';
    }
  }

  .dashboard-chart-dot {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
  }

  .dashboard-chart-dot-large {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
  }

  .dashboard-chart-key {
    font-family: 'Walshiem-Bold';
    font-size: 0.7rem;
    padding-left: 0.4rem;

    p {
      padding-left: 0.4rem;
    }
  }

  .dashboard-chart-key-large {
    font-family: 'Walshiem-Bold';
    font-size: 1rem;
    padding-left: 0.5rem;

    p {
      padding-left: 0.5rem;
    }
  }
}
