.apple-pay-container {
  width: 100%;
  height: 48px;

  .apple-pay-button {
    width: 100%;
    border-radius: 5px;
    padding: 5px 0px;
    display: block;
    background-color: black;
    border: none;
    cursor: pointer;
  }
}