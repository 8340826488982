.entity-list-item-container {
  @include text-agenda-regular;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $lightGrey2;

  .entity-list-item-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .avatar {
      margin: 1rem 0.5rem 1rem 3.25rem;
      height: 3.5rem;
      width: 3.5rem;

      @include mobile {
        margin-left: 1rem;
      }
    }

    .name {
      @include text-agenda-bold;
      font-size: 17px;
      text-transform: capitalize;
      line-height: 21px;
      word-break: break-word;
    }

    .foundation-name {
      color: $grey7;
      font-size: 12px;
      text-transform: capitalize;
      word-break: break-word;
    }
  }

  .cauze-button-container {
    margin: 0 2rem 0 auto;

    .cauze-button {
      @include text-agenda-bold;
      font-size: 10px;
      border-radius: 8px;
      width: 88px;
      background-color: $grey7;
      border: none;

      &:hover {
        color: white;
      }

      &.is-follow {
        background-color: $brandNew;
      }
    }

    @include mobile {
      margin-right: 1rem;
    }

    .is-follow {
      .hover-child {
        display: none;
      }

      &:hover {
        .non-hover-child {
          display: none;
        }

        .hover-child {
          display: block;
        }
      }
    }
  }
}
