.avatar {
	background-color: $lightGrey;
	width: 2rem;
	height: 2rem;
	border-radius: 1rem;
	max-width: unset;
	border: none;
	object-fit: cover;

	&.md {
		width: 4rem;
		height: 4rem;
		border-radius: 2rem;
	}

	&.lg {
		width: 8rem;
		height: 8rem;
		border-radius: 4rem;
	}

	&.xl {
		width: 12rem;
		height: 12rem;
		border-radius: 4rem;
	}

	&.xsm {
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 0.75rem;
	}

	&.is-square {
		border-radius: $radius-small;
	}

	&.orange-border {
		border: 1px solid $brandFocusLight;
	}
}
