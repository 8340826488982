.donation-checkout {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @include mobile {
    margin-top: 0;
  }

  .payment-request-button {
    width: 100%;
  }

  .navbar {
    min-height: unset;

    .navbar-brand {
      min-height: unset;
    }

    .backdrop {
      margin-top: 0;
    }
  }

  h1 {
    @include text-agenda-black;
    color: $black;
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
  }

  h2 {
    @include text-agenda-black;
    color: $black;
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
  }

  h3 {
    @include text-agenda-regular;
    color: $grey2;
    font-size: 20px;
    line-height: 60px;
    text-align: center;
  }

  h4 {
    @include text-agenda-regular;
    color: $textGrey;
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
  }

  .selected-nonprofits {
    text-align: center;
    @include text-agenda-regular;
    color: $grey2;
    height: 60px;
    margin-top: 24px;
    display: flex;

    div {
      margin-top: auto;
      margin-bottom: auto;
      flex-grow: 1;

      .sn-title {
        font-size: 20px;
        line-height: 100%;
      }

      .sn-subtitle {
        font-size: 12px;
      }
    }
  }

  .column-container {
    @include mobile-narrow;

    width: 556px;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    .form-container {
      background-color: $white;
      align-items: center;

      .avatar-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;

        .user-recipients {
          @include text-agenda-bold;
          font-size: 13px;
          height: 23px;
          line-height: 23px;
          text-transform: capitalize;
          color: black;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .user-sub {
          height: 18px;
          line-height: 18px;
          font-size: 10px;
          color: $textGrey;
        }

        .user-avatar {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .user-name {
          @include text-agenda-regular;
          font-size: 10px;
        }
      }

      @include mobile {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      & > * {
        margin-bottom: 3rem;
      }

      .message-container {
        margin: 2rem 0 2rem 0;

        p {
          text-align: left;
          margin-bottom: 5px;
          color: $textGrey;
          font-size: 0.8rem;
          margin-left: 2px;
        }

        .message-input {
          resize: none;
          padding: 1rem;
          background-color: $offWhite;
          color: $textGrey;
          border: none;
          border-radius: 3px;
          font-size: 0.75rem;
          width: 100%;
          border: rgb(0, 0, 0, 0) 2px solid;

          &:focus {
            outline: none;
            border: $brandFocusLight 2px solid;
          }

          &::placeholder {
            color: $grey4;
          }
        }
      }

      .matching {
        width: 100%;
        background-color: $lightGrey;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 2rem 2rem 2rem;
        flex-direction: column;
      }

      .payment-description {
        @include text-regular;
        padding: 0.5rem 0 1rem 0.25rem;
        line-height: 13px;
        color: $textGrey;
        font-size: 9px;
      }

      .unauthed-payment {
        margin: 0 1rem 2rem 1rem;
        text-transform: uppercase;

        .paypal-button-container {
          width: 100%;
          z-index: 1;

          div {
            width: 100%;
          }
        }

        .cauze-button-container {
          width: 100%;
        }

        .stripe-element-wrapper {
          width: 100%;
          margin: 1rem 7px 2px 7px;
          border: 1px solid $grey3;
          border-radius: 5px;
          padding: 0.5rem;
        }

        h3 {
          @include text-agenda-semi-bold;
        }
      }

      .donate {
        @include text-agenda-black;
        width: 100%;
        background-color: black;
        padding: 1rem 0;
        border: none;

        &:disabled {
          background-color: gray;
        }
      }
    }
  }

  .user-info-input-container {
    width: 100%;

    .user-name-fields-container {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;
    }
  }
}
