.charity-faq {
  p {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .accordion {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: unset;
  }

  .faq-list-item {
    list-style: disc;
    margin-left: 2rem;
  }
}
