.error-card-container {
  width: 100%;
  max-width: 28rem;
  padding: 1rem 1rem;

  .error-card {
    padding: 1rem 2rem;
    background-color: $baseColorPale;
    border-radius: 5px;

    &.support {
      background-color: $supportFocus;
    }

    .error-card-message {
      color: $white;
      @include text-regular;
      font-size: 14px;
      line-height: 1rem;

      .login-text {
        color: $brandFocusLight;
        text-decoration: underline;
      }
    }
  }
}