.block-modal {
  @include text-agenda-regular;
  align-items: center;

  .modal-background {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-card {
    justify-content: center;

    @include mobile {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .confirm-modal-head {
      background-color: $white;
      padding-left: 16px;
      padding-bottom: 31px;
      padding-top: 30px;
      padding-right: 16px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      @include mobile {
        padding-right: 1rem;
        padding-left: 1rem;
      }

      .modal-card-title {
        @include text-agenda-black;
        color: $black;
        font-size: 25px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;

        @include mobile {
          margin-left: 0;
        }

        .close-button {
          margin-bottom: 10px;
          color: $black;

          cursor: pointer;
        }

        .modal-card-title-text {
          width: 90%;
        }
      }
    }

    .confirm-modal-body {
      @include text-agenda-regular;
      background-color: white;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 28px;
      font-size: 16px;
      color: $textGrey;
      line-height: 22px;
    }

    .modal-card-foot {
      justify-content: flex-start;
      background-color: $white;
      border: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-top: 12px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 36px;

      @include mobile {
        padding-left: 1rem;
      }
    }

    .cauze-button {
      @include text-agenda-black;
      background-color: black;
      border: none;
      border-radius: 10px;
      height: 45px;
      padding-top: 13px;
    }
  }
}
