.hero-wrapper {
  color: $white;
  overflow: hidden;
  width: 100vw;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.cobranding-none {
    background-image: url('../../assets/images/backgrounds/HERO.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-clip: border-box;
    background-size: 100% auto;
    height: 28rem;
    background-size: cover;

    .hero-title-wrapper {
      margin-top: 5rem;
    }
  }

  &.cobranding-Thnks {
    background-color: $thnksBlue;
    height: 25rem;
    justify-content: flex-start;

    .hero-title-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin: 1rem auto 0 auto;
    }
  }

  &.hero-short {
    background-repeat: no-repeat;
    background-position: bottom;
    background-clip: border-box;
    background-size: 100% auto;
    height: 15rem;

    @include mobile {
      background-size: cover;
    }
  }

  .hero-title-wrapper {
    margin: 4rem auto 0 auto;
    max-width: 40rem;
    position: relative;
    display: flex;
    justify-content: center;

    h1 {
      display: block;
    }

    .hero-title {
      margin: 0 auto;
      @include text-title;
      text-transform: uppercase;
      font-size: 3.125rem;
      text-align: center;
      line-height: 3rem;

      &-cobranded {
        @include text-title;
        margin: 0 auto;
        text-transform: uppercase;
        font-size: 3rem;
        line-height: 2.5rem;
        text-align: center;

        @include mobile {
          line-height: 3rem;
          font-size: 3rem;
          margin-bottom: 2rem;
        }
      }

      &.hero-greeting {
        display: block;
      }

      .hero-title-color {
        color: $brandFocus;
      }
    }

    .hero-thnks-image {
      position: absolute;
      z-index: 0;

      &.image-left {
        left: -26.75rem;
        top: 4rem;

        @include mobile {
          display: none;
        }
      }

      &.image-right {
        left: 27rem;
        top: 4rem;

        @include mobile {
          display: none;
        }
      }
    }
  }

  .hero-search-wrapper {
    height: 4rem;
    max-width: 38rem;
    margin: 1rem auto 5rem auto;
    display: flex;
    z-index: 1;
    background-color: $white;
    border: 1px solid transparent;
    border-radius: 0.5rem;

    .charity-donate-search {
      padding-top: 0;
      z-index: 1;
    }

    .columns {
      margin: 0 0;
    }
  }
}