.checkout {
  background-color: white;
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: 1024px;

  @media (min-width: 1024px) {
    width: 1024px;
    margin: auto;
  }

  .header {
    border-style: solid;
    border-color: $support;
    border-width: 0 0 thin 0;
  }

  .charity {
    padding: 1rem 0;
    border-style: solid;
    border-color: $lightGrey;
    border-width: 0 0 thin 0;

    .donation-amount-per-charity {
      @include text-bold;
      color: $orange;
      margin: 1rem 1rem 0 1rem;
      text-align: center;
    }
  }

  .give-to-all {
    margin-bottom: 1rem;
    align-items: center;

    .select-all {
      margin: 0;
      height: 1rem;
      flex: 0;
      margin-left: 1rem;
    }

    label {
      padding-left: 1rem;
    }
  }

  .charity-list {
    .charity-item {
      flex: 0 0 7rem;
    }
  }

  h2 {
    @include text-bold;
  }

  // for payment options spacer
  hr {
    display: flex;
    flex: 1;
    height: 1px;
    border: 0;
    border-top: 1px solid $black;
    padding-top: 1px;
  }

  hr:first-of-type {
    margin-right: 1rem;
  }

  hr:last-of-type {
    margin-left: 1rem;
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  .payment-spacer {
    margin-top: 0.5rem;
  }

  .paypal-button {
    display: flex;
    flex: 1;
    justify-content: center;
    z-index: 1;
  }

  // need to make the paypal button container div a flex
  .paypal-button-container > div {
    display: flex;
    flex: 0.4;
  }

  form {
    h2,
    .charity-select-title {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
      color: $support;
    }

    .label {
      display: flex;
      flex: 1 0 10rem;
      flex-direction: column;
      margin-right: 1rem;

      @include tablet {
        max-width: 20rem;
      }
    }

    .input {
      border-radius: 5px;
      margin: 0.5rem 0;
      flex: 1;
      border-style: solid;
      border-color: $lightGrey;
      border-width: thin;
      padding: 0.7rem;
    }

    .columns {
      margin-left: unset;
    }

    .input-margin-bottom {
      margin-bottom: 1.05rem;
    }

    .input-error {
      color: $red;
    }

    .input-error-underline {
      border-style: solid;
      border-color: $red;
      border-width: 0 0 thin 0;
    }

    .error-text {
      font-size: 0.7rem;
    }

    .StripeElement {
      border-bottom: thin solid $support;
      padding-bottom: 0.2rem;
      margin: 0 0 2rem 0;
    }

    .donate-button {
      flex: 1;
      max-width: 20rem;
      margin-bottom: 1rem;
      border: none;

      @include tablet {
        max-width: 25rem;
      }
    }

    .card-element {
      margin-top: 1rem;
      margin-bottom: 1rem;

      label {
        flex: unset;
        flex-direction: row;
        margin-bottom: 1rem;
      }
    }
  }

  .fine-print {
    font-size: 0.75rem;
    padding: 2rem;
    text-align: center;
  }
}

.pending-invites-modal {
  padding: 2rem;
  background-color: $warning;
}
