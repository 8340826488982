.match-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .match-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .match-info {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 15px;
      margin-top: 11px;
      color: black;
      span {
        display: inline;
      }
    }

    .match-amount-text {
      font-size: $size-normal;
      @include text-bold;
    }

    .match-name {
      font-size: $size-normal;
    }

    .match-max {
      font-size: $size-small;
      @include text-bold;
    }
  }
}
