@import "_colors";
@import "_fonts";
@import "_config";
@import "./bulma/bulma";
@import "_textStyles";
@import "_gradients";
@import "_shadows";
@import "_utils";
@import "./components/_index";
@import "./views/_index";

html,
body,
#root,
.app {
  height: -webkit-fill-available;
  display: flex;
  align-self: stretch;
  flex: 1;
  min-height: 100%;
}

html.is-clipped {
  body {
    overflow: hidden !important;
  }
}

.env-indicator {
  width: 5rem;
  height: 1.2rem;
  position: absolute;
  top: 0;
  left: calc(50% - 2.5rem);
  background-color: grey;
  font-size: 0.75rem;
  line-height: 0.85rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
  padding: 3px;
  z-in &.local {
    background-color: yellow;
  }

  &.dev {
    background-color: green;
  }
}

.app {
  font-family: open-sans, sans-serif;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  flex: 1;
  background-color: $veryLightGrey;
  min-height: 10rem;
}

/* This styles the div that contains the app.js children defined in the
 scroll to top hook */
.app > div {
  display: flex;
  flex: 1;
}

.banner-margin {
  margin-top: 2rem;
}

.app-link {
  color: #61dafb;
}

/* This fixes a display issue with the plaid modal iframe */
iframe {
  height: 100vh;
}
