.charity-details {
  &.modal-card {
    max-width: 44rem;
    width: 100%;
    padding: 0 1rem;
  }
  .hero {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $black;
    padding-right: 16px;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @include mobile {
      padding-left: 16px;
      min-height: 83px;
    }

    .delete {
      position: relative;
      margin: 0;
      justify-self: flex-start;
    }

    .delete::before,
    .delete::after {
      background-color: $white;
    }

    .charity-avatar {
      .avatar {
        height: unset;
        margin: 24px;

        @include mobile {
          display: none;
        }
      }

      a {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        line-height: 100%;
        font-size: 0.8rem;
        @include text-medium;
      }
    }

    .charity-header {
      margin-bottom: 0;
      margin-right: 0.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .charity-name {
        @include text-agenda-black;
        font-size: 25px;
        color: $white;

        @include mobile {
          line-height: 100%;
          margin-bottom: 2px;
        }
      }

      .charity-location {
        @include text-agenda-regular;
        font-size: 1rem;
        color: $white;
        margin-bottom: 0;
        font-weight: normal;
        line-height: 100%;
      }

      .charity-details {
        @include text-agenda-regular;
        font-size: 1rem;
        color: $white;
        line-height: 100%;
      }
    }
  }

  .modal-card-body-mini {
    flex: unset;

    .body-text {
      margin: 0;
      padding: 28px 62px 36px 62px;
      color: $textGrey;
      @include text-agenda-regular;
      font-size: 1rem;

      @include mobile {
        padding: 1rem;
      }

      .charity-description {
        @include text-agenda-regular;
        line-height: 22px;
        margin: 0;
      }
    }

    .cauze-button-container {
      align-self: flex-end;
      margin-right: 3.25rem;
      @include mobile {
        margin-right: 1rem;
      }
    }
  }
  .modal-footer {
    justify-content: flex-start;
    background-color: $white;
    border: none;
    padding: 24px 0px 48px 62px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @include mobile {
      padding: 1rem;
    }

    span {
      @include text-agenda-black;
    }
  }
}
