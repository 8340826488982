.cauze-charity-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.active {
    background: rgba(255, 255, 255, 0.3);
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }

  .cauze-charity-item {
    margin: 0.25rem 0;
  }
}
