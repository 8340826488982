.profile-header {
  @include text-agenda-regular;
  border-radius: 10px;
  overflow: hidden;
  @include mobile {
    overflow: visible;
  }
  @include mobile {
    border-radius: 0px;
  }

  .profile-header-hero {
    position: relative;
    min-width: 544px;

    @include mobile {
      margin: -16px -16px 0 -16px;
    }
    .profile-header-hero-overlay {
      background-color: rgba(0, 0, 0, 0.35);
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .hero {
    height: 181px;
    background-position: center;
    background-size: cover;

    @include mobile {
      min-width: 544px;
    }
  }

  .empty-hero {
    background-color: $support;
    display: flex;
  }

  .profile-header-body {
    padding-right: 26px;
    padding-left: 26px;
    padding-top: 12px;
    padding-bottom: 14px;
    position: relative;
    border-left: 1px solid $lightBorderGrey;
    border-right: 1px solid $lightBorderGrey;
    border-bottom: 1px solid $lightBorderGrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @include mobile {
      border-left: none;
      border-right: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .avatar-container {
      border-radius: 150px;
      overflow: hidden;
      height: 70px;
      width: 70px;
      position: absolute;
      left: 26px;
      top: -26px;
    }

    .action-container {
      display: flex;
      justify-content: flex-end;
      padding-left: 70px;
      flex-wrap: wrap;
      row-gap: 11px;

      .profile-action-edit {
        @include text-agenda-semi-bold;
        border-radius: 6px;
        border: 1px solid $veryDarkGrey;
        background-color: white;
        padding-top: 10px;
        padding-bottom: 6px;
        color: $veryDarkGrey;
        cursor: pointer;
        margin-left: 11px;
      }

      .profile-action-give {
        @include text-agenda-semi-bold;
        border-radius: 6px;
        border: 1px solid $lightBorderGrey;
        background-color: black;
        padding-top: 10px;
        padding-bottom: 6px;
        padding-left: 38px;
        padding-right: 38px;
        color: white;
        cursor: pointer;
        margin-left: 11px;
      }

      .profile-action {
        height: 34px;
        width: 34px;
        border: none;
        border-radius: 8px;
        display: flex;
        padding: 0;
        cursor: pointer;
        background-color: $dividerGray;
        margin-left: 11px;
        color: $grey9;

        &:hover {
          background-color: $lightGrey3;
        }

        &:active {
          background-color: $mediumGrey;
        }

        &.unfollow {
          background-color: $brandNew;

          &:hover {
            background-color: $brandNew;
          }

          &:active {
            background-color: $brandNew;
          }
        }

        svg {
          margin: auto;
        }
      }
    }

    .identity-container {
      padding-top: 2px;
      padding-bottom: 3px;

      .identity-name {
        @include text-agenda-bold;
        font-size: 25px;
        line-height: 32px;
      }

      .identity-foundation {
        @include text-agenda-regular;
        font-size: 15px;
        line-height: 20px;
        color: $textGrey;
      }

      .identity-separator {
        margin-left: 12px;
        margin-right: 12px;
      }

      a {
        color: $textGrey;
      }
    }
  }

  .bio-text {
    display: inline-block;
  }

  .socials-container {
    display: flex;
    column-gap: 5px;
    flex-wrap: wrap;
  }

  .profile-social {
    height: 20px;
    width: 20px;
    border-radius: 150px;
    background-color: $grey5;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bio-container {
    text-align: left;
    justify-content: center;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 17px;

    .read-more-button {
      color: $textGrey;
      text-decoration: underline;
      cursor: pointer;
    }

    .empty-bio {
      color: $textGrey;
    }
  }

  .metrics-container {
    display: flex;
    column-gap: 52px;
    row-gap: 26px;
    border-top: 1px solid $lighterBorderGrey;
    padding-top: 16px;
    margin-left: -26px;
    margin-right: -26px;
    padding-left: 26px;
    padding-right: 26px;
    flex-wrap: wrap;

    @include mobile {
      column-gap: 37px;
    }

    .profile-metric-value {
      @include text-agenda-bold;
      font-size: 24px;
      line-height: 100%;
    }

    .profile-metric-title {
      color: $textGrey;
      font-size: 9px;
      line-height: 100%;
      text-transform: uppercase;
    }
  }
}
