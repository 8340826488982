.modal-card {
  .cents {
    padding: 0;
  }
  &.has-carousel {
    .modal-card-head {
      align-items: unset;

      .modal-carousel-subheader {
        padding: 0.5rem;
      }

      .modal-carousel-image {
        padding: 0rem 1rem 0rem 0rem;
      }
    }
  }

  &.has-datepicker {
    .modal-card-body {
      min-height: 26rem;
    }
  }

  @include mobile {
    max-height: unset;
    flex: 1;

    .modal-card-head,
    .modal-card-foot {
      border-radius: 0;
    }
  }

  .delete {
    position: absolute;
    right: 0;
    top: 4;
    z-index: 5;
  }

  .modal-card-title {
    @include text-bold;
  }

  .modal-card-body {
    padding: 1rem;
  }

  .modal-card-foot {
    flex-wrap: wrap;
  }

  .option-group {
    padding-bottom: 1rem;
    margin: 1rem 0 1rem 0;

    .radio + .radio {
      margin-left: unset;
    }

    .option-group-subhead {
      @include text-medium;
      padding: 0rem 1rem 0 1rem;
      color: black;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .option-info {
      padding: 0.25rem 1rem 2rem 1rem;
      @include text-medium;
    }

    .option-group-info-subhead {
      padding: 0rem 1rem 0 1rem;
    }

    &:last-of-type {
      border-bottom: none;
    }

    .option-total {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }

    .option-datepicker {
      padding: 0rem 1rem 0 1rem;
    }
  }

  .control {
    padding: 0 1rem 0 1rem;
    flex-direction: column;

    .radio {
      margin-bottom: 1rem;

      input[type='radio'] {
        transform: scale(1.5);
        margin-right: 1rem;
        margin-left: 0.5rem;
      }
    }
  }
}

.sponsor-info-modal {
  .modal-card-title {
    @include text-title;
  }

  .modal-card-body {
    .sponsor-header-item {
      @include text-title;
      text-decoration: underline;
    }

    td {
      padding: 5px 10px;
    }
  }

  .option-group {
    border-bottom: 1px solid $lightGrey;
  }
}

.search-options-modal {
  .modal-card-body {
    padding: unset;
  }

  .option-group {
    border-bottom: 1px solid $lightGrey;
  }
}

.error-modal {
  z-index: 9999;
  .modal-card-head {
    background-color: $red;
  }

  .modal-card-title {
    color: $white;
  }

  .modal-card-body {
    background-color: $red;
  }

  .error-message {
    color: $white;
  }

  .error-confirm-button {
    border: unset;
    margin-top: 1.5rem;
    color: $black;
  }

  // To override the default bulma mobile modal treatment to show centered
  // and not fullscreen
  @include mobile {
    flex: unset;
    width: 90%;

    .modal-card-body {
      flex-grow: unset;
      flex-shrink: unset;
    }
  }
}

.thanks-modal {
  .option-group-subhead {
    color: grey;
    padding: 10rem;
  }
}

.modal-match-height {
  height: 25rem;
}

.modal-summary {
  height: 22rem;
  padding-top: 1.5rem;

  p {
    @include text-regular;
    line-height: 1rem;
    font-size: 1rem;

    margin-bottom: 1.2rem;
  }
}

.annual-match-modal {
  .modal-card-head {
    align-items: unset;
  }

  .modal-head-image {
    padding: 0 0.5rem 0 0;
  }

  .option-group {
    border-bottom: 1px solid $lightGrey;
    padding-top: unset;
  }

  .input {
    border: 0.5px solid;
    border-color: grey;
  }
}

.create-match-modal {
  font-family: Agenda-Bold, sans-serif;
  .modal-card-head {
    background: $offWhite2;
    border-bottom-width: 0;

    p {
      @include text-title;
      text-transform: uppercase;
      color: $baseColorDark;
      margin-top: 5px;
    }
  }

  .modal-card-foot {
    background: $offWhite2;
    border-top-width: 0;
  }

  .modal-card-body {
    background-color: $white;
    padding-left: 2rem;
  }

  .option-group {
    padding-bottom: 0;

    .option-info {
      font-size: 0.9rem;
      padding: 0rem;
    }
  }

  .event-match-details {
    .details-container {
      padding-left: 3rem;
      padding-right: 4rem;

      .details-item {
        padding-bottom: 5px;
        margin-bottom: 2rem;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $lightGrey;
      }
    }
  }

  .option-group-subhead {
    margin-top: unset;
    margin-bottom: unset;
  }

  .amount-selector {
    margin-left: 1rem;
  }

  .name-input {
    display: flex;
    border-bottom: solid;
    border-bottom-color: $black;
    border-bottom-width: 1px;
    margin-top: 0.8rem;
    margin-bottom: 1.2rem;
    width: 60%;

    .input-title {
      font-size: 0.7rem;
      color: $darkGrey;
      width: fit-content;
    }

    input {
      border: unset;
      padding: 5px;
      @include text-regular;
      font-size: 1rem;
    }
  }

  .summary-balance {
    background-color: $lightGrey;
    padding: 1rem;
    margin-left: 3rem;
    margin-right: 5rem;

    .balance-header {
      @include text-bold;
      margin-bottom: 1rem;
    }

    .summary-error {
      color: $brand;
    }

    .summary-funding {
      border-bottom: solid;
      border-bottom-color: $black;
      border-bottom-width: 1px;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .summary-title {
      @include text-regular;
      margin-top: 1px;
      margin-bottom: 0;
    }

    .summary-error-message {
      @include text-bold;
      color: $brand;
    }
  }

  .custom-amount {
    @include text-regular;
    font-size: 0.7rem;
    color: $grey;
  }

  .match-title {
    @include text-regular;
    line-height: 1rem;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .limit-input {
    text-align: center;
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  .fund-info {
    @include text-regular;
    line-height: 1.1rem;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }

  .match-selection {
    @include text-regular;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.9rem;

    margin-left: 1rem;

    input {
      margin-right: 8px;
    }

    label {
      margin-bottom: 5px;
    }
  }
}

.add-funds-modal {
  z-index: 100;
  .form-container {
    margin-top: 1rem;

    .bg-dark-gray {
      margin-left: -1rem;
      margin-right: -1rem;
    }

    input[type='checkbox'] {
      height: 1rem;
    }

    .option-group {
      padding-bottom: 0;
    }

    .comment-field {
      margin-bottom: 1rem;
    }

    .check-description {
      margin-left: 1rem;

      .address {
        margin-left: 1rem;

        &:first-of-type {
          margin-top: 1rem;
        }

        &:last-of-type {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .paypal-button {
    margin-bottom: 0;
    margin-left: 1rem;
  }

  .plaid-button {
    margin-left: 1rem;
  }

  .button-row {
    .button {
      margin: 1rem;
    }
  }
}

.charity-details {
  .modal-card-title {
    font-size: 1.25rem;
    @include text-medium;
  }

  .charity-avatar {
    margin-right: 1rem;

    .avatar {
      height: unset;
    }

    a {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      line-height: 100%;
      font-size: 0.8rem;
      @include text-medium;
    }
  }

  .charity-header {
    margin-bottom: 0.75rem;

    .charity-name {
      @include text-bold;
      font-size: 1.25rem;
    }

    .charity-details {
      font-size: 0.75rem;
      color: $mediumGrey;
    }
  }

  .charity-stats-container {
    font-size: 1rem;
    margin: 1rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    flex: 1;

    @include tablet {
      margin: 2rem 0;
    }

    .charity-stat {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0.5rem 0;
      margin: 1rem 0;
      border-right: 1px solid $lightGrey;

      &:last-of-type {
        border-right: 0;
      }

      @include tablet {
        padding: 0;
        margin: 0;
      }

      .charity-stat-label {
        display: flex;
        @include text-bold;
      }
    }
  }

  .charity-contact {
    .contact-label {
      @include text-bold;
      margin-bottom: 0.5rem;
    }
    .contact-row {
      flex-direction: column;
      display: flex;
      flex: 1;

      @include tablet {
        flex-direction: row;
      }

      .contact-column {
        overflow: hidden;
        font-size: 0.8rem;
        flex: 1;
        margin: 0 0.5rem 0.5rem 0;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .contact-url {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .modal-card-foot {
    justify-content: flex-end;
  }
}

.user-search-modal {
  .modal-card-head {
    height: 6rem;
    padding: 1.8rem 0 1.2rem 4rem;
    background-color: $support;

    .modal-card-title {
      @include text-title;
      font-size: 2.2rem;
      text-transform: uppercase;
      color: $white;
    }
  }
  .modal-card-body {
    min-height: 21rem;
    padding: 3rem 3rem 0 3rem;
    overflow: hidden;
  }

  .email-input {
    padding: 0.5rem;

    .envelope-icon {
      margin: auto 0.5rem auto 0;
    }

    input {
      height: 2.6rem;
      padding: 4px 8px;
      border: 1px solid hsl(0, 0%, 80%);
      outline: none;
      outline-style: none;
      box-shadow: none;

      &:focus {
        border: 2px solid $brand;
        outline: none;
        outline-style: none;
        box-shadow: none;
      }
    }
  }

  .modal-card-foot {
    justify-content: flex-start;
    background-color: $white;
    border: none;
    padding: 3rem;
  }
}

.checkout-with-gift-modal {
  &.checkout {
    background: none;
    width: unset;
    max-width: unset;
    margin: 0;
  }

  .charity-avatar {
    margin-top: -0.6rem;
    width: 4rem;
    height: 4rem;
    position: relative-;
  }

  .delete {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0;
  }

  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-card {
    background-color: white;
    margin-top: 108px;
    border-radius: 5px;

    @include tablet {
      margin: auto;
    }

    .modal-card-body {
      border-radius: 5px;
      padding: 0;

      .checkout-details {
        padding: 1rem;

        .form-container {
          .input-group {
            border-top: unset; // bulma override

            textarea::-webkit-input-placeholder {
              color: $lightGrey;
            }

            textarea:-moz-placeholder {
              /* Firefox 18- */
              color: $lightGrey;
            }

            textarea::-moz-placeholder {
              /* Firefox 19+ */
              color: $lightGrey;
            }

            textarea:-ms-input-placeholder {
              color: $lightGrey;
            }

            textarea::placeholder {
              color: $lightGrey;
            }

            .details-title {
              @include text-title;
              font-size: 1.6rem;
              color: $brandFocusLight;
              text-transform: uppercase;
              margin-bottom: 1rem;
            }

            .message {
              background-color: $white;
              resize: none;
              border: 1px solid $lightGrey;
              border-radius: 5px;
              padding: 0.7rem;
              max-width: 100%;
              color: $black;
            }

            .legal-text {
              color: $grey2;
              font-size: 0.6rem;
              margin-bottom: 1.5rem;

              a {
                color: $grey2;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }

      .hero {
        background-color: $support;
        padding: 3rem 4rem;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1rem;

        .hero-image {
          width: 160px;
          height: 90px;
          display: flex;
        }

        .hero-details {
          margin-left: 1.25rem;

          .hero-title {
            @include text-title;
            text-transform: uppercase;
            line-height: normal;
            font-size: 1.75rem;
            flex: 1;
            color: white;
          }

          .hosted-by {
            color: $white;
            @include text-title;
            font-size: 1.25rem;
            text-transform: uppercase;
            margin-top: 5px;
            margin-bottom: 0.75rem;
          }

          .hero-location {
            @include text-title;
            font-size: 1rem;
            flex: 1;
            color: white;
            margin-left: 1rem;
            text-transform: uppercase;
          }

          .hero-description {
            font-size: 0.7rem;
            flex: 1;
            color: white;
          }
        }
      }

      .charity-list {
        padding: 1rem 0;
        background-color: #e0e0e0;
        flex: none;
      }
    }

    .email-input {
      .input-margin-bottom {
        margin-bottom: 0;
      }
    }
  }

  .footer-button {
    flex: 1;
    @include tablet {
      flex: none;
    }
  }
}

.modal-mini {
  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-card {
    margin-top: $navbar-height-mobile;
    border-radius: $radius-small;

    @include tablet {
      margin-top: $navbar-height;
      width: 450px;
      margin: auto;
    }
  }

  .modal-card-body-mini {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    overflow-wrap: break-word;
    word-break: break-word;
    overflow-y: scroll;
    padding-bottom: 3rem;

    .hero {
      background-color: $support;
      min-height: 10rem;
      justify-content: flex-end;

      &.hero-image {
        height: 56vw;
        background-size: cover;
        background-position: center;

        @include tablet {
          width: 450px;
          height: 234px;
        }
      }
    }

    .delete {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 0;
    }

    .body-text {
      margin: 1rem;
    }

    .event-image {
      width: 160px;
      height: 90px;
    }
  }

  .charity-details {
    .charity-avatar {
      margin: 0;

      .avatar {
        margin: 1rem;
      }
    }

    .charity-header {
      text-align: left;
      font-size: 1rem;
    }

    .charity-name {
      @include text-title;
      text-transform: uppercase;
      font-size: 1.5rem;
      margin-bottom: -0.25rem;
    }

    .charity-location {
      @include text-medium;
      color: $darkGrey;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    .charity-details {
      @include text-regular;
      color: $darkGrey;
      font-size: 0.75rem;
    }

    .charity-description {
      @include text-regular;
      margin-top: 1rem;
      font-size: 1rem;
    }
  }
}

.checkout-modal {
  .gift-avatar-container {
    .avatar.md {
      height: 72px;
      width: 72px;
      border-radius: 999px;
    }
  }

  .ReactCrop__child-wrapper {
    height: 100%;
  }
}
