.sortable-table {
    display: grid;

    .sortable-table-col {
        @include text-agenda-regular;
        font-size: 12px;
        line-height: 14.4px;
        color: $black;
        display: flex;
        column-gap: 5px;
        border-bottom: 1px solid $lightGrey;
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: nowrap;
        cursor: pointer;
        user-select: none;

        &:not(:last-child) {
            padding-right: 16px;
        }
    }
}