.branded-banner-wrapper {
  background-color: $lightPurple;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 23rem;
  width: 100%;

  .banner-background-image {
    position: absolute;
    height: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    filter: brightness(40%) saturate(100%);
  }

  .banner-headline {
    display: flex;
    justify-content: center;
    color: $white;
    text-transform: uppercase;
    @include text-title;
    font-size: 40px;
    z-index: 10;
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  .container {
    width: 100%;

    .banner-icon-wrapper {
      @include max-width-default;
      position: relative;
      width: 100%;
      margin: 0 auto 2rem auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include mobile {
        width: 100vw;
      }

      @include tablet {
        width: 100vw;
      }

      @include desktop {
        width: 100%;
      }

  

      .banner-icon {
        z-index: 1000;
        max-height: 64px;
      }
    }
  }

  .glide {
    .glide__slides {
      align-items: center;

      .glide__slide {
        text-align: center;
      }
    }
  }
}
