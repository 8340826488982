.select-wrapper {

  .select-header {
    border: 1px solid $grey3;
    border-radius: 0.5rem;
    height: 3rem;
    display: flex;
    align-content: flex-end;
    @include text-regular;
    font-size: 1rem;
    color: $textGrey;


    .select-header-placeholder {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }

    .select-dropdown-chevron {
      display: flex;
      align-items: center;
      justify-self: flex-end;
      margin: auto 1rem auto auto;
      background-color: $lightGrey;
      height: 2rem;
      width: 2rem;
      justify-content: center;
      border-radius: 0.5rem;
    }

  }

  .select-list {
    display: none;
  } 

  &.dropdown-active {

    .select-header {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }

  .select-list {
    display: flex;
    flex-direction: column;
    max-height: 15rem;
    height: 100%;
    overflow-y: scroll;
    border: 1px solid $grey4;
    cursor: pointer;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari */
    }
    

    .select-list-item {
      width: 100%;
      height: 2rem;
      padding: 0.4rem 1rem 1.7rem 1rem;
      background-color: transparent;
      border-radius: 0;
      border-bottom: 0.5px solid $grey3;
      border-top: unset;
      border-left: unset;
      border-right: unset;
      text-align: left;
      @include text-regular;
      font-size: 1rem;
      color: $textGrey;
      cursor: pointer;

      &:hover {
        background-color: $lightGrey2;
        font-weight: 700;
        color: $black;
      }
      
    }
  }
}
}