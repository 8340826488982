.payment-option-selector {
  padding: 1.75rem 0 !important;
  display: flex;
  flex-direction: column;

  input[type='radio'] {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .payment-option {
    margin-left: 0.25rem;
    margin-bottom: 1rem;
  }

  .payment-option-disabled {
    margin-left: 0.25rem;
    margin-bottom: 1rem;
    color: $grey50;
  }

  .fee-description {
    background-color: unset;
    padding: 0 0 8px 1rem;
    @include text-regular;
    color: $darkGrey;
  }

  .fee-description-no-element {
    background-color: unset;
    padding: 0 0 0 1rem;
    @include text-regular;
    color: $darkGrey;
  }

  .payment-method-details {
    margin: 0 1rem 1rem 1rem;

    .checkout-input {
      margin-top: 0.5rem;
      margin-bottom: 0;
      display: flex;
      flex: 1;
      flex-direction: column;

      input {
        border-bottom: 1px solid $support;
        margin-left: -0.25rem;
      }
    }
  }
}
