.charity-admin {
  display: flex;
  background-color: white;

  .charity-admin-body {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  .charity-admin-title {
    @include text-agenda-bold;
    font-size: 20px;
    line-height: 20px;
  }

  .charity-balance-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    .charity-button-container {
      display: flex;
      gap: 24px;
    }

    @include mobile {
      gap: 20px;
      flex-direction: column;

      .charity-button-container {
        gap: 20px;
      }
    }
  }

  .charity-pending-balance-container {
    padding: 20px;
    border: 1px solid $lightGrey;
    border-radius: 5px;
    flex-grow: 1;

    .charity-pending-balance-amount {
      @include text-agenda-bold;
      font-size: 31px;
      line-height: 37.76px;
      color: $black;
    }

    .charity-pending-balance-text {
      @include text-agenda-regular;
      font-size: 13px;
      line-height: 15.6px;
      color: $black70;
    }
  }

  .charity-admin-thank-all {
    @include text-agenda-bold;
    border-radius: 6px;
    width: 84px;
    height: 34px;
    background-color: $brand;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    padding-top: 4px;
    border: none;
  }

  .charity-admin-download {
    @include text-agenda-regular;
    border: none;
    cursor: pointer;
    color: $black;
    display: flex;
    column-gap: 5px;
    background-color: transparent;
    margin-top: auto;
    margin-bottom: auto;

    span {
      font-size: 13px;
      line-height: 15px;
    }
  }
}
