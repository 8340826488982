.checkout-confirm-modal {
  align-items: center;
  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-card {
    justify-content: center;
    max-height: 739px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    flex-grow: unset;
    min-height: 519px;

    @include mobile {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .modal-card-head {
      background-color: black;
      height: 137px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      @include mobile {
        padding-right: 34px;
        padding-left: 34px;
        height: 83px;
      }

      .modal-card-title {
        @include text-agenda-black;
        line-break: auto;
        white-space: normal;
        color: $white;
        font-size: 25px;
        line-height: 30px;
        text-transform: uppercase;
        margin-left: 2rem;
        margin-top: 4px;
        flex-shrink: 1;

        @include mobile {
          margin-left: 0;
        }
      }
    }

    .modal-card-body {
      @include text-agenda-regular;
      font-size: 1rem;
      padding-left: 64px;
      padding-right: 64px;
      padding: 64px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .cauze-button-donate {
        margin-top: 56px;
        border-radius: 5px;
      }

      .modal-card-flex {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;

        .flex-column {
          margin-top: 1rem;
        }
      }

      @include mobile {
        flex-grow: unset;
        padding: 0px 34px;
        height: 436px;
        padding-bottom: 67px;
      }

      .line-item {
        justify-content: space-between;
        margin-bottom: 0.5rem;
        font-size: 20px;

        &:first-of-type {
          margin-top: 0.5rem;
        }

        .dotted-line {
          flex: 1;
          overflow: hidden;
          margin: 0rem 0.5rem;
        }
      }
    }

    .modal-card-foot {
      justify-content: center;
      padding: 2rem 3.25rem 2rem 3.25rem;
      background-color: $white;
      border: none;

      .cauze-button-container {
        width: 100%;

        .cauze-button {
          width: 100%;
        }
      }
    }
  }

  .target-name {
    @include text-agenda-bold;
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 1rem;

    @include mobile {
      margin-bottom: 0px;
    }
  }
}