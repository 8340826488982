.cauzes-view {
  display: flex;
  flex: 1;

  .cauzes-container {
    background: $white;
    padding: 1rem;

    .cauze-dash {
      padding: 1rem;
      background: $support;
      color: $white;
      margin: 0 0 1rem 0;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include tablet {
        flex-direction: row;
      }

      .dash-item {
        @include text-title;
        font-size: 1.5rem;
        line-height: 2rem;

        .dash-number {
          font-size: 2rem;
        }

        @include tablet {
          line-height: 2rem;
          padding-right: 1rem;
          margin-right: 1rem;
          border-right: 1px solid $brand;

          &:first-of-type {
            border-right: 0;
            padding-right: 0;
          }

          &:last-of-type {
            padding-right: 0;
            margin-right: 0;
            border-right: 0;
          }
        }
      }
    }

    .cauze-list {
      flex-wrap: wrap;
      margin-right: -1rem;
      margin-bottom: -1rem;

      .cauze-item {
        flex: 1 0 20rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        position: relative;
        max-height: unset;

        .flex-row {
          align-items: flex-start;
        }

        .cauze-image {
          min-width: 100%;
          background-size: cover;
        }

        .event-ribbon {
          @include text-title;
          font-size: 1.5rem;
          margin-top: 1rem;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $white;
          border-width: thin thin thin 0;
          border-color: $brand;
          border-style: solid;
          color: $brand;
          padding: 0.25rem 1.5rem;
        }

        .cauze-details {
          padding: 1rem;
          border: solid;
          border-width: 1px 1px 1px 1px;
          border-color: $grey;

          .cauze-title {
            font-size: 1.5rem;
          }
        }

        .dropdown {
          align-self: flex-start;
        }

        .button {
          color: $brand;
          font-size: 1rem;
          border: 0;
          padding-right: 0;
          padding-left: 1rem;
          margin: 0;
        }

        .cauze-share {
          background-color: $support;
          padding: 1rem;

          .share-text {
            color: $white;
            margin-bottom: 0.5rem;

            img {
              width: 1rem;
              margin-right: 0.5rem;
            }
          }

          input {
            margin: 0 1rem;
            flex: 1;
            align-self: stretch;
            text-align: center;
            font-size: 1rem;
            padding: 0.25rem 0;
          }
        }
      }

      .hidden-flex {
        content: "";
        flex: 1 0 20rem;
        visibility: hidden;
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
