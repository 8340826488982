.cauze-tab-button {
  height: 40px;
  width: 100%;
  border-color: $grayscaleLightGray2;
  cursor: pointer;
  font-size: 15px;
  background-color: $grayscaleLightGray2;
  color: $black;
  transition: background-color 0.3s, color 0.3s;

  &--active {
    background-color: $brandNew;
    color: $white;
    border-color: $brandNew;
  }

  &:focus {
    outline: none;
  }
}
