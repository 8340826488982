.search-container {
  display: flex;
  height: 42px;
  border-radius: 5px;
  position: relative !important;

  .search-input-location {
    border: none;
    padding: 0;
    width: 100%;
    color: #1a1a1a;
    font-size: 16px;
    background-color: white !important;

    &::placeholder {
      color: #94a3b8;
      @include mobile {
        font-size: 14px;
      }
      @media (max-width: 380px) {
        font-size: 13px;
      }
    }
    &:focus,
    &:focus-visible {
      border: none;
      outline: none;
    }
    @include mobile {
      font-size: 14px;
    }
    @media (max-width: 380px) {
      transform: translateY(0px);
      font-size: 13px;
    }
  }
  &.user-search-container {
    display: flex;
    flex-direction: row;

    .search-icon {
      margin: auto 0.5rem auto 0;
    }
  }

  .search-result-container {
    .search-result-row {
      padding: 1rem 0;
      border-bottom: 1px solid $grey50;

      .avatar {
        margin-right: 0.5rem;
      }

      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .result-detail {
        @include text-regular;
        @include text-10pt;
        color: $grey;
      }
    }
  }
}
