.share-modal {
  @include text-agenda-regular;
  align-items: center;
  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-card {
    justify-content: center;
    border-radius: 4px;

    @include mobile {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .modal-card-head {
      display: none;
    }

    .modal-card-body {
      @include text-regular;
      font-size: 1rem;
      border: none;
      padding: 16px 16px 32px 32px;
      flex-grow: 0;
      border-radius: 4px;

      .share-link {
        padding-left: 12px;
        padding-top: 16px;
        padding-bottom: 14px;
        border: 1px solid rgba(136, 136, 136, 0.5);
        border-radius: 4px;
        width: 100%;
        color: $grey6;
        font-size: 15px;
      }

      .share-link-container {
        position: relative;

        img {
          position: absolute;
          top: 11px;
          right: 7px;
          cursor: pointer;
        }

        .share-link-copied {
          height: 16px;
          text-align: center;
        }
      }

      .share-link-title {
        @include text-agenda-bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
      }

      .share-link-subtitle {
        color: $grey6;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 30px;
      }

      .share-link-x-container {
        margin-bottom: 8px;
        position: relative;
        height: 16px;

        .delete {
          margin: 0;
          padding: 0;
        }
      }
    }

    .modal-card-foot {
      justify-content: flex-start;
      padding: 2rem 2rem 2rem 3.25rem;
      background-color: $white;
      border: none;

      @include mobile {
        padding-left: 1rem;
      }

      .cauze-button-container {
        .cauze-button {
          margin-left: 0;
        }
      }
    }
  }
}
