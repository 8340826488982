.user-info-input-field-container {
  width: 100%;

  .user-info-input-field-title {
    @include text-agenda-regular;
    color: $textGrey;
    font-size: 14px;
  }

  .user-info-input-field {
    width: 100%;
    box-shadow: none;
    border-style: none;
    border: 1px solid $grey3;
    border-radius: 5px;
    height: 2.5rem;
    text-indent: 0.5rem;

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $textGrey;
      font-size: 1rem;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $textGrey;
      font-size: 1rem;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $textGrey;
      font-size: 1rem;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $textGrey;
      font-size: 1rem;
    }

    &.error {
      border: 1px solid red;
      background-color: $error-light-red;
    }
  }
}
