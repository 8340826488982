.header {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  align-items: center;
  justify-content: space-between;

  @include tablet {
    padding: 2rem 2rem 1.75rem 2rem;
  }

  .event-image {
    padding: 0.5rem 0 0 1rem;
  }

  h1 {
    @include text-title;
    color: $support;
    hyphens: auto;
    font-size: 1.5rem;
    margin: 0;
    line-height: 1.2;
    padding-top: 0.25rem;

    @include tablet {
      font-size: 2rem;
    }

    &.long-title {
      font-size: 1rem;
    }
  }

  .subtitle {
    align-items: center;

    h2 {
      font-size: 0.75rem;

      @include tablet {
        font-size: 1rem;
      }
    }

    a {
      line-height: 0;
    }

    .logo-inline {
      width: 3rem;
      margin-left: 0.25rem;

      @include tablet {
        width: 4rem;
      }
    }
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .avatar {
      margin-right: 1rem;
    }
  }

  .share {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;

    .share-logo {
      width: 1.5rem;
    }
  }
}
