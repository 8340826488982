.navbar {
  background-color: black;
  flex: 1;
  display: flex;
  justify-content: center;
  height: 76px;
  min-height: 0px;

  .app-banner {
    height: 2rem;
    background-color: $brand;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p,
    a {
      color: $white;
    }

    a {
      @include text-bold;
      text-transform: uppercase;
    }
  }

  .navbar-content {
    @include desktop {
      flex-direction: row;
      display: flex;
    }

    .navbar-brand {
      @include cauze-gradient;
      background-color: black;

      .navbar-item {
        padding: 0px;
      }

      .cauze-gradient {
        background: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .app-logo {
        padding: 0;

        @include tablet {
          padding: 0 1rem;
        }

        &.app-logo-cobrand {
          object-fit: contain;
          object-position: 0 0;
          max-height: 1.5rem;
          padding: 0.25rem 0;

          @include tablet {
            max-height: 2rem;
            padding: 0 0.5rem 0 1rem;
          }
        }
      }

      .cobrand {
        display: flex;
        flex-direction: column;

        @include tablet {
          flex-direction: row;
          align-items: flex-end;
        }

        .powered-by {
          @include text-regular;
          display: flex;
          align-items: center;
          font-size: 0.7rem;
          text-transform: none;
          color: rgba(255, 255, 255, 0.7);

          .app-logo {
            padding: 0;
            width: 3rem;
          }
        }
      }
    }
  }

  .navbar-menu {
    @include cauze-gradient;
    background-color: black;

    .navbar-item {
      padding: 0px;
    }
  }

  .context-warning {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
  }

  .login-prompt {
    @include text-regular;
    text-transform: none;
    font-size: 1rem;
    color: $white;
    margin-right: 1rem;
  }

  .navbar-item {
    @include text-title;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: $mediumGrey;
  }

  .navbar-flex {
    flex: 1;
  }

  .gift-container {
    background-color: $supportFocus;
    position: relative;
    width: 85px;
    padding: 0;

    @include desktop {
      width: 75px;
      margin-right: 1.5rem;
    }

    .gift-card {
      background-color: $supportFocus;
      z-index: 100;
      width: 85px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      cursor: pointer;

      .gift-icon {
        width: 22px;
        height: 22px;
      }

      .gift-amount {
        color: $white;
        font-size: 1.5rem;
        line-height: 0;
      }

      @include desktop {
        flex-direction: column;
        width: 75px;
        top: 0;
        padding: 0;

        .gift-icon {
          margin: 1rem 0 1rem 0;
          width: 25px;
          height: 25px;
        }

        .gift-amount {
          font-size: 2rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .user-dropdown {
    margin-left: auto;

    .dropdown-divider:first-of-type {
      margin-top: 0;
    }

    .dropdown-trigger {
      align-items: center;

      button {
        @include text-title;
        font-size: 1.5rem;
        color: white;
        max-width: 12rem;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: 0;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: right;

        @include from($tablet) {
          color: $primary;
          max-width: 20rem;
        }

        .icon {
          height: 1rem;
        }

        .user-container {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
        }

        .user-name {
          font-size: 1.25rem;
          display: flex;
          white-space: normal;
          line-height: 1rem;
          text-transform: none;
          text-overflow: ellipsis;

          @include from($tablet) {
            color: $primary;
            overflow: hidden;
            white-space: nowrap;
            line-height: 1.25rem;
            font-size: 1.5rem;
          }
        }

        .user-balance {
          display: none;
          @include text-title;
          color: $mediumGrey;
          font-size: 1rem;
          line-height: normal;
          margin-bottom: -0.3rem;

          @include from($tablet) {
            display: inline;
          }
        }

        .avatar {
          height: 50px !important;
          width: 50px;
          max-height: 50px;
          border-radius: 9999px;
        }
      }
    }

    .dropdown-menu {
      text-transform: none;

      .dropdown-status {
        border-radius: 4px 4px 0 0;
        background-color: $mediumGrey;
        color: white;
        margin-bottom: 0.5rem;
        padding: 1rem 1rem;

        .dropdown-status-title {
          @include text-title;
          font-size: 1.5rem;
          color: $support;
        }

        .dropdown-status-subhead {
          @include text-title;
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        .user-action-button {
          background-color: $brand;
          border: 0;
          color: white;
          display: flex;
          margin-top: 1rem;
          flex: 1;
          margin-left: 0.25rem;
          margin-right: 0.25rem;

          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      .dropdown-content {
        .dropdown-item {
          padding: 5px 10px !important;
        }

        max-height: 90vh;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
        max-width: 20rem;
        min-width: 20rem;

        margin-right: 4px;

        .dropdown-title {
          white-space: normal;
          @include text-bold;
          align-items: center;
          color: $grey;
          cursor: auto;
          margin-left: 1rem;
          margin-bottom: 0.2rem;

          .avatar {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
            justify-content: center;
          }
        }

        a.dropdown-item,
        button.dropdown-item {
          white-space: normal;
          @include text-bold;
          align-items: center;
          .avatar {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
            justify-content: center;
          }
        }

        a.dropdown-item-highlight {
          color: $brand;
          text-decoration: underline;
        }

        a.dropdown-item-highlight:hover {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }
}

.dashboard-navbar {
  display: grid;
  grid-template-columns: 1fr 34rem 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.user-dropdown {
  .avatar {
    height: 2.125rem;
    width: 2.125rem;
    border-radius: 9999px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .is-active {
    .avatar {
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  .user-dropdown-menu {
    @include mobile {
      width: calc(100vw - 22px);
    }
  }
}

.activity-dropdown {
  .avatar {
    height: 26px;
    width: 26px;
  }

  .dropdown-content {
    height: 80vh;

    @include mobile {
      height: calc(100vh - 60px);
    }
  }

  .activity-list-container {
    padding-right: 1rem;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &:hover {
      padding-right: 0px;

      /* width */
      &::-webkit-scrollbar {
        width: 1rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $grey8;
        border: 0.25rem solid transparent;
        border-radius: 10px;
        background-clip: content-box;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $grey75;
        border: 0.25rem solid transparent;
        border-radius: 10px;
        background-clip: content-box;
      }
    }
  }
}

.user-avatar-btn {
  .avatar {
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 9999px;

    @media (max-width: 1024px) {
      height: 2rem;
      width: 2rem;
    }
  }
}
