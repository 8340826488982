.profile-modal {
  &.is-active {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-card {
    position: relative;
    border-radius: 10px;
    width: 335px !important;
    max-height: fit-content !important;
    @media (max-width: 350px) {
      width: 300px !important;
    }

    .modal-card-body-profile-modal {
      position: relative;
      background-color: #fff;
      padding: 0;

      .close-icon {
        svg {
          transition: opacity 0.2s ease-in-out;

          &:hover {
            opacity: 0.8;
          }
        }
      }
      .body-container {
        .profiles-image-container {
          .profile-header-hero {
            position: relative;

            .avatar-container {
              position: absolute;
              bottom: -20px;
              left: 50%;
              transform: translateX(-50%);
              z-index: 2;
              border: 2px solid #ce410d;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 150px;
              overflow: hidden;
            }
          }

          .hero {
            height: 130px;
            background-position: center;
            background-size: cover;
          }

          .empty-hero {
            background-color: $support;
            display: flex;
          }
        }

        .user-info {
          text-align: center;

          .read-more-button {
            color: #1b2031;
            text-decoration: underline;
            cursor: pointer;
            font-size: 13px;
          }
        }

        .user-stats {
          display: flex;
          justify-content: space-around;

          .stat {
            text-align: center;
            display: flex;
            flex-direction: column;
          }
        }

        .action-buttons {
          display: flex;
          flex-direction: column;
          margin-top: 22px;
          margin-bottom: 20px;

          gap: 10px;

          .follow-btn,
          .profile-btn {
            background-color: #fff;
            border: 1px solid #949494;
            padding: 0 20px;
            width: 100%;
            height: 48px;
            border-radius: 8px;
            cursor: pointer;
            font-size: 16px;

            line-height: 20px;
            span {
              transform: translateY(2px);
            }
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
