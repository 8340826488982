.dashboard-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 0;

  @media (max-width: 1024px) {
    margin: 1rem 0;
  }
  @include from($tablet) {
    margin-bottom: 1rem;
  }

  @include from($desktop) {
    margin-right: unset;
    margin-left: unset;
  }

  .subtitle {
    @include text-title;
    margin-bottom: 0.5rem;
  }

  .dashboard {
    flex: 1;
    align-self: stretch;
    flex-direction: column;

    @include from($tablet) {
      flex-direction: row;
    }

    .dashboard-item {
      display: flex;
      flex: 1;
      background-color: #fff;
      border-radius: 3px;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

      @include from($tablet) {
        margin-right: 1rem;
        margin-bottom: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:only-child {
        @include from($tablet) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          .dashboard-content {
            flex-direction: row;
          }

          .dashboard-icon {
            font-size: 1.5rem;
            padding: 0.5rem;
          }

          .dashboard-details {
            flex: 1;
          }

          .dashboard-item-actions {
            flex: unset;
            align-items: center;

            .button {
              margin-top: 0;
              margin-right: 0.5rem;
            }
          }
        }
      }

      .dashboard-link {
        display: flex;
        flex: 1;
        padding: 0.5rem;
        font-size: 1rem;
        margin-right: 3rem;
      }

      .dashboard-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.5rem;
        padding: 0.5rem;
        color: $black;
        margin-right: 0.5rem;

        @include from($tablet) {
          font-size: 2rem;
        }

        img {
          width: 30px;
          height: 30px;

          @include from($tablet) {
            width: 32px;
            height: 32px;
          }
        }
      }

      .dashboard-content {
        flex-direction: column;
        display: flex;
        flex: 1;
      }

      .dashboard-item-title {
        font-family: Agenda-Bold, sans-serif;
        line-height: 1;
        font-size: 20px;
        color: $black;
      }

      .dashboard-item-body {
        font-family: Agenda-Regular, sans-serif;
        margin-top: 2px;
        font-size: 14px;
      }
      .dashboard-item-header {
        @include text-title;
        font-size: 1.5rem;
        padding-bottom: 1rem;
      }

      .dashboard-item-actions {
        flex: 1;
        align-items: flex-end;
        flex-wrap: wrap;

        .button {
          margin-top: 0.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.dashboard-layout-body {
  height: calc(100vh - 76px);

  @media (max-width: 1024px) {
    height: 100vh;
  }

  .dashboard-grid {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 34rem 1fr;

    &.full {
      grid-template-columns: 1fr 62.5rem 1fr;
    }

    &.screen {
      grid-template-columns: 20rem auto;
    }

    @media (max-width: 1024px) {
      grid-template-columns: 100vw !important;
    }
  }
}
