$container-offset: (2 * $gap) !default

.container
  flex-grow: 1
  margin: 0 auto
  position: relative
  width: auto
  +desktop
    max-width: $desktop - $container-offset
    &.is-fluid
      margin-left: $gap
      margin-right: $gap
      max-width: none
  +until-widescreen
    &.is-widescreen
      max-width: $widescreen - $container-offset
  +until-fullhd
    &.is-fullhd
      max-width: $fullhd - $container-offset
  +widescreen
    max-width: $widescreen - $container-offset
  +fullhd
    max-width: $fullhd - $container-offset
