.not-found-view {
  display: flex;
  flex: 1;
  flex-flow: column;
  width: 100vw;
  background-color: $white;


  .section {
    max-width: 1100px;
    margin: 0 auto;

  .not-found-message {
    line-height: 2rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    @include text-bold;
  }

  ul {
    list-style: disc;

    li {
      margin-left: 2rem;
    }
  }
}

.footer-new {
  background-color: $offWhite5;
}
}
