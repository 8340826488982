.fundraiser-news-container {
  width: 100%;


  .news-post-container {
    padding: 3rem 1rem;
    border-bottom: 1px solid $textGrey;
    transition: padding 0.4s ease-in-out;


    &.is-closed {
      padding: 1rem;
      transition: padding 0.4s ease-in-out;
    }

    .news-post-dropdown {
      display: flex;
      justify-content: space-between;
      @include text-agenda-bold;
      font-size: 20px;
      line-height: 24px;
      color: $textGrey;

      .chevron-down {
        @include add-clickable-area;

        &.is-closed {
          transform: rotate(180deg);
        }
      }
    }

    .post-child-container {

      &.is-open {
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
      }

      &.is-closed {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s, padding 0.4s ease-in-out 0.1s;
        padding-top: 0;
        padding-bottom: 0;
      }


      .news-post-content-container {

        .post-title {
          @include text-agenda-black;
          font-size: 50px;
          line-height: 60px;
          color: $trueBlack;
          margin-top: 1rem;
          margin-bottom: 1rem;


          @include mobile {
            font-size: 30px;
            line-height: 36px;
          }
        }

        .post-content {

          .content-paragraph {
            @include text-agenda-regular;
            color: $trueBlack;
            font-size: 15px;
            line-height: 22px;
            margin: 1rem 0;
          }
        }
      }
    }
  }
}