.add-gift-banner {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 640px;
  background-color: $support;
  padding: 2rem;
  cursor: pointer;

  @include tablet {
    flex-direction: row;
    padding: 2rem 0 0 2rem;
  }

  .title {
    @include text-title;
    text-transform: uppercase;
    color: $white;
  }

  p {
    @include text-regular;
    color: $white;
  }

  .banner-image {
    display: none;

    @include tablet {
      display: block;
      width: 300px;
    }
  }

  .button {
    margin-top: 2rem;

    @include tablet {
      margin-top: 0;
    }
  }
}
