.donation-landing {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $white;

  .event-list {
    padding-left: 0px;
    padding-right: 18px;
  }

  //Loading Spinner Formatting Start
  .loading-hero {
    height: 497px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .loading-feed-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @include fullhd {
    // Possibly show grey margin here after 1800px breakpoint
  }

  .cauzes-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .feed-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10rem;
    align-items: center;
  }

  .top-cauzes {
    display: flex;
    width: 100%;
    padding: 2rem 1rem;
    overflow: hidden;
    .swiper {
      width: 539px !important;

      @include mobile {
        width: 100vw !important;
      }
    }
  }

  .feed {
    width: 100%;
    @include mobile {
      padding-right: 16px;
      padding-left: 16px;
    }
    @media (max-width: 380px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .content-container {
    .subtitle {
      margin-bottom: 1rem;
      text-align: center;
      font-size: 1rem;
      color: white;
    }

    .columns {
      margin: 0;
    }

    .charity-search {
      background-image: url("/assets/images/backgrounds/gift-donation-bg-grey.jpeg");
      padding-top: 2rem;
      padding-bottom: 2rem;
      background-size: cover;
      background-position: center;

      .search-title {
        color: $white;
        font-size: 3rem;
      }

      .redeem-title {
        text-transform: uppercase;
        color: $white;
        font-size: 2rem;
      }

      .redeem-button {
        font-size: 1.25rem;
      }

      .hr {
        display: flex;
        align-self: stretch;
        flex: 1;
        @include text-title;
        text-transform: uppercase;
        color: $white;
        font-size: 1.5rem;
        margin-top: 1.5rem;
        border-top: 2px solid white;
        border: 0;
        margin-right: 1rem;
        margin-left: 1rem;

        &::before,
        &::after {
          background-color: white;
          height: 2px;
        }
      }

      a {
        color: $white;
      }
    }

    .recent-feed {
      background-color: $white;

      .feed-title {
        color: $support;
      }
    }
  }
}
