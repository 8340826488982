.user-details {
  @include text-agenda-regular;
  display: flex;
  width: 100%;
  background-color: white;

  h1 {
    @include text-agenda-black;
    font-size: 35px;
    line-height: 100%;
  }

  .user-profile-form {
    background-color: white;
    display: flex;
    width: 100%;

    .save-button {
      color: white;
      border-radius: 8px;
      border: 1px solid white;
      background: black;

      &:hover,
      &:focus {
        background: black;
        border: 1px solid white;
      }
    }

    form {
      padding-left: 4px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;

      @include mobile {
        display: flex;
        flex-direction: column;
      }
    }

    .input-container {
      input,
      textarea {
        border-radius: 8px;
        border: 1px solid $lightBorderGrey;
        padding: 10.5px;
      }

      &.input-container-error {
        input,
        textarea {
          border-color: $error;
        }

        color: $error;
      }
    }

    .input-container-error-message {
      height: 16px;
    }

    .input-title {
      @include text-agenda-bold;
      font-size: 20px;
      line-height: 100%;
      margin-bottom: 11px;
    }

    .user-profile-form-content {
      font-size: 18px;

      .profile-link {
        @include text-agenda-bold;
        font-size: 20px;
        line-height: 100%;
        margin-left: 4px;
      }
    }

    .profile-hero-image-container {
      position: relative;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
    }

    .image-input {
      display: flex;
      column-gap: 20px;

      .profile-image-logo {
        height: 70px;
        width: 70px;
        border-radius: 150px;
      }

      .image-upload-label {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 10px;
        text-transform: uppercase;
        border-radius: 3.812px;
        border: 0.762px solid #000;
        background-color: $offWhite6;
        padding: 9px 12px;
        line-height: 100%;
        cursor: pointer;

        &:hover {
          background-color: $offWhite;
        }
      }

      .profile-hero-image {
        width: 149px;
        height: 49px;
        border-radius: 10px 10px 0px 0px;
        border: 0.5px solid #c9c9c9;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .profile-hero-image-overlay {
        background: rgba(0, 0, 0, 0.35);
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
}
