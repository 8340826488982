.add-funds-sidebar {
    .amount-selector {
        padding: 0px;
        max-width: unset;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;

        &.bg-dark-gray {
            background-color: white;
        }

        .amount-option {
            @include text-agenda-bold;
            text-align: center;
            border: 1px solid black;
            border-radius: 8px;
            margin-right: 0px;
            font-size: 17px;
            line-height: 20.71px;
            padding-top: 8px;
            width: 69px;
            height: 49px;
            cursor: pointer;

            &.is-active {
                background-color: black;
            }
        }

        .amount-custom {
            border: 1px solid black;
            border-radius: 8px;
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 17px;
            padding-right: 17px;
            height: 49px;
            flex: 1;
            max-width: unset;

            &.is-active {
                background-color: black;
                color: white;

                input {
                    &::placeholder {
                        color: white;
                    }
                }
            }

            input {
                &::placeholder {
                    @include text-agenda-bold;
                    color: $black;
                    text-transform: uppercase;
                    text-align: center;
                }

                text-align: center;
            }
        }
    }
}

.add-funds-view {
    display: flex;
    align-items: center;
    width: 100vw;
    flex-direction: column;
    background-color: white;
    padding-top: 48px;

    .add-funds-view-body {
        width: 930px;
        display: flex;
        column-gap: 24px;
        padding-bottom: 211px;

        .add-funds-view-main {
            display: flex;
            row-gap: 24px;
            flex-direction: column;
            width: 544px;

            .add-funds-view-title {
                @include text-agenda-bold;
                font-size: 20px;
                line-height: 20px;
                color: black;
            }
            
            .add-funds-view-desc {
                @include text-agenda-regular;
                font-size: 17px;
                line-height: 20.23px;
                color: black;
            }

            .add-funds-submit {
                @include text-agenda-bold;
                height: 49px;
                border-radius: 8px;
                background-color: $brand;
                color: white;
                font-size: 17px;
                line-height: 20.71px;
                border: none;
                padding-top: 6px;
                cursor: pointer;
            }
        }
    }

    .add-funds-card {
        padding: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        border: 1px solid $lightGrey;
        border-radius: 5px;
        width: 100%;

        .add-funds-card-title {
            @include text-agenda-bold;
            font-size: 17px;
            line-height: 20px;
            color: black;
        }
    }

    .amount-selector {
        padding: 0px;
        max-width: unset;
        width: 100%;
        justify-content: space-between;

        &.bg-dark-gray {
            background-color: white;
        }

        .amount-option {
            @include text-agenda-bold;
            text-align: center;
            border: 1px solid black;
            border-radius: 8px;
            margin-right: 0px;
            font-size: 17px;
            line-height: 20.71px;
            padding-top: 8px;
            width: 69px;
            height: 49px;
            cursor: pointer;

            &.is-active {
                background-color: black;
            }
        }

        .amount-custom {
            border: 1px solid black;
            border-radius: 8px;
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 17px;
            padding-right: 17px;
            height: 49px;

            input {
                &::placeholder {
                    @include text-agenda-bold;
                    color: $black;
                    text-transform: uppercase;
                }
            }
        }
    }

    .balance-total {
        width: 362px;
        height: 93px;

        .balance-total-amount {
            @include text-agenda-bold;
            font-size: 31px;
            line-height: 37.76px;
            color: $black;
        }

        .balance-current-balance {
            @include text-agenda-regular;
            font-size: 13px;
            line-height: 15.47px;
        }
    }
}