.follow-button {
  width: 3rem;
  cursor: pointer;

  img {
    height: 1.25rem;
    width: 1.25rem;
    margin-bottom: 1px;
  }


  .following-text {
    @include text-title;
    color: $brandFocusLight;
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  .follow-text {
    @include text-title;
    color: $grey2;
    font-size: 1.25rem;
    text-transform: uppercase;
  }
}