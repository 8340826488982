.payroll-report {
  .payroll-log {
    background-color: white;
    margin: 1rem;
    padding: 1rem;

    @include from($desktop) {
      margin: 0;
    }

    .file-upload-success {
      background-color: $success;
      padding: 0.25rem 0.25rem 0.25rem 0.5rem;
      border-radius: 0.25rem;
      margin-bottom: 1rem;
      color: white;
    }

    .log-section {
      margin-bottom: 0.25rem;
      padding-bottom: 0.25rem;
      // background-color: $veryLightGrey;
      // padding: 0.25rem;
      border-bottom: 1px solid $veryLightGrey;
    }

    .log-entry {
      margin-bottom: 0.25rem;

      .error {
        color: red;
      }

      .success {
        color: green;
      }
    }
  }
}
