.legal-description {
  margin: 2rem 0 1rem 0.25rem;
  @include text-agenda-regular;
  color: $textGrey;
  font-size: 8px;
  line-height: 13px;

  span {
    @include text-bold;
    cursor: pointer;
    color: $textGrey;
    text-decoration: underline;
  }
}
