.page-header {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;

  @include from($desktop) {
    justify-content: flex-start;
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }

  .title {
    text-transform: uppercase; 
    @include text-title;
    font-size: 3rem;
    margin: 0;
    margin-right: 1rem;
    display: flex;
    flex: 1;
    word-break: unset;
  }

  .button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.page-body {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;

  @include from($desktop) {
    justify-content: flex-start;
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }

  .page-section {
    margin-bottom: 2rem;
  }
}
