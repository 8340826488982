.avatar-list {
    flex: 1;
    margin-bottom: 1rem;
  
    .swiper-slide {
      -webkit-touch-callout:none;
      -webkit-user-select:none;
      -khtml-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      user-select:none;
      -webkit-tap-highlight-color:rgba(0,0,0,0);
    }
  
    @include mobile {
      padding-left: 18px;
      padding-right: 18px;
    }
    
    .scroll-horizontal {
      padding-bottom: 0.25rem;
  
      @include tablet {
        padding-left: 1rem;
      }
    }
  
    .flex-wrap {
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
    }
  
    .avatar-item {
      cursor: pointer;
      overflow: hidden;
      text-align: center;
      color: $grey7;

      .avatar-item-name {
        line-height: 100%;
        font-size: 12px;
      }
    }
  
    .swiper-button-prev, .swiper-button-next {
      display: none;
      color: black;
    }
  
    @media (min-width: 769px) {
      &.show-arrows:hover {
        .swiper-button-prev, .swiper-button-next {
          display: block !important;
        }
      }
    }
  }
  