@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.slider {
  .glide__track {
    padding: 0 2rem;
    margin-right: 0.75rem;
    overflow: hidden;
  }
  .glide__arrows {
    display: flex;
    justify-content: flex-end;

    button {
      height: 2.2rem;
      width: 2.1rem;
      -webkit-tap-highlight-color: transparent;
        .arrow-right, .arrow-left {
          height: 2rem;
          width: 2rem;
          margin-left: -8px;
          margin-top: -3px;
          path {
            stroke: $grey2;
          }
          &:hover {
            path {   
                stroke: $black;       
            }
          }
      }

      border: none;
      margin: 0 3px;
    
    }
  }

  .glide_slides {
    @include max-width-default;
  }
}
