.reset-password-view {
	width: 100vw;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	background-color: $lightGrey;
	justify-content: space-between;
	align-items: center;

	.reset-password-container {
		margin: 1rem;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;

		.reset-password-card {
			background-color: $white;
			height: 350px;
			max-width: 488px;
			width: 100%;
			display: flex;
			flex-direction: column;
			margin-top: 2rem;
			margin-bottom: 2rem;

			.card-header {
				padding: 1rem;
				box-shadow: none;
				font-size: 2rem;
				color: $trueBlack;
				@include text-title;
				text-align: center;
				width: 100%;
				justify-content: center;
			}

			.card-body {
				display: flex;
				flex-direction: column;

				&.error-message-container {
					justify-content: center;
					height: 100%;
					margin-bottom: 50px;

					.error-message {
						padding: 1rem;
						@include text-regular;
						font-size: 1.5rem;
						text-align: center;
					}
				}

				.password-input {
					max-width: 254px;
					width: 100%;
					padding: 0.5rem;
					margin: 1rem auto 1rem auto;
					background-color: transparent;
					border-style: none;
					border: 1px solid $textGrey;
					border-radius: 5px;

					&.second {
						margin-bottom: 0.5rem;
					}
				}

				.password-error {
					@include text-title;
					color: $brandFocusLight;
					font-size: 1rem;
					margin: 0 auto 2rem auto;
					height: 24px;
				}
			}
			.cauze-button-container {
				width: 100%;
				display: flex;
				justify-content: center;

				.cauze-button {
					max-width: 256px;
					width: 100%;
					margin: 0 auto;
				}
			}
		}
	}
}
