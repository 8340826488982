html {
  overflow-y: hidden !important;
  height: 100vh;
  width: 100vw;
}

.inline-block {
  display: inline-block;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.border-outset {
  border-style: outset;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.flex-gap {
  gap: 1rem;
}

.pointer {
  cursor: pointer;
}

.scroll-horizontal {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 2rem);
  margin-right: -15rem;
  margin-left: 1rem;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-more-left,
.scroll-more-right {
  cursor: pointer;
  pointer-events: none;
  touch-action: none;
  position: absolute;
  display: flex;
  align-items: center;
  height: calc(100%);

  button {
    @include text-bold;
    cursor: pointer;
    pointer-events: all;
    touch-action: initial;
    color: $white;
    border-radius: 2rem;
    border-width: 0px;
    border-style: solid;
    height: 2rem;
    width: 2rem;
    text-align: center;
    background-color: $brand;
  }
}

.scroll-more-right {
  padding-right: 0.5rem;
  justify-content: flex-end;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 65%
  );
}

.scroll-more-left {
  z-index: 1;
  padding-left: 0.5rem;
  justify-content: flex-start;
  left: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 65%
  );
}

.border-none {
  border: none !important;
}

.border-blue {
  border: 1px solid blue;
}

.border-red {
  border: 1px solid red;
}

.min-width-reset {
  min-width: 1rem;
}

.text-align-center {
  text-align: center;
}

.padding-responsive {
  padding: 1rem;

  @include desktop {
    padding: 2rem;
  }
}

.max-vw {
  max-width: 100vw;
}

.full-width {
  width: 100%;
}

.left-round-border {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-width: 0px;
  border-style: solid;
}

.right-round-border {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-width: 0px;
  border-style: solid;
}

.pin-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

.pin-top {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.backdrop {
  margin-top: $navbar-height-mobile;

  @include from($tablet) {
    margin-top: $navbar-height;
  }

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.support-block {
  align-items: center;

  @include tablet {
    margin: 2rem auto 0 auto;
  }

  .button {
    color: $white;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}

@mixin max-width-default {
  max-width: 1188px; // Bulma Widescreen breakpoint
}

@mixin width-default {
  width: 1188px; // Bulma Widescreen breakpoint
}

@mixin mobile-narrow {
  @media screen and (max-width: 556px) {
    width: 100vw;
  }
}

@mixin add-clickable-area {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 2em;
  margin: -2em;
}

@mixin add-clickable-area-small {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 1em;
  margin: -1em;
}

/* cursor */
.cursor-pointer {
  cursor: pointer;
}

/* text transform */
.uppercase {
  text-transform: uppercase;
}

/* line heights */
.lh-1\.2 {
  line-height: 120%;
}

.lh-1\.5 {
  line-height: 150%;
}

.animate-reverse {
  animation-duration: reverse;
}
