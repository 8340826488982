.question-icon {
  @include text-bold;
  display: flex;
  color: $brand;
  font-size: 0.7rem;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid $brand;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}

.tooltip {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .text-title {
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .tooltip-body {
    font-size: 0.9rem;
  }

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
  }
}

.tooltip-container-checkout-summary {
  @include text-agenda-regular;
  border: none;
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  text-align: left;
  line-height: 100%;
  font-size: 12px;
  text-transform: none;

  .tooltip-title {
    @include text-agenda-bold;
    text-align: center;
    font-size: 14px;
    margin-bottom: 12px;
  }
}

.context-switch-entity-tooltip {
  &.tooltip-container {
    background-color: #1b2031 !important;
    padding: 0.625rem;
    padding-top: 12px;
    border-radius: 10px;
    border: none;
    animation: fadeIn 300ms ease-in;

    .tooltip-title {
      @include text-agenda-semi-bold;
      color: white;
      line-height: 100%;
      font-size: 12px;
    }
  }
}
