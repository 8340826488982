.charity-table {
  background-color: #fff;
  .rt-tbody {
    .col-charity-container {
      margin: 0.5rem 0.5rem 0.5rem 0;

      @include from($tablet) {
        margin: 0.5rem 0.5rem 0.5rem 0.2rem;
      }

      .avatar {
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;

        @include from($tablet) {
          margin-right: 0.5rem;
          margin-left: 0;
        }
      }

      .col-charity-details {
        justify-content: center;

        .col-charity-name {
          button {
            border: 0;
            font-size: 15px;
            letter-spacing: 0.05em;
            background-color: transparent;
            padding: 0;
            text-decoration: underline;
            @include text-agenda-medium;
            color: $black;

            &:hover {
              color: $baseColor;
            }
          }
        }

        .col-charity-place {
          color: $black;
          font-size: 12px;
        }
      }

      .is-expanded {
        margin-top: 0.5rem;
        margin-left: 3rem;

        .user-expanded-status {
          color: $darkGrey;
          font-size: 0.9rem;
          @include text-medium;
        }

        .user-expanded-text {
          color: $darkGrey;
          font-size: 0.9rem;
        }

        .user-expanded-currency {
          color: $darkGrey;
          font-size: 0.9rem;
          @include text-agenda-bold;
        }
      }
    }
  }
}
