.charity-profile {
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .page-section {
    margin-bottom: 0.5rem;
  }

  .custom-class {
    border: 20px dotted magenta;
  }

  .subtitle {
    @include text-medium;
    margin-top: 0.5rem;
  }

  .page-body {
    margin-top: unset;
    padding-top: unset;
  }

  .subtitle {
    margin-bottom: 0.5rem;
  }

  .app-preview-image {
    max-width: 28rem;
  }

  .image-preview-container {
    margin-right: 1rem;
    max-width: 20rem;
    max-height: 20rem;
  }

  .profile-preview-image {
    border: 1px solid $lightGrey;
    border-radius: 10rem;
    max-width: 12rem;
  }

  .donation-preview-image {
    max-height: 18rem;
    max-width: 14rem;
    border: 1px solid $lightGrey;
    border-radius: 1rem;
  }

  .hero-preview-image {
    border: 1px solid $lightGrey;
    max-width: 18rem;
  }

  .profile-textarea {
    height: 10rem;
    width: 30rem;
    font-family: 'Walshiem';
  }

  .url-textarea {
    max-width: 23rem;
    flex: 1;
    display: flex;
    font-family: 'Walshiem';
    margin-bottom: 1rem;
  }

  .url-name {
    @include text-bold;
    min-width: 7rem;
  }

  .image-form {
    margin-top: 1rem;
  }

  .save-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .upload-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .section-description {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .image-header {
    margin-top: 1.5rem;
  }
}
